import { Auth } from "../../constants/action-constants/auth/AuthActionConstants";

export const login = ({ email, password, navigate }) => {
  return {
    type: Auth.USER_LOGIN,
    payload: { email, password, navigate },
  };
};

export const clearLoginInfo = () => ({
  type: Auth.CLEAR_LOGIN_INFO,
});
