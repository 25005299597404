//Pagination
export const DEFAULT_PAGINATION = {
  pageNumber: 1,
  pageSize: 10,
  totalPages: 1,
};

export const QueryParamsKeys = {
  pageNumber: "pn",
  pageSize: "ps",
  searchText: "search",
  junctionId: "junction",
  minimumTime: "min_time",
  maximumTime: "max_time",
  minimumDelay: "min_delay",
  maximumDelay: "max_delay",
};
