import { Signal } from "../../constants/action-constants/signals/SignalActionConstants";

//initial state
const initialState = {
  signalInfo: {},
  signalRoadMap: {},
  signalInfoLoading: false,
  signalInfoError: "",

  // States to store the details of Road Selection and the GeoLocation on the Map
  roadSelectionMap: {},
  geoLocationPoint: "",

  //Switch signal monitoring status
  selectedSignalsLoading: {},
  toggleSignalMonitoringStatusLoading: false,
  toggleSignalMonitoringStatusError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ..................Create Signal Cases..............
    case Signal.CREATE_SIGNAL:
      return {
        ...state,
        signalInfoLoading: true,
      };

    case Signal.CREATE_SIGNAL_SUCCESS: {
      // const signal = action.payload;
      return {
        ...state,
        signalInfoLoading: false,
      };
    }

    case Signal.CREATE_SIGNAL_FAILURE:
      return {
        ...state,
        signalInfoLoading: false,
        signalInfoError: action.payload,
      };

    // ..................Get Signal Cases..............
    case Signal.GET_SIGNAL_INFO:
      return {
        ...state,
        signalInfoLoading: true,
      };

    case Signal.GET_SIGNAL_INFO_SUCCESS: {
      const { signalInfo, signalRoadMap, signalFeedJunctionsMap } = action.payload;
      return {
        ...state,
        signalInfoLoading: false,
        signalInfo,
        signalRoadMap,
        signalFeedJunctionsMap,
      };
    }

    case Signal.GET_SIGNAL_INFO_FAILURE:
      return {
        ...state,
        signalInfoLoading: false,
        signalInfoError: action.payload,
      };

    // ..................Toggle signal monitoring status..............
    case Signal.TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID: {
      const { signalId } = action.payload;

      return {
        ...state,
        selectedSignalsLoading: {
          ...state.selectedSignalsLoading,
          [signalId]: true,
        },
        toggleSignalMonitoringStatusLoading: true,
      };
    }

    case Signal.TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID_SUCCESS: {
      //To get previous state
      const updateSelectedSignalsLoading = { ...state.selectedSignalsLoading };

      //To remove the id from state after successfull loading
      delete updateSelectedSignalsLoading[action.payload.signalId];

      return {
        ...state,
        selectedSignalsLoading: updateSelectedSignalsLoading,
        toggleSignalMonitoringStatusLoading: false,
      };
    }

    case Signal.TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID_FAILURE: {
      //To get previous state
      const updateSelectedSignalsLoading = { ...state.selectedSignalsLoading };

      //To remove the id from state after successfull loading
      delete updateSelectedSignalsLoading[action.payload.signalId];

      return {
        ...state,
        selectedSignalsLoading: updateSelectedSignalsLoading,
        toggleSignalMonitoringStatusLoading: false,
        toggleSignalMonitoringStatusError: action.payload.error,
      };
    }

    default:
      return state;
  }
};
