// Utils
import { sendGetRequest } from "../../utils/api/ApiUtils";

// Urls
import { congestionReportURL } from "../../urls/api-urls/ReportsURL";


// Conjestion Report API
export async function congestionReportApi(startTime, endTime) {

  // Headers
  const headers = {
    "Content-Type": "application/json",
  };

  // Request Params
  const params = {
    startTime: startTime,
    endTime: endTime
  };

  return sendGetRequest(congestionReportURL, params, true, headers);
}