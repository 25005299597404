import { useSearchParams } from "react-router-dom";

export function useUpdateQueryParams() {
  //Search params
  const [searchParams, setSearchParams] = useSearchParams();

  //Param Keys
  const keys = [
    "pn",
    "ps",
    "search",
    "junction",
    "min_time",
    "max_time",
    "min_time_day",
    "max_time_day",
    "min_delay",
    "max_delay",
  ];

  function updateQueryParams({ params }) {
    //Updating
    const updatedQueryParams = keys.reduce((acc, key) => {
      if (params[key]) {
        //Creates a new key value pair
        acc[key] = params[key];

        return acc;
      }

      //If there is no value present, it will return previous object, which means it will not add key with empty value
      return acc;
    }, {});

    return setSearchParams(updatedQueryParams);
  }

  return [searchParams, updateQueryParams];
}
