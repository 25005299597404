// Map Zoom Levels
export const MapZoom = {
  zoomLevel9: 9,
  zoomLevel10: 10,
  zoomLevel11: 11,
  zoomLevel12: 12,
  zoomLevel13: 13,
  zoomLevel14: 14,
  zoomLevel15: 15,
};

// Classes used by Leaflet to position controls
export const LEAFLET_POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

// Radius
export const RADIUS = {
  oneKm: 1000,
  fiveKm: 5000,
  fiveHundredMtr: 500,
};

//Color code for traffic
export const STATUS_COLOR = {
  normal: "#84ca50",
  "congestion-mild": "#f07d02",
  congestion: "#e60000",
  "congestion-heavy": "#9e1313",
  "congestion-extreme": "#530000",
};

//Local Storage
export const LOCAL_STORAGE_KEYS = {
  PRIVILEGES: "PRIVILEGES",
  USER: "USER",
  ORG_TYPE: "ORG_TYPE",
};

//Date Format
export const DATE_FORMATS = {
  full_month_with_date_year: "MMM DD-YYYY",
  date_month_year: "DD-MM-YYYY",
};

// Lat and Long for chennai
export const CHENNAI_LAT_LONG = [12.992206641308625, 80.2433964488025];
