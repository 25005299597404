import { Signal } from "../../constants/action-constants/signals/SignalActionConstants";

export const createSignal = (signalData, navigate) => ({
  type: Signal.CREATE_SIGNAL,
  payload: { signalData, navigate },
});

export const editSignal = (signalData, signalId, navigate) => ({
  type: Signal.EDIT_SIGNAL,
  payload: { signalData, signalId, navigate },
});

export const getSignalInfo = (signalId) => ({
  type: Signal.GET_SIGNAL_INFO,
  payload: { signalId },
});

export const toggleSignalMonitoringStatusById = ({
  monitoringStatus,
  signalId,
}) => ({
  type: Signal.TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID,
  payload: {
    monitoringStatus,
    signalId,
  },
});
