//Utils
import { sendGetRequest } from "../../utils/api/ApiUtils";

//Urls
import { getLiveCongestionInfoByIdUrl } from "../../urls/api-urls/LiveCongestionURL";

export async function getLiveCongestionInfoByIdApi(congestionId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getLiveCongestionInfoByIdUrl.replace(
    ":congestionId",
    congestionId
  );

  return sendGetRequest(url, {}, true, headerParams);
}
