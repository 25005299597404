//Utils
import { sendGetRequest } from "../../utils/api/ApiUtils";

//Urls
import { getSnapshotCongestionListUrl } from "../../urls/api-urls/SnapshotCongestionURL";

export async function getSnapshotCongestionListApi(
  searchText,
  minDelayMins,
  division,
  subDivision,
  station,
  time
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {};

  if (searchText) {
    params.q = searchText;
  }

  if (minDelayMins) {
    params.min_delay = minDelayMins * 60;
  }

  if (division) {
    params.division = division;
  }

  if (subDivision) {
    params.sub_division = subDivision;
  }

  if (station) {
    params.station = station;
  }

  if (time) {
    params.time = time;
  }

  const response = await sendGetRequest(getSnapshotCongestionListUrl, params, true, headerParams);
  return response;
}
