//Action Constants
import { SnapshotCongestionList } from "../../constants/action-constants/snapshot/SnapshotCongestionListActions";

//initial state
const initialState = {
  //Get Snapshot congestion list
  congestionList: [],
  congestionListError: "",
  congestionListLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Get live congestion list
    case SnapshotCongestionList.GET_SNAPSHOT_CONGESTION_LIST:
      return {
        ...state,
        congestionListLoading: true,
      };

    case SnapshotCongestionList.GET_SNAPSHOT_CONGESTION_LIST_SUCCESS:
      const { congestionList } =
        action.payload;

      return {
        ...state,
        congestionListLoading: false,
        congestionList,
      };

    case SnapshotCongestionList.GET_SNAPSHOT_CONGESTION_LIST_FAILURE:
      return {
        ...state,
        congestionListLoading: false,
        congestionListError: action.payload.error,
      };

    default:
      return state;
  }
};
