import { combineReducers } from "redux";

//Auth Reducer
import AuthReducer from "./auth/AuthReducer";

//Signal Reducers
import signalSearchReducer from "./signals/SignalSearchReducer";
import signalReducer from "./signals/SignalReducer";

// User Reducers
import UserListReducer from "./users/UserListReducer";
import UserReducer from "./users/UserReducer";

// Roads Reducers
import RoadSearchReducer from "./roads/RoadSearchReducer";
import RoadReducer from "./roads/RoadReducer";

//Live congestion
import LiveCongestionReducer from "./live-congestion/LiveCongestionReducer";
import LiveCongestionListReducer from "./live-congestion/LiveCongestionListReducer";

// Congestion
import CongestionSearchReducer from "./congestion/CongestionSearchReducer";

// Congestion Download
import CongestionDownloadReducer from "./congestion/CongestionDownloadReducer";

// Reports
import ReportsReducer from "./reports/ReportsReducer";

// Snapshot congestion
import SnapshotCongestionListReducer from "./snapshot/SnapshotCongestionListReducer";

// Combining Reducers
// -----------------------------------------------------------------------------------------
export default combineReducers({
  // Auth Reducer
  auth: AuthReducer,

  //Signals
  signalSearch: signalSearchReducer,
  signal: signalReducer,

  //Users
  user: UserReducer,
  userList: UserListReducer,

  //Roads
  roadSearch: RoadSearchReducer,
  road: RoadReducer,

  // Live congestion
  liveCongestion: LiveCongestionReducer,
  liveCongestionList: LiveCongestionListReducer,

  // Congestions
  congestionSearch: CongestionSearchReducer,
  congestionDownload: CongestionDownloadReducer,

  // Reports
  reports: ReportsReducer,

  // Snapshot congestion
  snapshotCongestionList: SnapshotCongestionListReducer,
});
