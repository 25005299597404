// Action Constants
import { DataFile } from "../../constants/action-constants/congestion/CongestionDownloadActionConstants";

const initialState = {

  // Download Data File
  downloadCongestionDataLoading: false,
  downloadCongestionDataError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {

    // Download Congestion Data
    case DataFile.DOWNLOAD_CONGESTION_DATA: {

      return {
        ...state,
        downloadCongestionDataLoading: true,
      };
    }

    case DataFile.DOWNLOAD_CONGESTION_DATA_SUCCESS: {

      return {
        ...state,
        downloadCongestionDataLoading: false,
      };
    }

    case DataFile.DOWNLOAD_CONGESTION_DATA_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        downloadCongestionDataLoading: false,
        downloadCongestionDataError: error,
      };
    }

    default:
      return state;
  }
};