import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

//Api
import { getUserListApi } from "../../apis/user/UserListAPI";

//Action Constants
import { UserList } from "../../constants/action-constants/user/UserListActionConstants";

//Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

export function* getUserList(action) {
  const { searchText, pageNumber, pageSize } = action.payload;

  try {
    const { items, pagination } = yield getUserListApi(
      searchText,
      pageNumber,
      pageSize
    );

    yield put({
      type: UserList.GET_USER_LIST_SUCCESS,
      payload: { userList: items, userListPagination: pagination },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: UserList.GET_USER_LIST_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(UserList.GET_USER_LIST, getUserList)]);
}
