import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

// Constants
import { SignalSearch } from "../../constants/action-constants/signals/SignalSearchActionConstants";

// API's
import { searchSignalApi } from "../../apis/signals/SignalSearchAPI";

//Urls
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";

export function* getSignalListData(action) {
  const { searchText, pageNumber, pageSize } = action.payload;
  try {
    const response = yield searchSignalApi(searchText, pageNumber, pageSize);
    const { pagination: signalListPagination, items: signalList } = response;

    yield put({
      type: SignalSearch.GET_SIGNAL_LIST_SUCCESS,
      payload: { signalList, signalListPagination },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SignalSearch.GET_SIGNAL_LIST_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(SignalSearch.GET_SIGNAL_LIST, getSignalListData)]);
}
