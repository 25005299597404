//Action Constants

import { Auth } from "../../constants/action-constants/auth/AuthActionConstants";

//intital state
const initialState = {
  // login in
  authUser: {},
  loginLoading: false,
  loginError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Login
    case Auth.USER_LOGIN:
      return {
        ...state,
        loginLoading: true,
      };
    case Auth.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        authUser: action.payload.loggedInData,
      };

    case Auth.USER_LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginError: action.payload.error,
      };

    // Clear login info
    case Auth.CLEAR_LOGIN_INFO:
      return initialState;

    default:
      return state;
  }
};
