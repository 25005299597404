/**
 * Congestion Entity URLs
 */
export const searchCongestionsUrl = "/api/v1/congestion/search";

/**
 * Congestion Download URL
 */

export const downloadCongestionDataURL = "/api/v1/congestion/export/xls";

