//Constants
import { DEFAULT_PAGINATION } from "../../constants/PaginationConstants";

//Utils
import { sendGetRequest } from "../../utils/api/ApiUtils";

//Urls
import { getLiveCongestionListUrl } from "../../urls/api-urls/LiveCongestionURL";

export async function getLiveCongestionListApi(
  searchText,
  minDelayMins,
  division,
  subDivision,
  station,
  pageNumber,
  pageSize
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    pn: pageNumber || 1,
    ps: pageSize || 150,
  };

  if (searchText) {
    params.q = searchText;
  }

  if (minDelayMins) {
    params.min_delay = minDelayMins * 60;
  }

  if (division) {
    params.division = division;
  }

  if (subDivision) {
    params.sub_division = subDivision;
  }

  if (station) {
    params.station = station;
  }

  const response = await sendGetRequest(getLiveCongestionListUrl, params, false, headerParams);
  return response;
}
