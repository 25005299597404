import { useState } from "react";

//Component
import ErrorMessage from "../../components/error-message/ErrorMessage";

//Page Component
function PasswordIcon({ isInputTypePassword, showPassword, setShowPassword }) {
  //if input type is password
  if (isInputTypePassword) {
    return (
      <span
        className="input-group-text bg-body-secondary"
        onClick={() => setShowPassword((prev) => !prev)}
      >
        <i
          className={`fa-regular ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
        ></i>
      </span>
    );
  }

  return "";
}

/**
 * Input Component
 * @param {*} type
 * @param {*} id
 * @param {*} value : Input Value
 * @param {*} name
 * @param {*} className
 * @param {*} onChange : Function
 * @param {*} placeholder
 * @param {*} disabled : Boolean Value
 * @param {*} error : Formik error
 */
export default function Input({
  type = "text",
  id,
  value,
  name,
  className = "",
  onChange = () => {},
  placeholder = "Enter text..",
  disabled = false,
  error = "",
  ...rest
}) {
  //State
  const [showPassword, setShowPassword] = useState(false);

  const isInputTypePassword = type === "password";

  return (
    <>
      <div className="input-group">
        <input
          type={!showPassword || !isInputTypePassword ? type : "text"}
          id={id}
          className={`form-control ${className} ${error && "border-danger"}`}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
          {...rest}
        />
        {/*password icon */}
        <PasswordIcon
          isInputTypePassword={isInputTypePassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      </div>
      {/* To show error message */}
      {error && <ErrorMessage error={error} />}
    </>
  );
}
