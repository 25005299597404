//Action Constants
import { LiveCongestion } from "../../constants/action-constants/live-congestion/LiveCongestionActionConstants";

//initial state
const initialState = {
  //Get live congestion information by id
  liveCongestionInfo: {},
  liveCongestionInfoError: "",
  liveCongestionInfoLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Get live congestion information by id
    case LiveCongestion.GET_LIVE_CONGESTION_INFO_BY_ID:
      return {
        ...state,
        liveCongestionInfoLoading: true,
      };

    case LiveCongestion.GET_LIVE_CONGESTION_INFO_BY_ID_SUCCESS:
      const { liveCongestionInfo } = action.payload;

      return {
        ...state,
        liveCongestionInfoLoading: false,
        liveCongestionInfo,
      };

    case LiveCongestion.GET_LIVE_CONGESTION_INFO_BY_ID_FAILURE:
      return {
        ...state,
        liveCongestionInfoLoading: false,
        liveCongestionInfoError: action.payload.error,
      };

    default:
      return state;
  }
};
