import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

// Constants
import { RoadSearch } from "../../constants/action-constants/roads/RoadSearchActionConstants";

// API's
import { getGeoNearRoadsApi } from "../../apis/roads/RoadSearchAPI";


export function* getGeoNearRoadList(action) {
    const { latitude, longitude, radiusInMts } = action.payload
    try {
        const { items: roadList } = yield getGeoNearRoadsApi(latitude, longitude, radiusInMts);
        yield put({
            type: RoadSearch.GET_GEO_NEAR_ROADS_SUCCESS,
            payload: { roadList },
        });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({
            type: RoadSearch.GET_GEO_NEAR_ROADS_FAILURE,
            payload: error,
        });
        toast.error(errorMessage);
    }
}

export default function* root() {
    yield all([
        takeLatest(
            RoadSearch.GET_GEO_NEAR_ROADS, getGeoNearRoadList
        )
    ]);
}
