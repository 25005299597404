//Constants
import { DEFAULT_PAGINATION } from "../../constants/PaginationConstants";

// Utils
import { sendGetRequest } from "../../utils/api/ApiUtils";

// URLs
import { searchCongestionsUrl } from "../../urls/api-urls/CongestionURL";

export async function searchCongestionsAPI(criteria, pageNumber, pageSize) {
  // Headers
  const headers = {
    "Content-Type": "application/json",
  };

  // Request Params
  const params = {
    pn: pageNumber || DEFAULT_PAGINATION.pageNumber,
    ps: pageSize || DEFAULT_PAGINATION.pageSize,
  };

  // Search Criteia
  params.junction = criteria?.junctionId || null;

  params.min_time = criteria?.minTimestamp || null;
  params.max_time = criteria?.maxTimestamp || null;

  params.min_time_day = criteria?.minTimeOfDay || null;
  params.max_time_day = criteria?.maxTimeOfDay || null;

  params.min_delay = criteria?.minDelaySecs || null;
  params.max_delay = criteria?.maxDelaySecs || null;

  params.min_speed = criteria?.minSpeedKmph || null;
  params.max_speed = criteria?.maxSpeedKmph || null;

  // Response
  const response = await sendGetRequest(
    searchCongestionsUrl,
    params,
    true,
    headers
  );
  return response;
}
