import { all, fork } from "redux-saga/effects";
// Auth Saga
import AuthSaga from "../saga/auth/AuthSaga";
//Signals Saga
import SignalSaga from "../saga/signals/SignalSaga";
import SignalSearchSaga from "../saga/signals/SignalSearchSaga";

//User Saga
import UserSaga from "../saga/user/UserSaga";
import UserListSaga from "../saga/user/UserListSaga";

//Road Saga
import RoadSearchSaga from "../saga/roads/RoadSearchSaga";

//Live Congestion Saga
import LiveCongestionSaga from "../saga/live-congestion/LiveCongestionSaga";
import LiveCongestionListSaga from "../saga/live-congestion/LiveCongestionListSaga";

//Snapshot Congestion Saga
import SnapshotCongestionListSaga from "./snapshot/SnapshotCongestionListSaga";

// Congestions
import CongestionSearchSaga from "./congestion/CongestionSearchSaga";
import CongestionDownloadSaga from "./congestion/CongestionDownloadSaga";

// Reports
import ReportsSaga from "./reports/ReportsSaga";

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    // Auth Saga
    fork(AuthSaga),
    //Signals
    fork(SignalSaga),
    fork(SignalSearchSaga),

    //Users
    fork(UserSaga),
    fork(UserListSaga),

    //Roads
    fork(RoadSearchSaga),

    //Live Congestion
    fork(LiveCongestionSaga),
    fork(LiveCongestionListSaga),

    // Snapshot Congestion
    fork(SnapshotCongestionListSaga),

    // Congestion
    fork(CongestionSearchSaga),
    fork(CongestionDownloadSaga),

    // Reports
    fork(ReportsSaga)

  ]);
}
