import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Hooks
import { useUpdateQueryParams } from "../../hooks/UpdateQueryParams";

//Actions
import { getUserList } from "../../actions/user/UserListActions";

// Constants
import {
  DEFAULT_PAGINATION,
  QueryParamsKeys,
} from "../../constants/PaginationConstants";

//Urls
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";

// Components
import { Button } from "../../components/button/Button";
import Input from "../../components/input/Input";

//Section
import PoliceStaffTable from "./PoliceStaffTable";

// Page Components
function PoliceStaffHeader() {
  return (
    <div className="container pt-5">
      <div className="row gx-0">
        <h4 className="col-10">Police Staff / Users</h4>
        <Link
          to={RedirectTo.AddNewPoliceStaffPageUrl}
          className="col fw-bold text-decoration-none text-end"
        >
          Add New User
        </Link>
      </div>
    </div>
  );
}

function PoliceStaffActions({ searchValue, setSearchValue }) {
  //Update query params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  //Search user
  function searchUser() {
    const params = {
      search: searchValue,
      pn: DEFAULT_PAGINATION.pageNumber,
      ps: DEFAULT_PAGINATION.pageSize,
    };
    updateQueryParams({ params });
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-between col-4">
        {/* Input */}
        <Input
          type="text"
          className="form-control shadow-none"
          placeholder="Search"
          value={searchValue || ""}
          onChange={({ target }) => setSearchValue(target.value)}
        />

        {/* Button */}
        <Button
          color="success"
          label="Search"
          className="ms-1"
          onClick={searchUser}
        />

        {/* TO DO : Filter is disabled for now */}
        <div className="col-2 text-end d-none">
          <button className="btn btn-outline-dark px-4 py-2">
            <i className="fa-solid fa-filter fa-xl me-1"></i>
            Filters
          </button>
        </div>
      </div>
    </div>
  );
}

/**
 * Police staff page
 */
export default function PoliceStaffPage() {
  //Dispatch
  const dispatch = useDispatch();

  //Search Params State
  const [searchParams, setSearchParams] = useSearchParams();

  //State
  const [searchValue, setSearchValue] = useState("");

  //Selector state
  const userList = useSelector((state) => state.userList.userList);
  const userListLoading = useSelector(
    (state) => state.userList.userListLoading
  );
  const userListPagination = useSelector(
    (state) => state.userList.userListPagination
  );
  const userListError = useSelector((state) => state.userList.userListError);

  //pageNumber, pageSize & search text from Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  //useEffect
  useEffect(() => {
    //If search value is present in url, it will set that value in input field
    setSearchValue(searchText);

    //Dispatch
    dispatch(getUserList({ searchText, pageNumber }));
  }, [dispatch, pageNumber, pageSize, searchText]);

  return (
    <div className="content-wrapper">
      {/* Police Staff Page header */}
      <PoliceStaffHeader />
      <hr className="hr-color" />

      {/* Police Staff Page Actions */}
      <PoliceStaffActions
        dispatch={dispatch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      {/* Police staff Table */}
      <PoliceStaffTable
        userList={userList}
        userListLoading={userListLoading}
        userListPagination={userListPagination}
        pageNumber={pageNumber}
        searchValue={searchValue}
      />
    </div>
  );
}
