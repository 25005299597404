import { Link } from "react-router-dom";

//Urls
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";
import { DEFAULT_PAGINATION } from "../../constants/PaginationConstants";

// Page Constants
const trafficCards = [
  {
    title: "Live Monitor",
    description: "Traffic Live Monitor",
    link: RedirectTo.LiveMonitorPageUrl,
  },
  {
    title: "City Map View",
    description: "View Junctions data at City Level on Map",
    link: RedirectTo.SignalMapViewPageUrl,
  },
  {},
  {
    title: "Congestion History",
    description: "View / Search Historical Congestion Data",
    link: RedirectTo.CongestionHistoryPageUrl,
  },
  {
    title: "Congestion Reports",
    description: "Congestion Weekly Summary / Reports",
    link: RedirectTo.CongestionReportPageUrl,
  },
  {
    title: "Congestion Snapshot",
    description: "Get the Traffic Congestion Snapshot a given Day/time",
    link: RedirectTo.CongestionSnapshotPageUrl,
  },
];

const dataMgmtCards = [
  {
    title: "Junctions / Signals Management",
    description: "View all Signals/Junctions and their Monitoring Status, etc.,",
    link: RedirectTo.listSignalsPageUrl,
  },
  {
    title: "Traffic Police Management",
    description: "View all Traffic Police and respective Junction assignments",
    link: RedirectTo.PoliceStaffPageUrl,
  },
];

//Page Components

/**
 * Dashboard Card
 * @param {*} card : Object containing cardData
 */
export function DashboardCard({ card }) {
  const { title, description, link } = card;

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text fw-light">{description}</p>
        <Link to={link} className="btn btn-primary px-4 py-2 mt-3">
          View
        </Link>
      </div>
    </div>
  );
}

/**
 * Home Page
 */
export default function HomePage() {
  return (
    <div className="content-wrapper">
      <div className="p-4"></div>

      <h4>Traffic Congestion Tools</h4>
      <div className="row mt-4 mb-5">
        {trafficCards.map((card, i) => (
          <div className="col-sm-6 col-lg-4 mb-3">
            {card?.title && ( //
              <DashboardCard key={i} card={card} />
            )}
          </div>
        ))}
      </div>

      <h4>Data Management</h4>
      <div className="row mt-4 mb-5">
        {dataMgmtCards.map((card, i) => (
          <div className="col-sm-6 col-lg-4 mb-3">
            <DashboardCard key={i} card={card} />
          </div>
        ))}
      </div>
    </div>
  );
}
