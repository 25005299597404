//Page Component

/**
 * Header
 *@param {*}  header:object
 *
 */
function Header({ header }) {
  const { title } = header;

  return <th className={title.className}>{title.displayName}</th>;
}

/**
 * Table Headers
 * @param {*} tableHeaders :array
 *
 */
export default function TableHeaders({ tableHeaders }) {
  return (
    <thead>
      <tr className="table-primary">
        {tableHeaders.map((header, index) => (
          <Header header={header} key={index} />
        ))}
      </tr>
    </thead>
  );
}
