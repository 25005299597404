import { Link, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { searchSignals } from "../../../actions/signals/SignalSearchActions";

// Constants
import { DEFAULT_PAGINATION, QueryParamsKeys } from "../../../constants/PaginationConstants";

//Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectUrls";

// Components
import Input from "../../../components/input/Input";
import { Button } from "../../../components/button/Button";

//Sections
import SignalsTable from "./SignalsTable";

// Page Components
function SignalsHeader() {
  return (
    <div className="row g-0 pt-5">
      <h4 className="col-10">Signals / Junctions</h4>
      <Link to={RedirectTo.createSignalPageUrl} className="col fw-bold text-decoration-none text-end">
        Add New Junction
      </Link>
    </div>
  );
}

function SignalsActions({ searchValue, setSearchValue, signalListPagination }) {
  //Update query params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();
  const { totalCount } = signalListPagination;

  //Search user
  function searchUser() {
    const params = {
      search: searchValue,
      pn: DEFAULT_PAGINATION.pageNumber,
      ps: DEFAULT_PAGINATION.pageSize,
    };
    updateQueryParams({ params });
  }

  return (
    <div className="d-flex justify-content-between g-0">
      <div className="d-flex justify-content-between col-4">
        {/* Input */}
        <Input
          type="text"
          className="form-control shadow-none"
          placeholder="Search"
          value={searchValue || ""}
          onChange={({ target }) => setSearchValue(target.value)}
        />

        {/* Button */}
        <Button color="success" label="Search" className="ms-1" onClick={searchUser} />

        {/* TO DO : Filter is disabled for now */}
        <div className="col-2 text-end d-none">
          <button className="btn btn-outline-dark px-4 py-2">
            <i className="fa-solid fa-filter fa-xl me-1"></i>
            Filters
          </button>
        </div>
      </div>
      <div className="mt-2">
        <span className="fw-bold">Total Junctions</span>
        <span>{`: ${totalCount}`}</span>
      </div>
    </div>
  );
}

/**
 * Signals page
 */
export default function SignalListPage() {
  // Dispatch
  const dispatch = useDispatch();

  //Search Params State
  const [searchParams, setSearchParams] = useSearchParams();

  //State
  const [searchValue, setSearchValue] = useState("");

  // Selector States
  const signalData = useSelector((state) => state.signalSearch.signalList) || [];
  const signalListLoading = useSelector((state) => state.signalSearch.signalListLoading);
  const signalListPagination = useSelector((state) => state.signalSearch.signalListPagination);

  //pageNumber, pageSize & search text from Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  //useEffect
  useEffect(() => {
    //If search value is present in url, it will set that value in input field
    setSearchValue(searchText);

    dispatch(searchSignals({ searchText, pageNumber }));
  }, [dispatch, pageNumber, pageSize, searchText]);

  return (
    <div className="content-wrapper">
      {/* Page header */}
      <SignalsHeader />

      <hr className="hr-color" />

      {/* Table Actions */}
      <SignalsActions
        dispatch={dispatch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        signalListPagination={signalListPagination}
      />

      <div>
        {/* Signals-Junctions Table */}
        <SignalsTable
          signalData={signalData}
          signalDataLoading={signalListLoading}
          signalListPagination={signalListPagination}
          pageNumber={pageNumber}
          searchValue={searchValue}
        />
      </div>
    </div>
  );
}
