import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Actions
import { getUserInfoById } from "../../actions/user/UserActions";

//Components
import Spinner from "../../components/spinner/Spinner";

//Images
import police_avatar from "../../assets/images/police-avatar.png";

//Page Constants
const staffInfoTitle = [
  {
    label: "Designation",
    key: "designation",
  },
  {
    label: "Station",
    key: "station",
  },
];

// Page Components
function PoliceStaffInfoHeader({ name, badgeNumber }) {
  return (
    <>
      <h4 className="container pt-5">
        {badgeNumber?.toUpperCase()} | {name?.toUpperCase()}
      </h4>
      <hr className="hr-color" />
    </>
  );
}

function PoliceStaffInfo({ userInfo }) {
  return (
    <div className="col-sm-6 mb-3">
      {staffInfoTitle.map((staff, index) => {
        const { label, key } = staff;
        return (
          <div key={index}>
            <h6>{label}</h6>
            <p className="fw-light">{userInfo[key]}</p>
          </div>
        );
      })}
    </div>
  );
}

function PoliceStaffImage() {
  return (
    <div className="col-sm-12 col-md-6 mb-3">
      <img src={police_avatar} />
    </div>
  );
}

/**
 * Police user information page
 */
export default function PoliceStaffInfoPage() {
  //Dispatch
  const dispatch = useDispatch();

  //use Params
  const params = useParams();

  //Selector states
  const userInfo = useSelector((state) => state.user.userInfo);
  const userInfoLoading = useSelector((state) => state.user.userInfoLoading);

  const { staffId } = params;

  //use Effect
  useEffect(() => {
    dispatch(getUserInfoById({ staffId }));
  }, [dispatch, staffId]);

  const { name, badgeNo } = userInfo;

  //Loader
  if (userInfoLoading) {
    return <Spinner className="mt-5" />;
  }

  return (
    <div className="content-wrapper ">
      {/* Police User Page Header */}
      <PoliceStaffInfoHeader name={name} badgeNumber={badgeNo} />

      <div className="container pt-3">
        <div className="row">
          {/* Police User Information */}
          <PoliceStaffInfo userInfo={userInfo} />
          {/* Police user Image */}
          <PoliceStaffImage />
        </div>
      </div>
    </div>
  );
}
