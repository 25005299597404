import * as yup from "yup";
import { useFormik } from "formik";

const formEmptyValues = {
  name: "",
  signalNo: "",
  subDivision: "",
  type: "",
  station: "",
}

//Validation Schema
const formValidationSchema = yup.object({
  name: yup.string().required("please fill the Name of the junction Field"),
  signalNo: yup.string().required("please fill the Signal Allotted No Field"),
  subDivision: yup.string().required("please fill the Sub-Division Foeld"),
  type: yup.string().required("please fill the Road Type Field"),
  station: yup.string().required("please fill the Station Field"),
});

export function constructFormik({ valuesObj = "", onSubmitFunc }) {
  const initialValues = !valuesObj ? formEmptyValues : valuesObj;
  const formik = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: onSubmitFunc,
  });
  return formik
}

export function constructFormikData(signalData) {
  if (Object.keys(signalData).length == 0) {
    return formEmptyValues;
  }
  const newFormValues = Object.keys(formEmptyValues).reduce((acc, eachKey) => {
    acc[eachKey] = signalData[eachKey];
    return acc;
  }, {});
  return newFormValues;
}

export function updateFormicData(signalData, formik) {
  formik.setValues(constructFormikData(signalData));
}


