import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getCongestionReport } from "../../../actions/reports/ReportsActions";

// Components
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";

// Page Components
// ----------------------------------------------------------------------------

function getDateTimeStr(timestamp) {
  // Sanity checks
  if (!timestamp) {
    return "--";
  }

  const dateObj = new Date(timestamp);
  const dateTimeStr = dateObj.toLocaleString("en-IN", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  return dateTimeStr;
}

function getDateStr(timestamp) {
  // Sanity checks
  if (!timestamp) {
    return "--";
  }

  const dateObj = new Date(timestamp);
  const dateStr = dateObj.toLocaleString("en-IN", {
    weekday: "short",
    month: "short",
    day: "2-digit",
  });
  return dateStr;
}

function PageHeader({ minTime, setMinTime, maxTime, setMaxTime }) {
  return (
    <div className="page-header pt-4 d-flex justify-content-between">
      <h4 className="">
        Congestions Report
        {minTime && ( //
          <small className="ms-3 text-muted">
            [{getDateStr(minTime)} - {getDateStr(maxTime)}]
          </small>
        )}
      </h4>

      <div className="position-relative d-block">
        {/* Bootstrap Date Range Picker */}
        <BootstrapDateRangePicker
          onApplyDates={(selectedDates) => {
            const { startDate, endDate } = selectedDates;

            // Update State
            setMinTime(new Date(startDate._d).getTime());
            setMaxTime(new Date(endDate._d).getTime());
          }}
          styleObject={{
            buttonClassName: "bg-white form-control d-inline-block  ",
            border: true,
            showDropdowns: true,
          }}
        />
      </div>
    </div>
  );
}

/**
 * Congestion Detail Row
 */
function CongestionDetailRow({ congestion = {}, junctionsMap, junctionStatesMap }) {
  // junction info
  const junctionId = congestion.junctionId;
  const junction = junctionsMap[junctionId] || {};
  const junctionState = junctionStatesMap[junctionId] || {};

  const dateTime = new Date(congestion.timestamp);
  const hourOfDay = dateTime.getHours();
  const usualDelayInSecs = junctionState?.hourlyAvg["_" + hourOfDay] || 0;

  return (
    <tr>
      <td>{junction.station}</td>
      <td>{junction.signalNo}</td>
      <td>{junction.name}</td>
      <td>{getDateTimeStr(congestion.timestamp)}</td>
      <td>{Math.ceil(congestion.delayInSecs / 60)} mins</td>
      <td>{Math.ceil(usualDelayInSecs / 60)} mins</td>
    </tr>
  );
}

function BiggestCongestionCard({
  morningLongest = [],
  eveningLongest = [],
  junctionsMap = {},
  junctionStatesMap = {},
}) {
  return (
    <div className="card card-info mb-4">
      <div className="card-body">
        <h5 className="card-title">Biggest Congestions</h5>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Station</th>
                <th>Signal No</th>
                <th>Signal Name</th>
                <th>Time</th>
                <th>Observed Delay</th>
                <th>Usual Delay</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={6} className="bg-light text-center">
                  Morning
                </td>
              </tr>
              {morningLongest.map((c) => {
                return (
                  <CongestionDetailRow
                    key={c.timestamp}
                    congestion={c}
                    junctionsMap={junctionsMap}
                    junctionStatesMap={junctionStatesMap}
                  />
                );
              })}

              <tr>
                <td colSpan={6} className="bg-light text-center">
                  Evening
                </td>
              </tr>
              {eveningLongest.map((c) => {
                return (
                  <CongestionDetailRow
                    key={c.timestamp}
                    congestion={c}
                    junctionsMap={junctionsMap}
                    junctionStatesMap={junctionStatesMap}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

/**
 * CongestionReportPage page
 */
export default function CongestionReportPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Start and End dates State
  const [minTime, setMinTime] = useState("");
  const [maxTime, setMaxTime] = useState("");

  // Selector States
  const congestionReport = useSelector((state) => state.reports.congestionReport);
  const congestionReportLoading = useSelector((state) => state.reports.congestionReportLoading);

  useEffect(() => {
    if (minTime && maxTime) {
      //Dispatch
      dispatch(getCongestionReport({ minTime, maxTime }));
    }
  }, [dispatch, minTime, maxTime]);

  // Data
  const { report = {}, junctionsMap = {}, junctionStatesMap = {} } = congestionReport || {};

  // Congestions Summary
  const { morningLongest, eveningLongest } = report;
  const { morningMostFrequent, eveningMostFrequent } = report;
  const { morningLeastFrequent, eveningLeastFrequent } = report;

  return (
    <div className="content-wrapper">
      {/* Page header */}
      <PageHeader minTime={minTime} setMinTime={setMinTime} maxTime={maxTime} setMaxTime={setMaxTime} />
      <hr className="hr-color" />

      {/** Biggest Congestion List */}
      <BiggestCongestionCard
        morningLongest={morningLongest}
        eveningLongest={eveningLongest}
        junctionsMap={junctionsMap}
        junctionStatesMap={junctionStatesMap}
      />

      <div className="row d-none">
        <div className="col-6">
          <div className="card card-info">
            <div className="card-body">
              <h5 className="card-title">Most Congested Junctions</h5>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card card-info">
            <div className="card-body">
              <h5 className="card-title">Least Congested Junctions</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
