import { SignalSearch } from "../../constants/action-constants/signals/SignalSearchActionConstants";

//initial state
const initialState = {
    signalList: [],
    signalListPagination: {},
    signalListError: "",
    signalListLoading: false,

};


export default (state = initialState, action) => {
    switch (action.type) {
        case SignalSearch.GET_SIGNAL_LIST:
            return {
                ...state,
                signalListLoading: true,
            };

        case SignalSearch.GET_SIGNAL_LIST_SUCCESS: {
            const { signalList, signalListPagination } = action.payload;
            return {
                ...state,
                signalListLoading: false,
                signalList,
                signalListPagination
            };
        }

        case SignalSearch.GET_SIGNAL_LIST_FAILURE:
            return {
                ...state,
                signalListLoading: false,
                signalListError: action.payload,
            };

        default:
            return state;
    }
};
