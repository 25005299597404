import { Link } from "react-router-dom";

// Constants
import { CongestionsData } from "../../../Data";

// Components
import TableHeaders from "../../../components/table/TableHeaders";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Date",
    },
  },
  {
    title: {
      displayName: "Start Time",
    },
  },
  {
    title: {
      displayName: "Duration",
    },
  },
  {
    title: {
      displayName: "Police",
    },
  },
  {
    title: {
      displayName: "Police Id",
    },
  },
];

//Page Components
function CongestionTableBody({ CongestionsData }) {
  return (
    <tbody>
      {CongestionsData.map((CongestionData) => {
        const { policeId, date, startTime, duration, police } = CongestionData;

        return (
          <tr key={policeId}>
            <td>{date}</td>
            <td>{startTime}</td>
            <td>{duration}</td>
            <td className="text-primary">{police}</td>
            <td>{policeId}</td>
          </tr>
        );
      })}

      <tr>
        <td colSpan="8">
          <div className="d-flex align-items-center justify-content-end">
            1-8 of 226
            <i className="fa-solid fa-chevron-left mx-5"></i>
            <i className="fa-solid fa-chevron-right me-4"></i>
          </div>
        </td>
      </tr>
    </tbody>
  );
}

/**
 * Congestion Table
 */
export default function CongestionTable() {
  return (
    <>
      <Link
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        className="d-none"
      >
        View congestion history
      </Link>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p>Congestion History</p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table table-compact mt-3">
                {/* Table headers */}
                <TableHeaders tableHeaders={tableHeaders} />
                {/* Table Body */}
                <CongestionTableBody CongestionsData={CongestionsData} />
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
