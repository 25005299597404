//Utils
import { sendCreateOrUpdateRequest } from "../../utils/api/ApiUtils";

//Urls
import { loginUrl } from "../../urls/api-urls/authURL";

// Login
export async function loginApi(email, password) {
  const headerParams = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const bodyFormData = new FormData();
  bodyFormData.set("username", email);
  bodyFormData.set("password", password);

  return sendCreateOrUpdateRequest(
    loginUrl,
    bodyFormData,
    false,
    "POST",
    headerParams
  );
}
