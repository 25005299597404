import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";

// Actions
import { editSignal, getSignalInfo } from "../../actions/signals/SignalActions";

// constants
import { constructSignalHeader } from "../../constants/signals/signalConstants";

// Utils
import { constructFormik, constructFormikData, updateFormicData } from "./SignalSchema";
import { SignalHeader, SignalForm } from "./SignalUtils";
import { SignalMapContainer } from "./SignalMapUtils";

// Components
import Spinner from "../../components/spinner/Spinner";

/**
 * Add new signal page
 */
export default function EditSignalPage() {
  //Dispatch
  const dispatch = useDispatch();

  // Navigation
  const navigate = useNavigate();

  // Params
  const params = useParams();
  const { signalId } = params;

  // Selector States
  const signalInfoLoading = useSelector((state) => state.signal.signalInfoLoading);
  const signalInfo = useSelector((state) => state.signal.signalInfo);
  const signalInfoId = signalInfo.id ?? "";
  const feedJunctionsMap = useSelector((state) => state.signal.signalFeedJunctionsMap);

  useEffect(() => {
    dispatch(getSignalInfo(signalId));
  }, [dispatch, signalId]);

  useEffect(() => {
    updateFormicData(signalInfo, formik);
  }, [signalInfoId]);

  // Formik
  const formikOnSubmitFunc = (signalData) => {
    dispatch(editSignal(signalData, signalId, navigate));
  };
  const formik = constructFormik({ onSubmitFunc: formikOnSubmitFunc });
  const { handleSubmit } = formik;

  const headerValue = constructSignalHeader(signalInfo);

  // Page-loading
  if (signalInfoLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="content-wrapper">
      <form onSubmit={handleSubmit}>
        {/* Add New Signal Header */}
        <SignalHeader value={headerValue} />
        <hr className="hr-color" />

        <div className="row">
          {/* Form component */}
          <SignalForm formik={formik} />

          {/* Map Container */}
          <SignalMapContainer />
        </div>
      </form>
    </div>
  );
}
