import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//Actions
import { addUser } from "../../actions/user/UserActions";

// Components
import Input from "../../components/input/Input";
import { Button } from "../../components/button/Button";

//Validation Schema
const formValidationSchema = yup.object({
  name: yup.string().required("please fill the user name"),
  badgeNo: yup.string().required("please fill the badge number"),
  designation: yup.string().required("please fill the designation"),
  station: yup.string().required("please fill the station name"),
});

//Page Constants
const addUserInputItems = [
  {
    label: "Name",
    name: "name",
    type: "text",
    placeholder: "Enter police name...",
  },
  {
    label: "Badge Number",
    name: "badgeNo",
    type: "text",
    placeholder: "Enter badge number...",
  },
  {
    label: "Designation",
    name: "designation",
    type: "text",
    placeholder: "Enter designation...",
  },
  {
    label: "Station",
    name: "station",
    type: "text",
    placeholder: "Enter station name...",
  },
];

// Page components
function AddNewPoliceStaffHeader() {
  return (
    <div className="pt-5 d-flex align-items-center justify-content-between">
      <h4>New Police / User</h4>
      <Button label="Save" size="sm" type="submit" />
    </div>
  );
}

function AddNewPoliceStaffForm({ formik }) {
  const { values, handleChange, handleBlur, errors, touched } = formik;

  return (
    <div className="col-sm-6 mb-3">
      {addUserInputItems.map((input, index) => {
        const { label, name, type, placeholder } = input;

        return (
          <div className="mb-3 col-6" key={index}>
            <label className="mb-2">{label}</label>
            <Input
              className={`shadow-none ${
                touched[name] && errors[name] && "border-danger"
              }`}
              name={name}
              type={type}
              value={values[name]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched[name] && errors[name]}
              placeholder={placeholder}
            />
          </div>
        );
      })}
    </div>
  );
}

function AddNewPoliceStaffImageContainer() {
  return (
    //TO DO : For now image container is set to d-none
    <div className="col-sm-6 mb-3 d-none">
      <b>Image</b>
      <div className="bg-white d-flex flex-column align-items-center justify-content-center police-upload-image">
        <label htmlFor="file-input">
          <i className="fa-solid fa-cloud-arrow-up text-body-tertiary upload-icon"></i>
        </label>
        <Input
          type="file"
          className="d-none "
          id="file-input"
          placeholder="Upload Image here..."
        />
        <p className="text-body-tertiary">Upload Image</p>
      </div>
    </div>
  );
}

/**
 * Add new police user
 */
export default function AddNewPoliceStaffPage() {
  //Dispatch
  const dispatch = useDispatch();

  //Navigate
  const navigate = useNavigate();

  //Formik
  const formik = useFormik({
    initialValues: {
      name: "",
      badgeNo: "",
      designation: "",
      station: "",
      cityId: "city-chennai",
    },
    validationSchema: formValidationSchema,
    onSubmit: (userData) => {
      dispatch(addUser({ userData, navigate }));
    },
  });

  const { handleSubmit } = formik;

  return (
    <div className="content-wrapper">
      <form onSubmit={handleSubmit}>
        {/* Page Header */}
        <AddNewPoliceStaffHeader />

        <hr className="hr-color" />
        <div className="container">
          <div className="row">
            {/* Add New Police User Form */}
            <AddNewPoliceStaffForm formik={formik} />

            {/* Police User Image Upload */}
            <AddNewPoliceStaffImageContainer />
          </div>
        </div>
      </form>
    </div>
  );
}
