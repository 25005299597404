import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

//Api
import {
  getUserInfoByIdApi,
  addUserApi,
  editUserByIdApi,
} from "../../apis/user/UserAPI";

//Action Constants
import { User } from "../../constants/action-constants/user/UserActionConstants";

//Constants
import { DEFAULT_PAGINATION } from "../../constants/PaginationConstants";
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";

//Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

export function* getUserInfoById(action) {
  const { staffId } = action.payload;

  try {
    const userInfo = yield getUserInfoByIdApi(staffId);

    yield put({
      type: User.GET_USER_INFO_BY_ID_SUCCESS,
      payload: { userInfo },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: User.GET_USER_INFO_BY_ID_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* addUser(action) {
  const { userData, navigate } = action.payload;

  try {
    yield addUserApi(userData);

    yield put({
      type: User.ADD_USER_SUCCESS,
    });

    //Navigate
    navigate(
      RedirectTo.PoliceStaffPageUrl.replace(
        ":pageNumber",
        DEFAULT_PAGINATION.pageNumber
      )
    );
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: User.ADD_USER_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* editUserById(action) {
  const { userData, staffId, navigate } = action.payload;

  try {
    yield editUserByIdApi(userData, staffId);

    yield put({
      type: User.EDIT_USER_BY_ID_SUCCESS,
    });

    //Navigate
    navigate(
      RedirectTo.PoliceStaffPageUrl.replace(
        ":pageNumber",
        DEFAULT_PAGINATION.pageNumber
      )
    );
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: User.EDIT_USER_BY_ID_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(User.GET_USER_INFO_BY_ID, getUserInfoById),
    takeLatest(User.ADD_USER, addUser),
    takeLatest(User.EDIT_USER_BY_ID, editUserById),
  ]);
}
