// Components
import TableHeaders from "../../components/table/TableHeaders";
import TableLoader from "../../components/table/TableLoader";
import TablePagination from "../../components/table/TablePagination";
import TableDataNotFound from "../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Junction",
    },
  },
  {
    title: {
      displayName: "Traffic Coming from",
    },
  },
  {
    title: {
      displayName: "Delay",
    },
  },
  {
    title: {
      displayName: "Date",
    },
  },
  {
    title: {
      displayName: "Time",
    },
  },
];

function CongestionTableBody({ congestionList, congestionListLoading }) {
  if (congestionListLoading) {
    return <TableLoader colSpan={8} />;
  }

  if (!congestionList || congestionList.length === 0) {
    return <TableDataNotFound colSpan={8} message={"No Congestions Data Found"} />;
  }

  return congestionList.map((congestion, index) => {
    return <CongestionTableRow key={index} congestion={congestion} />;
  });
}

function CongestionTableRow({ congestion }) {
  // Junction & feed Junction
  const { junctionId, junctionName, junctionSignalNo } = congestion;
  const { feedJunctionId, feedJunctionName, feedJunctionSignalNo } = congestion;

  // congestion info
  const { delayInSecs, timestamp, timeOfDay } = congestion;
  const duration = Math.ceil(delayInSecs / 60);
  const dateObj = new Date(timestamp);

  return (
    <tr>
      <td className="col-4">
        <strong>{junctionSignalNo}</strong> | <small>{junctionName}</small>
      </td>
      <td className="col-4">
        <strong>{feedJunctionSignalNo}</strong> | <small>{feedJunctionName}</small>
      </td>
      <td className="col-1">{duration} mins</td>
      <td className="col-1">
        {dateObj.getDate()}-{dateObj.getMonth() + 1}-{dateObj.getFullYear()}
      </td>
      <td className="col-2">
        {dateObj.getHours()}:{dateObj.getMinutes()}
      </td>
    </tr>
  );
}

/**
 * Signals table
 */
export default function CongestionTable({
  congestionList,
  congestionListLoading,
  congestionListPagination,
  pageNumber,
}) {
  // Pagination
  const { totalPages, pageSize } = congestionListPagination;

  return (
    <div className="table-responsive">
      <table className="table table-compact mt-3 bg-white">
        {/* Table headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        {/* Table Body */}
        <tbody>
          <CongestionTableBody congestionList={congestionList} congestionListLoading={congestionListLoading} />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalPages={totalPages} />
    </div>
  );
}
