export const RoadSearch = {

    // Geo Near Roads
    GET_GEO_NEAR_ROADS: "GET_GEO_NEAR_ROADS",
    GET_GEO_NEAR_ROADS_SUCCESS: "GET_GEO_NEAR_ROADS_SUCCESS",
    GET_GEO_NEAR_ROADS_FAILURE: "GET_GEO_NEAR_ROADS_FAILURE",

    // Reset RoadList
    RESET: "RESET_ROAD_LIST_DATA",

}