//ALL PAGE URLS

export const RedirectTo = {
  //Home Page
  HomePageUrl: "/",

  // Privacy Policy
  PrivacyPolicyPageUrl: "/privacy",

  //Live Moniter Page
  LiveMonitorPageUrl: "/live-monitor",

  // Congestion
  CongestionHistoryPageUrl: "/congestion/history",
  CongestionReportPageUrl: "/congestion/report",
  CongestionSnapshotPageUrl: "/congestion/snapshot",

  //Signal Pages
  listSignalsPageUrl: "/signals",
  viewSignalPageUrl: "/signal/:signalId",
  createSignalPageUrl: "/new-signal",
  editSignalPageUrl: "/edit/signal/:signalId",
  signalLiveMonitorPageUrl: "/signal-live-monitor/:congestionId",

  SignalMapViewPageUrl: "/signal/map",

  //User Pages
  PoliceStaffPageUrl: "/user",
  PoliceStaffInfoPageUrl: "/user-info/:staffId",
  AddNewPoliceStaffPageUrl: "/new-user",
  EditPoliceStaffPageUrl: "/edit-user/:staffId",

  //Login Page
  LoginPageUrl: "/login",

  // Notifications
  NotificationsPageUrl: "/notifications",
};
