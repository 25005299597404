import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

// Utils and constants
import { getItem } from "./utils/LocalStorageUtils";

//Urls
import { RedirectTo } from "./urls/page-urls/RedirectUrls";
import { LOCAL_STORAGE_KEYS } from "./constants/GeneralConstants";

// Private Methods
// --------------------------------------------------------------------------

function _getUserFromLocalStorage() {
  // Get UserInfo
  const exUser = JSON.parse(getItem(LOCAL_STORAGE_KEYS.USER)) || {};
  return exUser;
}

function _isTokenExpired(token) {
  const decodedJwt = jwt_decode(token);
  decodedJwt.exp * 1000 < Date.now();
}

function _isUserAuthenticated(user) {
  if (Object.keys(user).length < 1) {
    return false;
  }
  const userPrivileges = user.privileges || [];

  const isUserAuthenticated =
    user.token && userPrivileges.length > 0 && !_isTokenExpired(user.token);

  return isUserAuthenticated;
}

/**
 * Routes which needs the USER Authenticated.
 */
function ProtectedRoute({ children }) {
  // Get Authenticated User from Global(redux) State
  const authUser = useSelector((state) => state.auth.authUser);
  const user =
    Object.keys(authUser).length > 0
      ? authUser
      : _getUserFromLocalStorage() || {};

  if (!_isUserAuthenticated(user)) {
    localStorage.clear();
    return <Navigate to={RedirectTo.LoginPageUrl} />;
  }

  return <div>{children}</div>;
}

export default ProtectedRoute;
