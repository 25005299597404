import { useState } from "react";
import { Marker, Polyline } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";

// Constants
import { GEO_JSON_BOUNDRIES_DATA } from "../../constants/GeoJSONBoundries";
import { CHENNAI_LAT_LONG, MapZoom, RADIUS } from "../../constants/GeneralConstants";

// Actions
import { getGeoNearRoads, resetRoadList } from "../../actions/roads/RoadSearchActions";
import { setGeoLocation } from "../../actions/roads/RoadActions";
import { selectRoad, unSelectRoad } from "../../actions/roads/RoadActions";

// Components
import LLMap from "../../components/map/LLMap";
import PinDropLocationAndZoom from "../../components/map/pin-drop-select/PinDropLocationAndZoom";
import MapIcon from "../../components/map/map-icon/MapIcon";

// default Constants
const defaultZoom = MapZoom.zoomLevel13;
const defaultRadius = RADIUS.fiveKm;

// Road Selection Constants
export const selected = { color: "green", weight: 20 };
export const unSelected = { color: "blue", weight: 20 };

function Road({ road, enableRoadActions = true }) {
  // dispatch
  const dispatch = useDispatch();

  const { id: roadId, traceLatLngs } = road;

  // Selected Road
  const roadSelected = useSelector((state) => state.road.roadSelectionMap[roadId]);

  const pathOptions = roadSelected ? selected : unSelected;

  function onPolylineClick(e) {
    if (!enableRoadActions) {
      return;
    }
    const dispatchFunction = roadSelected ? unSelectRoad : selectRoad;
    dispatch(dispatchFunction(roadId));
    e.originalEvent.view.L.DomEvent.stopPropagation(e);
  }

  return (
    <Polyline
      pathOptions={pathOptions}
      positions={traceLatLngs}
      eventHandlers={{
        click: (e) => onPolylineClick(e),
      }}
    ></Polyline>
  );
}

function RoadContainer({ enableMapActions }) {
  // Selector States
  const roadList = useSelector((state) => state.roadSearch.roadList);

  //TODO: Once the user selects a geoLocation from the Map, we will be making an API
  // to fetch the roadList, how do we show the loading on the Map..
  const roadListLoading = useSelector((state) => state.roadSearch.roadListLoading);

  return roadList.map((eachRoad, i) => <Road key={eachRoad.id} road={eachRoad} enableRoadActions={enableMapActions} />);
}

export function SignalMapContainer({ enableMapActions = true }) {
  // dispatch
  const dispatch = useDispatch();

  // GeoLocation
  const geoLocationPoint = useSelector((state) => state.road.geoLocationPoint);
  const initialLocation = geoLocationPoint ? geoLocationPoint.split(",") : [];

  // Local State
  const [coordinate, setCoordinate] = useState(initialLocation);
  const [mapZoom, setMapZoom] = useState(null);

  // This call-back function is used to capture the PinDrop Location
  // And initiate the other functions accordingly
  function getCoordinatesFromPinDrop(locationData) {
    dispatch(resetRoadList());
    setCoordinate([locationData.lat, locationData.lng]);
    dispatch(setGeoLocation(`${locationData.lat},${locationData.lng}`));
    dispatch(getGeoNearRoads(locationData.lat, locationData.lng, defaultRadius));
  }

  // map-point-marker
  const marker = { type: "selected-poi" };
  const icon = new MapIcon({ type: marker.type });

  return (
    <div className="col-6">
      <b>Geo Location</b>
      <div className="map">
        {/* Leaflet Map */}
        <LLMap center={CHENNAI_LAT_LONG} zoom={defaultZoom} geoJSONBoundaryData={GEO_JSON_BOUNDRIES_DATA}>
          {/* PinDrop Marker */}
          {coordinate.length > 0 && <Marker position={coordinate} icon={icon} />}

          {/* PinDropLocationAndZoom Component */}
          {enableMapActions && (
            <PinDropLocationAndZoom
              onLocationSelect={getCoordinatesFromPinDrop}
              mapZoom={mapZoom}
              setMapZoom={setMapZoom}
            />
          )}

          {/* Display Roads */}
          <RoadContainer enableMapActions={enableMapActions} />
        </LLMap>
      </div>
    </div>
  );
}
