/**
 * Converts to Seconds to Mins
 *
 * @param {*} durationSecs
 * @returns
 */
export function getDurationMins(durationSecs) {
  // Sanity checks
  if (!durationSecs) {
    return;
  }

  const durationMins = Math.ceil(durationSecs / 60);
  return durationMins;
}

export function getDelayStyleInfo(durationSecs) {
  // Sanity checks
  // Sanity checks
  if (!durationSecs) {
    return;
  }

  // Get Duration in Mins
  const duration = getDurationMins(durationSecs);

  // Marker Radius
  const radius = duration * 4 || 2;

  // Based on duration color code is picked
  if (duration <= 2) {
    return {
      radius,
      colorCode: "normal",
      status: "Normal",
    };
  }

  if (duration > 2 && duration <= 5) {
    return {
      radius,
      colorCode: "congestion-mild",
      status: "Mild Congestion",
    };
  }

  if (duration > 5 && duration <= 8) {
    return {
      radius,
      colorCode: "congestion",
      status: "Congestion",
    };
  }

  if (duration > 8 && duration <= 10) {
    return {
      radius,
      colorCode: "congestion-heavy",
      status: "Heavy Congestion",
    };
  }

  if (duration > 10) {
    return {
      radius,
      colorCode: "congestion-extreme",
      status: "Extreme Congestion",
    };
  }
}

/**
 * Get Color Code function
 * @param {*} delayInSecs : Delayed seconds
 * @returns Color Code and radius of circle based on delay secs
 *
 * @deprecated
 */
export default function getColorCode({ delayInSecs }) {
  const duration = Math.round(delayInSecs / 60);

  //If durattion is less than 0 take default radius as 2
  const radius = duration * 4 || 2;

  //Based on duration color code is picked
  if (duration <= 2) {
    return {
      radius,
      duration,
      colorCode: "normal",
      status: "Normal",
    };
  }

  if (duration > 2 && duration <= 5) {
    return {
      radius,
      duration,
      colorCode: "congestion-mild",
      status: "Mild Congestion",
    };
  }

  if (duration > 5 && duration <= 8) {
    return {
      radius,
      duration,
      colorCode: "congestion",
      status: "Congestion",
    };
  }

  if (duration > 8 && duration <= 10) {
    return {
      radius,
      duration,
      colorCode: "congestion-heavy",
      status: "Heavy Congestion",
    };
  }

  if (duration > 10) {
    return {
      radius,
      duration,
      colorCode: "congestion-extreme",
      status: "Extreme Congestion",
    };
  }
}
