// Utils
import { sendGetRequest, sendCreateOrUpdateRequest } from "../../utils/api/ApiUtils";

// URL's
import {
  getSignalSummaryByIdUrl,
  createSignalUrl,
  editSignalUrl,
  toggleSignalMonitoringStatusByIdUrl,
} from "../../urls/api-urls/SignalsURL";

export async function getSignalSummaryByIdApi(signalId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getSignalSummaryByIdUrl.replace(":signalId", signalId);

  const response = await sendGetRequest(url, {}, true, headerParams);
  return response;
}

export async function createSignalApi(signalFormData) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(createSignalUrl, signalFormData, true, "POST", headerParams);
}

export async function updateSignalApi(signalFormData, signalId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = editSignalUrl.replace(":signalId", signalId);

  return sendCreateOrUpdateRequest(url, signalFormData, true, "PUT", headerParams);
}

export async function toggleSignalMonitoringStatusByIdApi(monitoringStatus, signalId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    monitor: monitoringStatus ?? false,
  };

  const url = toggleSignalMonitoringStatusByIdUrl.replace(":signalId", signalId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams, params);
}
