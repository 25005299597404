import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Constants
import gctp_logo from "../../assets/images/logo.jpg";
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";

// Components
import ShowDateAndTime from "../show-date-time/ShowDateAndTime";
import { Dropdown } from "../dropdown/Dropdown";

// Actions
import { clearLoginInfo } from "../../actions/auth/AuthActions";

//CSS
import "./Navbar.scss";

/**
 * Navigation Bar
 */
export default function Navbar() {
  //Dispatch
  const dispatch = useDispatch();

  //Navigate
  const navigate = useNavigate();

  //Dropdown Menus
  const userMenuItems = [
    {
      buttonName: "Logout",
      buttonClassName: "",
      iconClassName: "fa-solid fa-power-off me-2 p-1 text-danger",
      onClick: () => {
        localStorage.clear();

        //Dispatch
        dispatch(clearLoginInfo({ navigate }));

        //Navigate
        navigate(RedirectTo.LoginPageUrl);
      },
    },
  ];

  return (
    <nav className="top-navbar navbar">
      <div className="container-fluid">
        <div className="navbar-left">
          <Link className="navbar-brand" to={RedirectTo.HomePageUrl}>
            <img src={gctp_logo} alt="GCTP" width="40" height="40" />
          </Link>
          {/* Shows Date and Time */}
          <ShowDateAndTime />
        </div>

        <div className="nav navbar-right">
          {/* 
          <i className="fa-regular fa-bell fa-lg mx-2"></i>
           */}

          {/* Dropdown */}
          <Dropdown label="GCTP" imageUrl={""} parentClassName="p-1" dropDownItems={userMenuItems} />
        </div>
      </div>
    </nav>
  );
}
