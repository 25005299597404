import { Road } from "../../constants/action-constants/roads/RoadActionConstants";

//initial state
const initialState = {
    roadSelectionMap: {},
    geoLocationPoint: ""
};

function multipleSelectionAction(ids, select = true) {
    const selectedRoads = {};
    for (const id of ids) {
        selectedRoads[id] = select;
    }
    return selectedRoads;
}


export default (state = initialState, action) => {
    switch (action.type) {
        case Road.SELECT_ROAD:
            const { roadId } = action.payload;
            return {
                ...state,
                roadSelectionMap: { ...state.roadSelectionMap, [roadId]: true },
            };

        case Road.SELECT_MULTIPLE_ROADS:
            const { roadIds } = action.payload;
            return {
                ...state,
                roadSelectionMap: {
                    ...state.roadSelectionMap,
                    ...multipleSelectionAction(roadIds)
                },
            };

        case Road.UN_SELECT_ROAD: {
            const { roadId } = action.payload;
            const roadSelectionMap = { ...state.roadSelectionMap };
            delete roadSelectionMap[roadId];
            return {
                ...state,
                roadSelectionMap,
            };
        }

        case Road.SET_GEO_LOCATION: {
            const { locationStr } = action.payload;
            const refreshSelectionRoadMap = locationStr != state.geoLocationPoint
            return {
                ...state,
                roadSelectionMap: refreshSelectionRoadMap ? {} : state.roadSelectionMap,
                geoLocationPoint: locationStr
            };
        }

        default:
            return state;
    }
};
