//Action Constants
import { UserList } from "../../constants/action-constants/user/UserListActionConstants";

//initial state
const initialState = {
  //Get user list
  userList: [],
  userListPagination: {},
  userListError: "",
  userListLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Get user list
    case UserList.GET_USER_LIST:
      return {
        ...state,
        userListLoading: true,
      };

    case UserList.GET_USER_LIST_SUCCESS:
      const { userList, userListPagination } = action.payload;

      return {
        ...state,
        userListLoading: false,
        userList,
        userListPagination,
      };

    case UserList.GET_USER_LIST_FAILURE:
      return {
        ...state,
        userListLoading: false,
        userListError: action.payload.error,
      };

    default:
      return state;
  }
};
