import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Hooks
import { useUpdateQueryParams } from "../../hooks/UpdateQueryParams";

// Actions
import { searchCongestions } from "../../actions/congestion/CongestionSearchActions";
import { downloadCongestionData } from "../../actions/congestion/CongestionDownloadActions";

//Constants
import { DEFAULT_PAGINATION, QueryParamsKeys } from "../../constants/PaginationConstants";

//Urls
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";

// Components
import Input from "../../components/input/Input";
import { Button } from "../../components/button/Button";
import BootstrapDateRangePicker from "../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";

//Sections
import CongestionTable from "./CongestionTable";

// Page Components
// ----------------------------------------------------------------------------

function PageHeader() {
  return (
    <div className="page-header pt-5">
      <h4 className="">Congestions History</h4>
    </div>
  );
}

function CongestionSearchList({ congestionList, setJunctionId }) {
  //To get junction id using junction name
  function getJunctionId({ target }) {
    //To find the searched junction
    const junction = congestionList.find((congestion) => congestion.junctionName === target.value);

    //Updating the state with junction id
    setJunctionId(junction?.junctionId || "");
  }

  return (
    <>
      <label htmlFor="inp-junction">Junction</label>

      {/* Input */}
      <Input
        list="junction-list"
        id="inp-junction"
        placeholder="Search Junction name"
        data-value="123"
        onChange={getJunctionId}
      />
      <datalist id="junction-list">
        {congestionList.map((congestion, index) => {
          const { junctionName } = congestion;

          return <option key={index} value={junctionName} />;
        })}
      </datalist>
    </>
  );
}

function CongestionDateRangePicker({ setMinTimestamp, setMaxTimestamp }) {
  function onApplyDates(selectedDate) {
    const { startDate, endDate } = selectedDate;

    //Start Date timestamp
    setMinTimestamp(new Date(startDate._d).getTime());

    //End Date timestamp
    setMaxTimestamp(new Date(endDate._d).getTime());
  }

  //For styles
  const styleObject = {
    buttonClassName: "bg-white form-control",
    border: true,
    showDropdowns: true,
  };
  return (
    <div className="position-relative d-block">
      <label htmlFor="inp-daterange">Date Range</label>
      {/* Bootstrap Date Range Picker */}
      <BootstrapDateRangePicker
        initialStartDate={new Date().getTime()}
        initialEndDate={new Date().getTime()}
        onApplyDates={onApplyDates}
        styleObject={styleObject}
      />
    </div>
  );
}

function CongestionSelectMinDelay({ setMinDelayMins }) {
  return (
    <>
      <label htmlFor="inp-delay-min">Min Delay (mins)</label>
      {/* Input */}
      <Input
        type="number"
        step="1"
        className="form-control"
        id="inp-delay-min"
        placeholder="Min. Delay"
        onChange={({ target }) => setMinDelayMins(target.value)}
      />
    </>
  );
}

function CongestionSelectMaxDelay({ setMaxDelayMins }) {
  return (
    <>
      <label htmlFor="inp-delay-max">Max Delay (mins)</label>
      {/* Input */}
      <Input
        type="number"
        step="1"
        className="form-control"
        id="inp-delay-max"
        placeholder="Max. Delay"
        onChange={({ target }) => setMaxDelayMins(target.value)}
      />
    </>
  );
}

function CongestionSearchSubmitButton({ submitSearchForm }) {
  return <Button className="ms-1 mt-4" color="success" label="Search" onClick={submitSearchForm} />;
}

function CongestionDownloadButton({ submitDownloadFile }) {
  // Selector State
  const downloadCongestionDataLoading = useSelector((state) => state.congestionDownload.downloadCongestionDataLoading);

  return (
    <Button
      className="ms-4 mt-4"
      color="warning"
      label="Download"
      onClick={submitDownloadFile}
      loading={downloadCongestionDataLoading}
      disabled={downloadCongestionDataLoading}
    />
  );
}

function CongestionPageResetButton() {
  // Navigate
  const navigate = useNavigate();

  return (
    <Button
      className="ms-1 mt-4"
      color="dark"
      label="Reset"
      onClick={() => navigate(RedirectTo.CongestionHistoryPageUrl)}
    />
  );
}

function CongestionSearchForm({ congestionList, setCriteria, criteria }) {
  // TODO Correct Junction Auto-complete

  // Dispatch
  const dispatch = useDispatch();

  // Search Criteria
  const [junctionId, setJunctionId] = useState("");
  const [minTimestamp, setMinTimestamp] = useState("");
  const [maxTimestamp, setMaxTimestamp] = useState("");
  const [minDelayMins, setMinDelayMins] = useState("");
  const [maxDelayMins, setMaxDelayMins] = useState("");

  //Query params update
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Search Congestions
  function submitSearchForm() {
    // Build Criteria
    const criteria = {
      junctionId,
      minTimestamp,
      maxTimestamp,
      minDelaySecs: minDelayMins ? minDelayMins * 60 : null,
      maxDelaySecs: maxDelayMins ? maxDelayMins * 60 : null,
    };

    setCriteria(criteria);

    //Construct params
    const params = {
      pn: DEFAULT_PAGINATION.pageNumber,
      ps: DEFAULT_PAGINATION.pageSize,
      junction: criteria.junctionId,
      min_time: criteria.minTimestamp,
      max_time: criteria.maxTimestamp,
      min_delay: criteria.minDelaySecs,
      max_delay: criteria.maxDelaySecs,
    };

    //Updating params
    updateQueryParams({ params });
  }

  function downloadFile() {
    dispatch(downloadCongestionData({ criteria }));
  }

  function downloadButtonEnabled() {
    return !Object.values(criteria).every((ele) => ele === null || ele === "");
  }

  return (
    <div className="row">
      <div className="col-3">
        {/* Congestion Search List */}
        <CongestionSearchList congestionList={congestionList} setJunctionId={setJunctionId} />
      </div>
      <div className="col-3">
        {/* Congestion Date Range Picker */}
        <CongestionDateRangePicker setMinTimestamp={setMinTimestamp} setMaxTimestamp={setMaxTimestamp} />
      </div>
      <div className="col-2">
        {/* Congestion Select Minimum Delay */}
        <CongestionSelectMinDelay setMinDelayMins={setMinDelayMins} />
      </div>
      <div className="col-2">
        {/* Congestion Select Max Delay */}
        <CongestionSelectMaxDelay setMaxDelayMins={setMaxDelayMins} />
      </div>
      <div className="col-2">
        {/* Congestion Search Submit Button*/}
        <CongestionSearchSubmitButton submitSearchForm={submitSearchForm} />

        {/* CongestionPageResetButton */}
        <CongestionPageResetButton />

        {/* Congestion Search Submit Button*/}
        {downloadButtonEnabled() && ( //
          <CongestionDownloadButton submitDownloadFile={downloadFile} />
        )}
      </div>
    </div>
  );
}

/**
 * Signals page
 */
export default function CongestionHistoryPage() {
  // Dispatch
  const dispatch = useDispatch();

  //Search Params State
  const [searchParams, setSearchParams] = useSearchParams();

  //State
  const [criteria, setCriteria] = useState({});

  // Selector States
  const congestionList = useSelector((state) => state.congestionSearch.congestionList) || [];
  const congestionListLoading = useSelector((state) => state.congestionSearch.congestionListLoading);
  const congestionListPagination = useSelector((state) => state.congestionSearch.congestionListPagination);

  //Get values from URL
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const junctionId = searchParams.get(QueryParamsKeys.junctionId);
  const minimumTime = searchParams.get(QueryParamsKeys.minimumTime);
  const maximumTime = searchParams.get(QueryParamsKeys.maximumTime);
  const minimumDelay = searchParams.get(QueryParamsKeys.minimumDelay);
  const maximumDelay = searchParams.get(QueryParamsKeys.maximumDelay);

  useEffect(() => {
    //Construct Criteria
    const criteria = {
      junctionId,
      minTimestamp: minimumTime,
      maxTimestamp: maximumTime,
      minDelaySecs: minimumDelay,
      maxDelaySecs: maximumDelay,
    };

    setCriteria(criteria);

    //Dispatch
    dispatch(searchCongestions({ pageNumber, criteria }));
  }, [dispatch, pageNumber, pageSize, junctionId, minimumTime, maximumTime, minimumDelay, maximumDelay]);

  return (
    <div className="content-wrapper">
      {/* Page header */}
      <PageHeader />

      <hr className="hr-color" />

      {/* Table Actions */}
      <CongestionSearchForm
        congestionList={congestionList}
        pageNumber={pageNumber}
        pageSize={pageSize}
        setCriteria={setCriteria}
        criteria={criteria}
      />
      <hr className="mt-4" />

      <div className="pb-5">
        {/* Signals-Junctions Table */}
        <CongestionTable
          congestionList={congestionList}
          congestionListLoading={congestionListLoading}
          congestionListPagination={congestionListPagination}
          pageNumber={pageNumber}
        />
      </div>
    </div>
  );
}
