import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Input from "../../components/input/Input";
import { Button } from "../../components/button/Button";

// Actions
import { getLiveCongestionList } from "../../actions/live-congestion/ListCongestionListActions";

// Constants
import { DIVISION_LIST, SUB_DIVISION_LIST, STATION_LIST } from "../../constants/JunctionConstants";

// Page Constants

/**
 * Notification section
 */
export default function NotificationSearchForm({ refreshSecs = 1, setShowSearchForm }) {
  //Dispatch
  const dispatch = useDispatch();

  // State
  const [searchText, setSearchText] = useState("");
  const [minDelayMins, setMinDelayMins] = useState("");
  const [division, setDivision] = useState("");
  const [subDivision, setSubDivision] = useState("");
  const [station, setStation] = useState("");

  // Data
  const [divisionsList, setDivisionsList] = useState(DIVISION_LIST);
  const [subDivisionsList, setSubDivisionsList] = useState(SUB_DIVISION_LIST);
  const [stationsList, setStationsList] = useState(STATION_LIST);

  // Initial Loading
  useEffect(() => {
    if (refreshSecs === 1) {
      dispatch(getLiveCongestionList({ searchText, minDelayMins, division, subDivision, station }));
    }
  }, [dispatch, refreshSecs]);

  // Search Function
  function submitSearchForm() {
    dispatch(getLiveCongestionList({ searchText, minDelayMins, division, subDivision, station }));

    // Set ShowSearchForm to false
    setShowSearchForm(false);
  }

  return (
    <div className="form-congestion-search px-4 mt-5">
      <div className="form-group mb-3">
        <label for="inp-division" className="form-label">
          District
        </label>
        <select className="form-control" id="inp-division" onChange={({ target }) => setDivision(target.value)}>
          <option value={""}>--</option>
          {divisionsList?.map((division, idx) => {
            return <option value={division}>{division}</option>;
          })}
        </select>
      </div>
      <div className="form-group mb-3">
        <label for="inp-sub-division" className="form-label">
          Sub Division
        </label>
        <select className="form-control" id="inp-sub-division" onChange={({ target }) => setSubDivision(target.value)}>
          <option value={""}>--</option>
          {subDivisionsList?.map((division, idx) => {
            return <option value={division}>{division}</option>;
          })}
        </select>
      </div>
      <div className="form-group mb-3">
        <label for="inp-station" className="form-label">
          Station
        </label>
        <select className="form-control" id="inp-station" onChange={({ target }) => setStation(target.value)}>
          <option value={""}>--</option>
          {stationsList?.map((division, idx) => {
            return <option value={division}>{division}</option>;
          })}
        </select>
      </div>
      <div className="form-group mb-3">
        <label for="inp-delay" className="form-label">
          Congestion Level
        </label>
        <select className="form-control" id="inp-delay" onChange={({ target }) => setMinDelayMins(target.value)}>
          <option value={""}>--</option>
          <option value={2}>Mild Congestion (+2 mins)</option>
          <option value={5}>Congestion (+5 mins)</option>
          <option value={8}>Heavy Congestion (+8 mins)</option>
          <option value={10}>Severe Congestion (+10 mins)</option>
        </select>
      </div>
      <div className="form-group">
        <Button className="px-5 my-3" size="md" label="Go" color="success" onClick={submitSearchForm} />
      </div>
    </div>
  );
}
