export const ADD_NEW_SIGNAL_LABELS = [
    {
        type: "text",
        label: "Name of the junction",
        placeholder: "Enter junction name...",
        name: "name"
    },
    {
        type: "text",
        label: "Station",
        placeholder: "Enter station name...",
        name: "station"
    },
    {
        type: "text",
        label: "Signal Allotted NO",
        placeholder: "Enter Signal alloted number...",
        name: "signalNo"
    },
    {
        type: "text",
        label: "Sub-Division",
        placeholder: "Enter Sub Division",
        name: "subDivision"
    },
    {
        type: "text",
        label: "Type",
        placeholder: "Enter Road Type Details..",
        name: "type"
    },
];


export const DISLAY_SIGNAL_INFO = [
    {
        label: "Name of the junction",
        key: "name"
    },

    {
        label: "Station",
        key: "station"
    },

    {
        label: "Signal Allotted NO",
        key: "signalNo"
    },

    {
        label: "Sub-Division",
        key: "subDivision"
    },

    {
        label: "Type",
        key: "type"
    },
];

export function constructSignalHeader(signalInfo) {
    const { name: junctionName, signalNo } = signalInfo;
    return `${signalNo} | ${junctionName}`;
}
