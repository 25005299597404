import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//Action Constants
import { getLiveCongestionInfoById } from "../../../actions/live-congestion/LiveCongestionActions";

//Constants
import { STATUS_COLOR } from "../../../constants/GeneralConstants";

// Utils
import getColorCode from "../../../utils/TrafficUtils";

//Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectUrls";

// Components
import PlainDropdown from "../../../components/dropdown/Dropdown";
import LineChart, { SimpleBarChart } from "../../../components/charts/Charts";
import TableHeaders from "../../../components/table/TableHeaders";
import Spinner from "../../../components/spinner/Spinner";

//Section
import CongestionTable from "./CongestionTable";
import SignalLiveMonitorMapContainer from "./SignalLiveMonitorMapContainer";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";

//Page Constants
const dropdownItems = [
  { id: 1, label: "Sundays" },
  { id: 2, label: "Mondays" },
  { id: 3, label: "Tuesdays" },
  { id: 4, label: "Wednesdays" },
  { id: 5, label: "Thursdays" },
  { id: 6, label: "Fridays" },
  { id: 7, label: "Saturdays" },
];

const tableHeaders = [
  {
    title: {
      displayName: "Traffic Coming from",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Delay",
    },
  },
];

//TODO : sample data to show graph
const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

// Page Components
function DaysSelection() {
  return (
    <span className="col-4 d-none">
      <PlainDropdown
        items={dropdownItems}
        className="border btn rounded-lg shadow-none px-2 w-100"
        placeHolder="Select days"
      />
    </span>
  );
}

function SignalLiveMonitorInfo({ junction, junctionState = {} }) {
  // Junction info
  const { name, station, subDivision, signalNo } = junction || {};

  // Checkin-info
  const { policeMap = {} } = junctionState; // Police
  const { hasSignalRemote, hasChallanMachine, hasBreathAnalyser, hasBodyCamera } = junctionState; // Devices

  // Police Array
  const policeList = policeMap ? Object.values(policeMap) : [];

  return (
    <>
      <div className="row">
        <h4 className="mb-4">{name}</h4>
        <div className="col-4">
          <h6>Station</h6>
          <p className="fw-light">{station}</p>
        </div>
        <div className="col-4">
          <h6>Sub Station</h6>
          <p className="fw-light">{subDivision}</p>
        </div>
        <div className="col-4">
          <h6>Signal NO.</h6>
          <p className="fw-light">{signalNo}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h6>Active Officers</h6>

          <div>
            {policeList.map((police) => {
              const { id, name, phoneNo } = police;
              return (
                <div key={id}>
                  <p className="fw-light mb-1">{name}</p>
                  <p className="text-phone mb-2">{phoneNo}</p>
                </div>
              );
            })}
          </div>

          {policeList.length === 0 && ( //
            <small className="text-muted">No Check-ins at the Moment</small>
          )}
        </div>
        <div className="col-6">
          <h6>Devices</h6>

          {hasSignalRemote && ( //
            <p className="text-muted mb-1">Signal Remote</p>
          )}
          {hasChallanMachine && ( //
            <p className="text-muted mb-1">Challan Machine</p>
          )}
          {hasBreathAnalyser && ( //
            <p className="text-muted mb-1">Breath Analyzer</p>
          )}
          {hasBodyCamera && ( //
            <p className="text-muted mb-1">Body Camera</p>
          )}
        </div>
      </div>
    </>
  );
}

function TrafficStatusInfoTableDurationCell({ duration }) {
  //If no delay
  if (duration === 0) {
    return <td className="col-2">No delay</td>;
  }

  return (
    <td className="col-2">
      {duration} {duration === 1 ? "min" : "mins"}
    </td>
  );
}

function TrafficStatusInfoTableStatusCell({ colorCode, status }) {
  const statusColor = STATUS_COLOR[colorCode];
  return (
    <td className="col-2" style={{ color: statusColor }}>
      <span title={status} className="traffic-status-dot" style={{ backgroundColor: statusColor }}></span>
    </td>
  );
}
function TrafficStatusInfoTableBody({ trafficStates, feedJunctionsMap }) {
  return trafficStates?.map((trafficState, index) => {
    // Traffic Status for each road
    const { feedJunctionId, delayInSecs } = trafficState;

    //For each road
    const feedJunction = feedJunctionsMap[feedJunctionId];

    //To get color code
    const { status, colorCode, duration } = getColorCode({
      delayInSecs,
    });

    return (
      <tr key={index}>
        <td className="col-6">
          <strong>{feedJunction.signalNo}</strong> | {feedJunction.name}
        </td>

        {/* Traffic Status Info Table Status Cell */}
        <TrafficStatusInfoTableStatusCell status={status} colorCode={colorCode} />

        {/* Traffic Status Info Table Duration Cell */}
        <TrafficStatusInfoTableDurationCell duration={duration} />
      </tr>
    );
  });
}

function TrafficStatusInfo({ trafficStates, feedJunctionsMap }) {
  return (
    <div className="table-responsive">
      <h6>Traffic status</h6>
      <table className="table table-compact">
        <TableHeaders tableHeaders={tableHeaders} />
        <tbody>
          <TrafficStatusInfoTableBody trafficStates={trafficStates} feedJunctionsMap={feedJunctionsMap} />
        </tbody>
      </table>
    </div>
  );
}

//TO DO : Right now no data is available to display so d-none
function CongestionProbability({ congestionProfile = {} }) {
  // Render Avg. Profile
  const { hourlyMin = {}, hourlyAvg = {}, hourlyMax = {} } = congestionProfile;
  const hourlyProfileData = [];
  for (let i = 6; i < 21; i++) {
    const hourKey = "_" + i;
    const hourData = {
      hour: "" + i,
      min: Math.ceil((hourlyMin[hourKey] || 0) / 60),
      avg: Math.ceil((hourlyAvg[hourKey] || 0) / 60),
      max: Math.ceil((hourlyMax[hourKey] || 0) / 60),
    };

    hourlyProfileData.push(hourData);
  }

  return (
    <div className="pb-5">
      <hr className="hr-color" />

      <div className="">
        {/* Days selection dropdown */}
        <DaysSelection />
      </div>

      <h5 className="mb-2">Congestion Probablity</h5>
      <div className="py-3">
        {/* Tiny Bar Chart */}
        <TrafficDelayBarChart data={hourlyProfileData} />
      </div>
      <p className="fw-light mt-3 d-none">
        There is
        <span className="fw-bold"> 90% </span>
        chance of congestion at this hour
      </p>

      {/* Conestion table */}
      <CongestionTable />
    </div>
  );
}
function TrafficDelayBarChart({ data = [], XAxisLabel = "", YAxisLabel = "" }) {
  return (
    <ResponsiveContainer height={260}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis
          interval={1}
          label={{
            value: "Hour of Day",
            dy: 20,
          }}
          dataKey="hour"
        />
        <YAxis label={{ value: "Delay in mins", angle: -90, dx: -20 }} />

        <Bar dataKey={"avg"} fill="#ff700e" />
      </BarChart>
    </ResponsiveContainer>
  );
}

//TO DO : Right now no data is available to display so d-none
function TrafficSpeedProfile() {
  return (
    <div>
      <hr className="hr-color" />

      <div className="row">
        <div className="col mt-2">
          <h6>Traffic Speed Profile</h6>
        </div>
        {/* Days selection dropdown */}
        <DaysSelection />
      </div>
      <div className="mt-2">
        {/* Line Chart */}
        <LineChart data={data} />
      </div>
      <p className="fw-light mt-3">
        Usual Traffic Speed at this Hour is
        <span className="fw-semibold"> 15 kmph </span>
      </p>
      <p className="fw-light">
        Current Traffic Speed (live) is
        <span className="fw-semibold"> 13 Kmph </span>
      </p>
    </div>
  );
}

/**
 * Signal live monitor page
 */
export default function SignalLiveMonitorPage() {
  //Dispatch
  const dispatch = useDispatch();

  //Params
  const params = useParams();
  const { congestionId } = params;

  //Selector state
  const liveCongestionInfo = useSelector((state) => state.liveCongestion.liveCongestionInfo);
  const liveCongestionInfoLoading = useSelector((state) => state.liveCongestion.liveCongestionInfoLoading);

  //use Effect
  useEffect(() => {
    dispatch(getLiveCongestionInfoById({ congestionId }));
  }, [dispatch, congestionId]);

  // Live congestion
  const { junction, trafficStates, feedJunctionsMap, congestionProfile } = liveCongestionInfo || {};

  // Junction State
  const junctionState = liveCongestionInfo.junctionState || {};
  const { hourlyAvg, hourlyCounts } = junctionState; // Junction Historical info

  return (
    <div className="row gx-0">
      <div className="col-4 px-3 overflow-auto content">
        {/* Loader */}
        {liveCongestionInfoLoading && <Spinner className="mt-5" />}

        {/* After Loading */}
        {!liveCongestionInfoLoading && (
          <>
            <Link to={RedirectTo.LiveMonitorPageUrl} className="my-4 me-2 d-inline-block">
              <i className="fa-solid fa-arrow-left"></i> Go Back
            </Link>

            {/* Information regarding Signal */}
            <SignalLiveMonitorInfo junction={junction} junctionState={junctionState} />
            <hr className="hr-color" />

            {/* Information regarding Traffic */}
            <TrafficStatusInfo trafficStates={trafficStates} feedJunctionsMap={feedJunctionsMap} />

            {/* Congestaion Probability */}
            <CongestionProbability congestionProfile={congestionProfile} />

            {/* Traffic speed Profile */}
            {/*
            <TrafficSpeedProfile />
             */}
          </>
        )}
      </div>

      {/* Map container */}
      <SignalLiveMonitorMapContainer
        junction={junction}
        trafficStates={trafficStates}
        feedJunctionsMap={feedJunctionsMap}
      />
    </div>
  );
}
