import { useEffect, useState } from "react";
import { Pagination } from "react-headless-pagination";

//Hooks
import { useUpdateQueryParams } from "../../hooks/UpdateQueryParams";

//Constants
import {
  DEFAULT_PAGINATION,
  QueryParamsKeys,
} from "../../constants/PaginationConstants";

/**
 * Table Pagination Component
 * @param {*} pageNumber : Current page number
 * @param {*} pageSize : Number of data to be displayed in table
 * @param {*} totalPages : Total number of pages
 * @param {*} containerClassName
 */
export default function TablePagination({
  pageNumber,
  pageSize,
  totalPages,
  containerClassName = "d-flex flex-row-reverse",
}) {
  //Update query params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  //State
  const [page, setPage] = useState(0);

  //Get values from URL
  const searchText = searchParams.get(QueryParamsKeys.searchText);
  const junctionId = searchParams.get(QueryParamsKeys.junctionId);
  const minimumTime = searchParams.get(QueryParamsKeys.minimumTime);
  const maximumTime = searchParams.get(QueryParamsKeys.maximumTime);
  const minimumDelay = searchParams.get(QueryParamsKeys.minimumDelay);
  const maximumDelay = searchParams.get(QueryParamsKeys.maximumDelay);

  useEffect(() => {
    //Updating page number, If the page number is null, it will take 0 as current page.
    setPage(pageNumber ? pageNumber - 1 : page);
  }, [pageNumber]);

  //Function
  function handleChange(pageNo) {
    setPage(pageNo);

    //Construct params
    const params = {
      pn: pageNo + 1,
      ps: pageSize,
      search: searchText,
      junction: junctionId,
      min_time: minimumTime,
      max_time: maximumTime,
      min_delay: minimumDelay,
      max_delay: maximumDelay,
    };

    //Updating Query Params
    updateQueryParams({ params });
  }

  if (totalPages <= 1) {
    return null;
  }

  return (
    <ul className={`pagination mt-4 ${containerClassName}`}>
      {/* Pagination */}
      <Pagination
        currentPage={page}
        setCurrentPage={handleChange}
        totalPages={totalPages}
        edgePageCount={2}
        middlePagesSiblingCount={0}
        className="d-flex"
        truncableText="..."
        truncableClassName="disabled me-2"
      >
        {/* Previous Button */}
        <Pagination.PrevButton className="btn btn-secondary me-2 btn-sm">
          <i className="fa-solid fa-angle-left"></i> Previous
        </Pagination.PrevButton>

        <div className="d-flex align-items-center justify-content-center flex-grow-1">
          {/* Current Page Number Display Button */}
          <Pagination.PageButton
            activeClassName="active"
            inactiveClassName=""
            className="text-decoration-none btn btn me-2"
          />
        </div>

        {/* Next Button */}
        <Pagination.NextButton className="btn btn-secondary ms-2 btn-sm">
          Next <i className="fa-solid fa-angle-right"></i>
        </Pagination.NextButton>
      </Pagination>
    </ul>
  );
}

TablePagination.defaultProps = {
  pageNumber: DEFAULT_PAGINATION.pageNumber,
  pageSize: DEFAULT_PAGINATION.pageSize,
  totalPages: DEFAULT_PAGINATION.totalPages,
};
