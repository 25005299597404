import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

//Urls
import { RedirectTo } from "./urls/page-urls/RedirectUrls";

// Components
import Navbar from "./components/navbar/Navbar";

// CSS
import "./App.scss";

// JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Home Page
import HomePage from "./pages/home-page/HomePage";

// Privacy Policy Page
import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicyPage";

// Signal Pages
import LoginPage from "./pages/login-page/LoginPage";

import LiveMonitorPage from "./pages/live-monitor/LiveMonitorPage";
import SignalLiveMonitorPage from "./pages/live-monitor/signal-live-monitor/SignalLiveMonitorPage";

import CongestionHistoryPage from "./pages/congestion/CongestionHistoryPage";
import CongestionReportPage from "./pages/congestion/report/CongestionReportPage";
import CongestionSnapshotPage from "./pages/congestion/snapshot/CongestionSnapshotPage";

import CreateSignalPage from "./pages/signals/CreateSignalPage";
import ViewSignalPage from "./pages/signals/ViewSignalPage";
import EditSignalPage from "./pages/signals/EditSignalPage";
import SignalListPage from "./pages/signals/signal-list/SignalListPage";
import SignalMapPage from "./pages/signals/signal-map/SignalMapPage";

import PoliceStaffPage from "./pages/user/PoliceStaffPage";
import PoliceStaffInfoPage from "./pages/police-staff-info/PoliceStaffInfoPage";
import AddNewPoliceStaffPage from "./pages/add-new-police-staff/AddNewPoliceStaffPage";
import EditPoliceStaffPage from "./pages/user/EditPoliceStaffPage";

import NotificationsPage from "./pages/notification/NotificationsPage";

//Protected Route
import ProtectedRoute from "./ProtectedRoute";

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: RedirectTo.HomePageUrl,
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: RedirectTo.HomePageUrl,
        element: <HomePage />,
      },
      {
        path: RedirectTo.LiveMonitorPageUrl,
        element: <LiveMonitorPage />,
      },
      {
        path: RedirectTo.signalLiveMonitorPageUrl,
        element: <SignalLiveMonitorPage />,
      },

      {
        path: RedirectTo.CongestionHistoryPageUrl,
        element: <CongestionHistoryPage />,
      },
      {
        path: RedirectTo.CongestionReportPageUrl,
        element: <CongestionReportPage />,
      },
      {
        path: RedirectTo.CongestionSnapshotPageUrl,
        element: <CongestionSnapshotPage />,
      },

      {
        path: RedirectTo.listSignalsPageUrl,
        element: <SignalListPage />,
      },
      {
        path: RedirectTo.SignalMapViewPageUrl,
        element: <SignalMapPage />,
      },
      {
        path: RedirectTo.viewSignalPageUrl,
        element: <ViewSignalPage />,
      },
      {
        path: RedirectTo.createSignalPageUrl,
        element: <CreateSignalPage />,
      },
      {
        path: RedirectTo.editSignalPageUrl,
        element: <EditSignalPage />,
      },

      {
        path: RedirectTo.PoliceStaffPageUrl,
        element: <PoliceStaffPage />,
      },
      {
        path: RedirectTo.PoliceStaffInfoPageUrl,
        element: <PoliceStaffInfoPage />,
      },
      {
        path: RedirectTo.AddNewPoliceStaffPageUrl,
        element: <AddNewPoliceStaffPage />,
      },
      {
        path: RedirectTo.EditPoliceStaffPageUrl,
        element: <EditPoliceStaffPage />,
      },
    ],
  },
  {
    path: RedirectTo.LoginPageUrl,
    element: <LoginPage />,
  },
  {
    path: RedirectTo.PrivacyPolicyPageUrl,
    element: <PrivacyPolicyPage />,
  },
  {
    path: RedirectTo.NotificationsPageUrl,
    element: <NotificationsPage />,
  },
]);

function App() {
  return (
    <div className="page">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
