import { toast } from "react-toastify";
import { all, put, takeLatest, select } from "redux-saga/effects";

// Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

// Constants
import { Signal } from "../../constants/action-constants/signals/SignalActionConstants";
import { Road } from "../../constants/action-constants/roads/RoadActionConstants";
import { RoadSearch } from "../../constants/action-constants/roads/RoadSearchActionConstants";
import { SignalSearch } from "../../constants/action-constants/signals/SignalSearchActionConstants";

//Urls
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";
import { RADIUS } from "../../constants/GeneralConstants";
import { DEFAULT_PAGINATION } from "../../constants/PaginationConstants";

// API's
import {
  createSignalApi,
  getSignalSummaryByIdApi,
  updateSignalApi as editSignalApi,
  toggleSignalMonitoringStatusByIdApi,
} from "../../apis/signals/SignalsAPI";
import { searchSignalApi } from "../../apis/signals/SignalSearchAPI";

export function* getSignalSummary(action) {
  const { signalId } = action.payload;
  try {
    const { junction, roadsMap, feedJunctionsMap } = yield getSignalSummaryByIdApi(signalId);
    yield put({
      type: Signal.GET_SIGNAL_INFO_SUCCESS,
      payload: { signalInfo: junction, signalRoadMap: roadsMap, signalFeedJunctionsMap: feedJunctionsMap },
    });

    const { latitude, longitude } = junction["location"];

    // Update the GeoLocation
    const locationStr = `${latitude},${longitude}`;
    yield put({
      type: Road.SET_GEO_LOCATION,
      payload: { locationStr },
    });

    // Update the states of RoadReducer (Selected Road Ids)
    const roadIds = Object.keys(roadsMap);
    yield put({
      type: Road.SELECT_MULTIPLE_ROADS,
      payload: { roadIds: roadIds },
    });

    // Fetch all the roads near the signal location
    yield put({
      type: RoadSearch.GET_GEO_NEAR_ROADS,
      payload: {
        latitude,
        longitude,
        radiusInMts: RADIUS.fiveKm,
      },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Signal.GET_SIGNAL_INFO_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* createNewSignal(action) {
  const { signalData, navigate } = action.payload;
  try {
    const geoLocationStr = yield select((state) => state.road.geoLocationPoint);
    const roadIdsMap = yield select((state) => state.road.roadSelectionMap);
    const roadIds = Object.keys(roadIdsMap);

    // TODO: Fetch these values from the reducer once integrated map view
    const cityId = "city-chennai";
    signalData["roadIds"] = roadIds;
    signalData["geoLocationStr"] = geoLocationStr;
    signalData["cityId"] = cityId;

    // TODO: These value we will be taking from the form itself, we need to add a check box
    // Saying whether you want to monitor or not, as of now hardcoading it
    signalData["isMonitoringActive"] = true;

    const response = yield createSignalApi(signalData);

    // TODO: Once the API is updated then we might need the response from the API
    yield put({
      type: Signal.CREATE_SIGNAL_SUCCESS,
      // payload: { response },
    });

    // Once the API is success we redirect to SignalViewPage
    navigate(
      RedirectTo.listSignalsPageUrl.replace(
        ":pageNumber",
        DEFAULT_PAGINATION.pageNumber
      )
    );
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Signal.CREATE_SIGNAL_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* editSignalInfo(action) {
  const { signalData, signalId, navigate } = action.payload;
  try {
    const geoLocationStr = yield select((state) => state.road.geoLocationPoint);
    const roadIdsMap = yield select((state) => state.road.roadSelectionMap);
    const roadIds = Object.keys(roadIdsMap);

    // TODO: Fetch these values from the reducer once integrated map view
    signalData["roadIds"] = roadIds;
    signalData["geoLocationStr"] = geoLocationStr;

    // TODO: These value we will be taking from the form itself, we need to add a check box
    // Saying whether you want to monitor or not, as of now hardcoading it
    signalData["isMonitoringActive"] = true;

    const response = yield editSignalApi(signalData, signalId);

    // TODO: Once the API is updated then we might need the response from the API
    yield put({
      type: Signal.EDIT_SIGNAL_SUCCESS,
      // payload: { response },
    });

    // Once the API is success we redirect to SignalViewPage
    navigate(RedirectTo.listSignalsPageUrl);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Signal.EDIT_SIGNAL_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* toggleSignalMonitoringStatusById(action) {
  const { monitoringStatus, signalId } = action.payload;

  try {
    yield toggleSignalMonitoringStatusByIdApi(monitoringStatus, signalId);

    const { items, pagination } = yield searchSignalApi();

    //Dispatch action to refresh signal list table
    yield put({
      type: SignalSearch.GET_SIGNAL_LIST_SUCCESS,
      payload: { signalList: items, signalListPagination: pagination },
    });

    //Disptach
    yield put({
      type: Signal.TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID_SUCCESS,
      payload: { signalId },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Signal.TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Signal.CREATE_SIGNAL, createNewSignal),
    takeLatest(Signal.GET_SIGNAL_INFO, getSignalSummary),
    takeLatest(Signal.EDIT_SIGNAL, editSignalInfo),
    takeLatest(
      Signal.TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID,
      toggleSignalMonitoringStatusById
    ),
  ]);
}
