import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { Icon } from "leaflet";

// Actions
import { searchSignals } from "../../../actions/signals/SignalSearchActions";

// Constants
import { GEO_JSON_BOUNDRIES_DATA } from "../../../constants/GeoJSONBoundries";
import { CHENNAI_LAT_LONG } from "../../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";
import LLMap from "../../../components/map/LLMap";

// Icon
import markerIconPng from "leaflet/dist/images/marker-icon.png";

const signalIcon = new Icon({
  iconUrl: markerIconPng,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

// Page Components

function JunctionMarkerPopup({ signal }) {
  const { name, station, signalNo, feedJunctionIds } = signal;

  return (
    <Popup>
      <div>
        <h4>{signalNo}</h4>
        <p className="my-0">{name}</p>
        <p className="my-2">{station}</p>
      </div>
    </Popup>
  );
}

function SignalMarkers({ signalList }) {
  return (
    <>
      {signalList?.map((signal, index) => {
        // Signal Info
        const { id, location } = signal;
        const { latitude, longitude } = location || {};
        if (!latitude || !longitude) {
          return null;
        }

        return (
          <Marker key={id} position={[latitude, longitude]} icon={signalIcon}>
            <JunctionMarkerPopup signal={signal} />
            <Tooltip permanent>{signal.signalNo}</Tooltip>
          </Marker>
        );
      })}
    </>
  );
}

/**
 * Signals page
 */
export default function SignalMapPage() {
  // Dispatch
  const dispatch = useDispatch();

  //State
  const [searchText, setSearchText] = useState("");

  // Selector States
  const signalList = useSelector((state) => state.signalSearch.signalList) || [];
  const signalListLoading = useSelector((state) => state.signalSearch.signalListLoading);

  useEffect(() => {
    dispatch(searchSignals({ searchText, pageNumber: 1, pageSize: 500 }));
  }, []);

  // Page-loading
  if (signalListLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="map-cont">
      <LLMap center={CHENNAI_LAT_LONG} geoJSONBoundaryData={GEO_JSON_BOUNDRIES_DATA}>
        {/*Traffic signal Markers */}
        <SignalMarkers signalList={signalList} />
      </LLMap>
    </div>
  );
}
