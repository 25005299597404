export const Signal = {
  // Create Signal Actions
  CREATE_SIGNAL: "CREATE_SIGNAL",
  CREATE_SIGNAL_SUCCESS: "CREATE_SIGNAL_SUCCESS",
  CREATE_SIGNAL_FAILURE: "CREATE_SIGNAL_FAILURE",

  // Update Signal Actions
  EDIT_SIGNAL: "EDIT_SIGNAL",
  EDIT_SIGNAL_SUCCESS: "EDIT_SIGNAL_SUCCESS",
  EDIT_SIGNAL_FAILURE: "EDIT_SIGNAL_FAILURE",

  // Get Signal Info
  GET_SIGNAL_INFO: "GET_SIGNAL_INFO",
  GET_SIGNAL_INFO_SUCCESS: "GET_SIGNAL_INFO_SUCCESS",
  GET_SIGNAL_INFO_FAILURE: "GET_SIGNAL_INFO_FAILURE",

  //Switch Signal Monitoring Status
  TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID:
    "TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID",
  TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID_SUCCESS:
    "TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID_SUCCESS",
  TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID_FAILURE:
    "TOGGLE_SIGNAL_MONITORING_STATUS_BY_ID_FAILURE",
};
