import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

//Api
import { getLiveCongestionInfoByIdApi } from "../../apis/live-congestion/LiveCongestionAPI";

//Action Constants
import { LiveCongestion } from "../../constants/action-constants/live-congestion/LiveCongestionActionConstants";

//Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

export function* getLiveCongestionInfoById(action) {
  const { congestionId } = action.payload;

  try {
    const liveCongestionInfo = yield getLiveCongestionInfoByIdApi(congestionId);

    yield put({
      type: LiveCongestion.GET_LIVE_CONGESTION_INFO_BY_ID_SUCCESS,
      payload: { liveCongestionInfo },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: LiveCongestion.GET_LIVE_CONGESTION_INFO_BY_ID_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      LiveCongestion.GET_LIVE_CONGESTION_INFO_BY_ID,
      getLiveCongestionInfoById
    ),
  ]);
}
