// Action Constants
import { Reports } from "../../constants/action-constants/reports/ReportsActionConstants";

const initialState = {
  // Congestion Report Data
  congestionReport: {},
  congestionReportLoading: false,
  congestionReportError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Download Congestion Data
    case Reports.GET_CONGESTION_REPORT: {
      return {
        ...state,
        congestionReportLoading: true,
      };
    }

    case Reports.GET_CONGESTION_REPORT_SUCCESS: {
      const { congestionReport } = action.payload;
      return {
        ...state,
        congestionReport: congestionReport,
        congestionReportLoading: false,
      };
    }

    case Reports.GET_CONGESTION_REPORT_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        congestionReportLoading: false,
        congestionReportError: error,
      };
    }

    default:
      return state;
  }
};
