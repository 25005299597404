import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

//Api
import { getLiveCongestionListApi } from "../../apis/live-congestion/LiveCongestionListAPI";

//Action Constants
import { LiveCongestionList } from "../../constants/action-constants/live-congestion/LiveCongestionListActionConstants";

//Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

export function* getLiveCongestionList(action) {
  const { searchText, minDelayMins, division, subDivision, station, pageNumber, pageSize } = action.payload;

  try {
    const { junctionsMap, junctionStatesMap, items, pagination } = yield getLiveCongestionListApi(
      searchText,
      minDelayMins,
      division,
      subDivision,
      station,
      pageNumber,
      pageSize
    );

    yield put({
      type: LiveCongestionList.GET_LIVE_CONGESTION_LIST_SUCCESS,
      payload: {
        junctionsMap: junctionsMap,
        junctionStatesMap: junctionStatesMap,
        liveCongestionList: items,
        liveCongestionListPagination: pagination,
      },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: LiveCongestionList.GET_LIVE_CONGESTION_LIST_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(LiveCongestionList.GET_LIVE_CONGESTION_LIST, getLiveCongestionList)]);
}
