import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHours, setMinutes } from "date-fns";

// Components
import Input from "../../components/input/Input";
import { Button } from "../../components/button/Button";
import DateTimePicker from "../../components/date-time-picker/DateTimePicker";

// Actions
import { getLiveCongestionList } from "../../actions/live-congestion/ListCongestionListActions";
import { getSnapshotCongestionList } from "../../actions/snapshot/SnapshotCongestionListActions";

// Constants
import { DIVISION_LIST, SUB_DIVISION_LIST, STATION_LIST } from "../../constants/JunctionConstants";

/**
 * Congestion section
 */
export default function CongestionSearchForm({ isLive = true, refreshSecs, freeFlowDelay, setFreeFlowDelay }) {
  //Dispatch
  const dispatch = useDispatch();

  //State
  const [searchText, setSearchText] = useState("");
  const [minDelayMins, setMinDelayMins] = useState("");
  const [division, setDivision] = useState("");
  const [subDivision, setSubDivision] = useState("");
  const [station, setStation] = useState("");
  const [dateTime, setDateTime] = useState();

  // Data
  const [divisionsList, setDivisionsList] = useState(DIVISION_LIST);
  const [subDivisionsList, setSubDivisionsList] = useState(SUB_DIVISION_LIST);
  const [stationsList, setStationsList] = useState(STATION_LIST);

  // Initial Loading
  useEffect(() => {
    if (refreshSecs === 1) {
      dispatch(getLiveCongestionList({ searchText, minDelayMins, division, subDivision, station }));
    }
  }, [dispatch, refreshSecs]);

  // Search Function
  function submitSearchForm() {
    if (refreshSecs) {
      dispatch(getLiveCongestionList({ searchText, minDelayMins, division, subDivision, station }));
      return;
    }
    const time = dateTime ? dateTime.getTime() : Date.now();
    dispatch(getSnapshotCongestionList({ searchText, minDelayMins, division, subDivision, station, time }));
  }

  function resetSearchForm() {
    // setSearchText("");
    // setMinDelayMins("");
    // setDivision();
    // setSubDivision("");
    // setStation("");
  }

  return (
    <div className="form-congestion-search mb-4">
      <div className="row mb-3">
        <div className="col-3">
          <label htmlFor="inp-division" className="form-label">
            District
          </label>
          <select className="form-control" id="inp-division" onChange={({ target }) => setDivision(target.value)}>
            <option value={""}>--</option>
            {divisionsList?.map((division, idx) => {
              return <option value={division}>{division}</option>;
            })}
          </select>
        </div>
        <div className="col-3">
          <label htmlFor="inp-sub-division" className="form-label">
            Sub Division
          </label>
          <select
            className="form-control"
            id="inp-sub-division"
            onChange={({ target }) => setSubDivision(target.value)}
          >
            <option value={""}>--</option>
            {subDivisionsList?.map((division, idx) => {
              return <option value={division}>{division}</option>;
            })}
          </select>
        </div>
        <div className="col-4">
          <label htmlFor="inp-station" className="form-label">
            Station
          </label>
          <select className="form-control" id="inp-station" onChange={({ target }) => setStation(target.value)}>
            <option value={""}>--</option>
            {stationsList?.map((division, idx) => {
              return <option value={division}>{division}</option>;
            })}
          </select>
        </div>
        <div className="col-2">
          <label htmlFor="inp-delay" className="form-label">
            Min. Delay
          </label>
          <input
            id="inp-delay"
            type="number"
            className="form-control"
            onChange={({ target }) => setMinDelayMins(target.value)}
          />
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-8">
          {!isLive && ( //
            <DateTimePicker
              dateTime={dateTime}
              setDateTime={setDateTime}
              minDate={new Date("June 6, 2023")}
              maxDate={new Date()}
              minTime={setHours(setMinutes(new Date(), 0), 8)}
              maxTime={setHours(new Date(), 20)}
              timeInterval={5}
            />
          )}

          {isLive && ( //
            <div className="form-group">
              <select className="form-select" name="" onChange={(e) => setFreeFlowDelay(!freeFlowDelay)}>
                <option value={true} selected={freeFlowDelay}>
                  Delay from Freeflow Traffic
                </option>
                <option value={false} selected={!freeFlowDelay}>
                  Delay from Usual Traffic
                </option>
              </select>
            </div>
          )}

          {/* Input */}
          <Input
            className="d-none"
            placeholder="Search a Location here..."
            onChange={({ target }) => setSearchText(target.value)}
          />
        </div>
        <div className="col-2">
          <Button className="d-none" size="md" label="Reset" color="info" onClick={resetSearchForm} />
        </div>
        <div className="col-2">
          <Button className="" size="md" label="Search" color="success" onClick={submitSearchForm} />
        </div>
      </div>
    </div>
  );
}
