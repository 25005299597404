//Action Constants
import { User } from "../../constants/action-constants/user/UserActionConstants";

//initial state
const initialState = {
  //Get user Info by id
  userInfo: {},
  userInfoError: "",
  userInfoLoading: false,

  //Add user
  addUserError: "",
  addUserLoading: false,

  //Edit user by id
  editUserError: "",
  editUserLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Get user Info by id
    case User.GET_USER_INFO_BY_ID:
      return {
        ...state,
        userInfoLoading: true,
      };

    case User.GET_USER_INFO_BY_ID_SUCCESS:
      const { userInfo } = action.payload;

      return {
        ...state,
        userInfoLoading: false,
        userInfo,
      };

    case User.GET_USER_INFO_BY_ID_FAILURE:
      return {
        ...state,
        userInfoLoading: false,
        userInfoError: action.payload.error,
      };

    //Add user
    case User.ADD_USER:
      return {
        ...state,
        addUserLoading: true,
      };

    case User.ADD_USER_SUCCESS:
      return {
        ...state,
        addUserLoading: false,
      };

    case User.ADD_USER_FAILURE:
      return {
        ...state,
        addUserLoading: false,
        addUserError: action.payload.error,
      };

    //Edit user by id
    case User.EDIT_USER_BY_ID:
      return {
        ...state,
        editUserLoading: true,
      };

    case User.EDIT_USER_BY_ID_SUCCESS:
      return {
        ...state,
        editUserLoading: false,
      };

    case User.EDIT_USER_BY_ID_FAILURE:
      return {
        ...state,
        editUserLoading: false,
        editUserError: action.payload.error,
      };

    default:
      return state;
  }
};
