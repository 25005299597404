//Action Constants
import { LiveCongestionList } from "../../constants/action-constants/live-congestion/LiveCongestionListActionConstants";

// Constants
import { FILTERS } from "../../constants/JunctionConstants";

//initial state
const initialState = {
  //Get live congestion list
  liveCongestionList: [],
  liveCongestionListPagination: {},
  liveCongestionListError: "",
  liveCongestionListLoading: false,
  junctionsMap: {},
  junctionStatesMap: {},

  // Enable/Disable Devices, Enable/Disable boundary  visibility on the Map
  filtersMap: {
    [FILTERS.REGION_BOUNDARY]: true,
    [FILTERS.BODY_CAMERA]: false,
    [FILTERS.CHALAN_MACHINE]: false,
    [FILTERS.SIGNAL_REMOTE]: false,
    [FILTERS.BREATH_ANALYSER]: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Get live congestion list
    case LiveCongestionList.GET_LIVE_CONGESTION_LIST:
      return {
        ...state,
        liveCongestionListLoading: true,
      };

    case LiveCongestionList.GET_LIVE_CONGESTION_LIST_SUCCESS:
      const { junctionsMap, junctionStatesMap, liveCongestionList, liveCongestionListPagination } = action.payload;

      return {
        ...state,
        liveCongestionListLoading: false,
        liveCongestionList,
        liveCongestionListPagination,
        junctionsMap,
        junctionStatesMap,
      };

    case LiveCongestionList.GET_LIVE_CONGESTION_LIST_FAILURE:
      return {
        ...state,
        liveCongestionListLoading: false,
        liveCongestionListError: action.payload.error,
      };

    case LiveCongestionList.ENABLE_FILTER: {
      const { filterType } = action.payload;
      const filtersMap = { ...state.filtersMap };
      filtersMap[filterType] = true;
      return {
        ...state,
        filtersMap: filtersMap,
      };
    }

    case LiveCongestionList.DISABLE_FILTER: {
      const { filterType } = action.payload;
      const filtersMap = { ...state.filtersMap };
      filtersMap[filterType] = false;
      return {
        ...state,
        filtersMap: filtersMap,
      };
    }

    default:
      return state;
  }
};
