//Constants
import { DEFAULT_PAGINATION } from "../../constants/PaginationConstants";

// Utils
import { sendGetRequest } from "../../utils/api/ApiUtils";

// URLs
import { searchSignalsUrl } from "../../urls/api-urls/SignalsURL";

export async function searchSignalApi(searchText, pageNumber, pageSize) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const params = {
    pn: pageNumber || DEFAULT_PAGINATION.pageNumber,
    ps: pageSize || DEFAULT_PAGINATION.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  return sendGetRequest(searchSignalsUrl, params, true, headerParams);
}
