import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//Actions
import { toggleSignalMonitoringStatusById } from "../../../actions/signals/SignalActions";

//Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectUrls";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import TableLoader from "../../../components/table/TableLoader";
import TablePagination from "../../../components/table/TablePagination";
import Spinner from "../../../components/spinner/Spinner";
import TableDataNotFound from "../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Signal N0.",
    },
  },
  {
    title: {
      displayName: "Sub Division",
    },
  },
  {
    title: {
      displayName: "Station",
    },
  },
  {
    title: {
      displayName: "Name of the Junction",
    },
  },
  {
    title: {
      displayName: "Officer Stationed",
    },
  },
  {
    title: {
      displayName: "Monitoring Status",
    },
  },
  {
    title: {
      displayName: "Last Updated date",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

function SignalStatus({ signalStatus, selectedSignalsLoading, signalId }) {
  //Dispatch
  const dispatch = useDispatch();

  //Monitoring Icon
  const statusClassName = signalStatus
    ? "fa-solid fa-toggle-on toggle-icon"
    : "fa-solid fa-toggle-off toggle-icon text-danger";

  //Toggling Function
  function toggleMonitoringStatus() {
    dispatch(
      toggleSignalMonitoringStatusById({
        monitoringStatus: !signalStatus,
        signalId,
      })
    );
  }

  //Loader
  if (selectedSignalsLoading[signalId]) {
    return (
      <td>
        {/* Spinner */}
        <Spinner />
      </td>
    );
  }

  return (
    <td className="text-center" onClick={toggleMonitoringStatus}>
      <i className={statusClassName}></i>
    </td>
  );
}

function SignalStation({ signalStation, signalId }) {
  const redirectUrl = RedirectTo.viewSignalPageUrl.replace(":signalId", signalId);
  return (
    <td className="text-primary col-1">
      <Link to={redirectUrl} className="text-decoration-none">
        {signalStation}
      </Link>
    </td>
  );
}

function EditSignal({ signalId }) {
  const redirectUrl = RedirectTo.editSignalPageUrl.replace(":signalId", signalId);
  return (
    <td className="text-primary col-1">
      <Link to={redirectUrl} className="text-decoration-none">
        {"Edit"}
      </Link>
    </td>
  );
}

function SignalsTableBody({ signalData, signalDataLoading, selectedSignalsLoading }) {
  if (signalDataLoading) {
    return <TableLoader colSpan={8} />;
  }

  if (!signalData || signalData.length == 0) {
    return <TableDataNotFound colSpan={8} message={"No Signals Data Found"} />;
  }

  return signalData.map((eachSignaldata, index) => {
    return <SignalTableRow key={index} signalRow={eachSignaldata} selectedSignalsLoading={selectedSignalsLoading} />;
  });
}

function SignalTableRow({ signalRow, selectedSignalsLoading }) {
  //Signal
  const { signalNo, subDivision, station, id, name, isMonitoringActive } = signalRow;

  const signalViewURL = RedirectTo.viewSignalPageUrl.replace(":signalId", id);

  return (
    <tr>
      <td className="col-1">{signalNo}</td>
      <td className="col-1">{subDivision}</td>

      {/* Station Name */}
      <SignalStation signalStation={station} signalId={id} />

      <td className="col-3">
        <Link to={signalViewURL} className="text-decoration-none">
          {name}
        </Link>
      </td>
      <td className="col-1">{"Sarvanan"}</td>

      {/* Station monitoring status (True/False) */}
      <SignalStatus signalId={id} signalStatus={isMonitoringActive} selectedSignalsLoading={selectedSignalsLoading} />

      <td className="col-2">{"Mar 28 2023"}</td>

      {/* Edit Signal */}
      <EditSignal signalId={id} />
    </tr>
  );
}

/**
 * Signals table
 */
export default function SignalsTable({ signalData, signalDataLoading, signalListPagination, pageNumber }) {
  //Selector state
  const selectedSignalsLoading = useSelector((state) => state.signal.selectedSignalsLoading);

  const { totalPages, pageSize } = signalListPagination;

  return (
    <div className="table-responsive">
      <table className="table table-compact  mt-3 bg-white">
        {/* Table headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        {/* Table Body */}
        <tbody>
          <SignalsTableBody
            signalData={signalData}
            signalDataLoading={signalDataLoading}
            selectedSignalsLoading={selectedSignalsLoading}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalPages={totalPages} />
    </div>
  );
}
