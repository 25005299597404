/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Constants
import { STATUS_COLOR } from "../../constants/GeneralConstants";
import gctp_logo from "../../assets/images/logo.jpg";

//Utils
import getColorCode from "../../utils/TrafficUtils";

// Sections
import NotificationSearchForm from "./NotificationSearchForm";
import ShowDateAndTime from "../../components/show-date-time/ShowDateAndTime";

// Page Components

/**
 * Live Monitor Page
 */
export default function NotificationsPage() {
  //Dispatch
  const dispatch = useDispatch();

  // Selector State
  const liveCongestionList = useSelector((state) => state.liveCongestionList.liveCongestionList);
  const liveCongestionListLoading = useSelector((state) => state.liveCongestionList.liveCongestionListLoading);

  const [showSearchForm, setShowSearchForm] = useState(true);

  // use Effect
  const refreshInteval = 10;
  const [refreshSecs, setRefreshSecs] = useState(1);
  useEffect(() => {
    if (!refreshSecs) {
      return;
    }

    const refreshIntervalId = setInterval(() => {
      const newRefreshSecs = refreshSecs === 1 ? refreshInteval : refreshSecs - 1;
      setRefreshSecs(newRefreshSecs);
    }, 1000);

    return () => clearInterval(refreshIntervalId);
  }, [refreshSecs]);

  // Results Length
  const resultLength = liveCongestionList?.length || 0;

  return (
    <div className="mx-auto" style={{ maxWidth: "400px", minHeight: "600px" }}>
      <nav className="top-navbar navbar">
        <div className="container-fluid">
          <img src={gctp_logo} alt="GCTP" width="40" height="40" />

          {/* Shows Date and Time */}
          <ShowDateAndTime />
        </div>
      </nav>

      {/** Search Form */}
      {showSearchForm && <NotificationSearchForm refreshSecs={refreshSecs} setShowSearchForm={setShowSearchForm} />}

      {/** Congestion List */}
      {!showSearchForm && (
        <div className="px-2">
          <h5 className="d-flex justify-content-between my-2">
            <span className="mt-2">Traffic Congestions ({liveCongestionListLoading ? "-" : resultLength})</span>
            <small className="d-none">Auto refresh in {refreshSecs} secs</small>
            <a className="btn btn-link" href="#" onClick={() => setShowSearchForm(!showSearchForm)}>
              &lt;- go back
            </a>
          </h5>

          {liveCongestionListLoading && (
            <div className="d-flex justify-content-center mt-3">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {!liveCongestionListLoading &&
            liveCongestionList?.map((congestion, index) => {
              const { junctionName, subDivision, station, signalNo, delayInSecs, timestamp } = congestion;

              // To get color code
              const { colorCode, duration } = getColorCode({
                delayInSecs,
              });

              // Redirect
              return (
                <div key={index} className="card mb-2 px-3 py-2">
                  <div className="row gx-0">
                    <div className="col-1">
                      <p className="pt-3">
                        <i className={`fa-solid fa-circle fa-2xl`} style={{ color: STATUS_COLOR[colorCode] }}></i>
                      </p>
                    </div>
                    <div className="col-11">
                      {/* NotificationTableStatusCell */}
                      <p className="mb-0 px-2">
                        <strong>
                          {duration} {duration === 1 ? "min" : "mins"}{" "}
                        </strong>{" "}
                        |{" "}
                        <small>
                          {" "}
                          {signalNo} | {station} | {subDivision}{" "}
                        </small>
                      </p>
                      <div className="mb-0 px-2" title={junctionName}>
                        {junctionName}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
