/**
 * Junction Related Constants
 */

/**
 * Divisions
 */
export const DIVISION_LIST = ["East", "North", "South", "West"];

/**
 * Sub-Divisions
 */
export const SUB_DIVISION_LIST = [
  "East -I",
  "East -II",
  "East -III",
  "North -I",
  "North -II",
  "South -I",
  "South -II",
  "South -III",
  "West -I",
  "West -III",
];

/**
 * Stations
 */
export const STATION_LIST = [
  "B1 North Beach",
  "B3 Fort",
  "C1 Flower Bazaar",
  "C2 Elephant Gate",
  "D1 Triplicane",
  "D2 Anna Salai",
  "D5 Marina",
  "E1 Mylapore",
  "E2 Royapettah",
  "E3 Teynampet",
  "E4 Abiramipuram",
  "F2 Egmore",
  "F3 Nungambakkam",
  "G1 Vepery",
  "G2 Periamet",
  "G3 Kilpauk",
  "G7 Chetpet",
  "H1 Washermanpet",
  "H3 Tondaiyarpet",
  "H5 New Washermanpet",
  "H8 Thiruvotriyur",
  "J1 Saidapet",
  "J2 Adayar",
  "J3 Guindy",
  "J4 Kotturpuram",
  "J5 Shastri Nagar",
  "J6 Thiruvanmiyur",
  "J7 Velachery",
  "J8 Nelankarai",
  "J9 Thoraipakkam",
  "K1 Sembium",
  "K10 Koyembedu",
  "K3 Aminjikarai",
  "K4 Anna Nagar",
  "K7 ICF",
  "M1 Madhavaram",
  "N1 Royapuram",
  "N3 Muthyalpet",
  "P1 Pulianthope",
  "P3 Vysarpadi",
  "P6 Kodungaiyur",
  "R1 Mambalam",
  "R2 Kodambakkam",
  "R3 Ashok Nagar",
  "R4 Pondy Bazaar",
  "R5 Virugambakkam",
  "R7 KK Nagar",
  "R9 Valasaravakkam",
  "S1 Mount",
  "S2 Airport",
  "S7 Madipakkam",
  "T4 Madhuravoyal",
  "V1 Villlivakkam",
  "V5 Thirumangalam",
];

export const FILTERS = {
  REGION_BOUNDARY: "REGION_BOUNDARY",
  BODY_CAMERA: "BODY_CAMERA",
  CHALAN_MACHINE: "CHALAN_MACHINE",
  SIGNAL_REMOTE: "SIGNAL_REMOTE",
  BREATH_ANALYSER: "BREATH_ANALYSER",
};

export const FILTERS_DESCRIPTION = {
  REGION_BOUNDARY: "Boundary",
  BODY_CAMERA: "Body Camera",
  CHALAN_MACHINE: "Chalan Machine",
  SIGNAL_REMOTE: "Signal Remote",
  BREATH_ANALYSER: "Breath Analyser",
};
