import { Road } from "../../constants/action-constants/roads/RoadActionConstants";

export const selectRoad = (roadId) => ({
    type: Road.SELECT_ROAD,
    payload: { roadId },
});

export const selectMultipleRoad = ({ roadIds }) => ({
    type: Road.SELECT_ROAD,
    payload: { roadIds },
});

export const unSelectRoad = (roadId) => ({
    type: Road.UN_SELECT_ROAD,
    payload: { roadId },
});

export const setGeoLocation = (locationStr) => ({
    type: Road.SET_GEO_LOCATION,
    payload: { locationStr },
});

