export const User = {
  //Get user infor by id
  GET_USER_INFO_BY_ID: "GET_USER_INFO_BY_ID",
  GET_USER_INFO_BY_ID_SUCCESS: "GET_USER_INFO_BY_ID_SUCCESS",
  GET_USER_INFO_BY_ID_FAILURE: "GET_USER_INFO_BY_ID_FAILURE",

  //Add User
  ADD_USER: "ADD_USER",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",

  //Edit User by id
  EDIT_USER_BY_ID: "EDIT_USER_BY_ID",
  EDIT_USER_BY_ID_SUCCESS: "EDIT_USER_BY_ID_SUCCESS",
  EDIT_USER_BY_ID_FAILURE: "EDIT_USER_BY_ID_FAILURE",
};
