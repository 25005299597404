//Action Constants
import { LiveCongestionList } from "../../constants/action-constants/live-congestion/LiveCongestionListActionConstants";

export const getLiveCongestionList = ({
  searchText,
  minDelayMins,
  division,
  subDivision,
  station,
  pageNumber,
  pageSize,
}) => ({
  type: LiveCongestionList.GET_LIVE_CONGESTION_LIST,
  payload: { searchText, minDelayMins, division, subDivision, station, pageNumber, pageSize },
});

export const enableFilter = ({ filterType }) => ({
  type: LiveCongestionList.ENABLE_FILTER,
  payload: { filterType },
});

export const disableFilter = ({ filterType }) => ({
  type: LiveCongestionList.DISABLE_FILTER,
  payload: { filterType },
});
