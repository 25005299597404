// Utils
import { sendGetRequest } from "../../utils/api/ApiUtils";

// URL's
import { getGeoNearRoadsUrl } from "../../urls/api-urls/RoadURL";

export async function getGeoNearRoadsApi(latitide, longitude, radiusInMts) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const params = {
    lat: latitide,
    lng: longitude,
    radius: radiusInMts,
  };

  return sendGetRequest(getGeoNearRoadsUrl, params, true, headerParams);
}
