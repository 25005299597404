export const getErrorMessage = (error) => {
    if (error.response) {
        if (error.response.data instanceof Object) {
            return error.response.data.message;
        }
        return error.response.data;
    } else if (error.request) {
        return error.request;
    } else {
        return error.message;
    }
};