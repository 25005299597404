//Constants
import { DEFAULT_PAGINATION } from "../../constants/PaginationConstants";

//Utils
import { sendGetRequest } from "../../utils/api/ApiUtils";

//Urls
import { getUserListUrl } from "../../urls/api-urls/UserURL";

export async function getUserListApi(searchText, pageNumber, pageSize) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const params = {
    pn: pageNumber || DEFAULT_PAGINATION.pageNumber,
    ps: pageSize || DEFAULT_PAGINATION.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  return sendGetRequest(getUserListUrl, params, true, headerParams);
}
