export const LiveCongestionList = {
  //Get live congestion list
  GET_LIVE_CONGESTION_LIST: "GET_LIVE_CONGESTION_LIST",
  GET_LIVE_CONGESTION_LIST_SUCCESS: "GET_LIVE_CONGESTION_LIST_SUCCESS",
  GET_LIVE_CONGESTION_LIST_FAILURE: "GET_LIVE_CONGESTION_LIST_FAILURE",

  // Enable/Disable Devices and region boundary
  ENABLE_FILTER: "ENABLE_FILTER",
  DISABLE_FILTER: "DISABLE_FILTER",
};
