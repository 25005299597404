export const GEO_JSON_BOUNDRIES_DATA = {
  type: "FeatureCollection",
  name: "GCP_Boundaries",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { DIVISION: "GCP Boundary" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [80.175339781, 13.024080306],
              [80.174878761000102, 13.024883374000099],
              [80.17480737699999, 13.0249517830001],
              [80.173971591000097, 13.0250618330001],
              [80.172798442, 13.024793029],
              [80.172826996000097, 13.025064287000101],
              [80.173048286, 13.02593517],
              [80.173366870439196, 13.0267873269819],
              [80.172197158048604, 13.0274418089148],
              [80.171053439266601, 13.0280693688958],
              [80.1702921978696, 13.028501048468501],
              [80.169508676236504, 13.0289438681592],
              [80.170288484496893, 13.0316713403842],
              [80.170614811000107, 13.032744949000101],
              [80.170477866503006, 13.0330081545449],
              [80.170529853720396, 13.033238383650399],
              [80.171242821272699, 13.0333915602729],
              [80.171038585776003, 13.0338000312665],
              [80.171123993347393, 13.0345018587008],
              [80.171658719011603, 13.0343830307754],
              [80.172373543250302, 13.0342094306032],
              [80.1724632560001, 13.035281931],
              [80.172549844000102, 13.036057471],
              [80.171692763017106, 13.0360885741883],
              [80.171706435673997, 13.0372152011105],
              [80.170814978449101, 13.0372589536123],
              [80.170771225947306, 13.0378605505125],
              [80.170158690921696, 13.037882426763399],
              [80.170065716855305, 13.0386641609172],
              [80.169789500044701, 13.0393063827195],
              [80.169130376395998, 13.038992602729],
              [80.1678826831793, 13.0386992462882],
              [80.166881000901995, 13.0385163626842],
              [80.166101192641605, 13.038386394640799],
              [80.165425358815895, 13.038286133578801],
              [80.164623270319495, 13.0382564265974],
              [80.1638731690405, 13.0382155794981],
              [80.163290169531507, 13.0381190318087],
              [80.162205864712305, 13.037902727850801],
              [80.161641432066702, 13.037809893534],
              [80.160612827837596, 13.037691529780201],
              [80.160146349601106, 13.037620694050201],
              [80.159727679503007, 13.037540032838599],
              [80.159282122334304, 13.0374248025364],
              [80.158727096378499, 13.0372437949366],
              [80.158294982745105, 13.037059426453],
              [80.157753400324594, 13.0367804730963],
              [80.157453801538793, 13.0365961046128],
              [80.157242545984602, 13.0364309411795],
              [80.157062018511098, 13.036219685625399],
              [80.156770101745494, 13.035815419315099],
              [80.156512754070405, 13.035485092448599],
              [80.156213155284604, 13.0350472173001],
              [80.156067196901802, 13.0348244387158],
              [80.155206810644998, 13.0352661548744],
              [80.154365629438701, 13.035692506992699],
              [80.153858616108806, 13.0359613776979],
              [80.152955978741204, 13.036257135473701],
              [80.151319708449407, 13.036272499514],
              [80.1494452955329, 13.036241771433399],
              [80.149176424827701, 13.036218725372899],
              [80.149237880988906, 13.0377877779885],
              [80.1493684753314, 13.0403631752436],
              [80.149468341593305, 13.0423720235127],
              [80.149502416813206, 13.042876326368701],
              [80.147478439000096, 13.042720661],
              [80.146124176028096, 13.0425857549574],
              [80.145856813196005, 13.043803741192701],
              [80.145811324380801, 13.044572177249201],
              [80.145754695447593, 13.0451823307959],
              [80.145710134975602, 13.0452918752896],
              [80.1456302974632, 13.0453457191933],
              [80.145418635221105, 13.0454459802553],
              [80.144911759851894, 13.0455228006524],
              [80.143929572781005, 13.0454722059498],
              [80.143004942986593, 13.0456058873659],
              [80.142583475188701, 13.045566896952799],
              [80.141994905620706, 13.0455673611244],
              [80.141098126121193, 13.045641628577799],
              [80.140680371696007, 13.045723322776499],
              [80.140749997433502, 13.045589641360399],
              [80.140146574374896, 13.0456462702936],
              [80.138513760000095, 13.0457116440001],
              [80.137759804092198, 13.045588713017301],
              [80.137002276067804, 13.045507018818601],
              [80.136526964366197, 13.0454216112472],
              [80.136460123658196, 13.0453213501851],
              [80.135327544994297, 13.0450047851651],
              [80.135266274345199, 13.044759702568999],
              [80.135193863578195, 13.0444292124015],
              [80.134835523115697, 13.044733708960299],
              [80.134490179457501, 13.0449379444571],
              [80.134360211414105, 13.0447188554697],
              [80.133353887420895, 13.0449639380658],
              [80.132291862837704, 13.045224802495699],
              [80.1307099660809, 13.0455849996446],
              [80.129822470013096, 13.045915489812099],
              [80.129296918000094, 13.046047148000101],
              [80.128791283000098, 13.046207761000099],
              [80.12888646099999, 13.046588475],
              [80.128809129, 13.0469929830001],
              [80.128880513000098, 13.047278519],
              [80.129285021, 13.048087535],
              [80.129136305, 13.048361173],
              [80.129421840000106, 13.048961987],
              [80.129588402000095, 13.049705569],
              [80.12980255399999, 13.050211204],
              [80.130104686335898, 13.051329587162799],
              [80.129881883975798, 13.0513704342622],
              [80.129477126354899, 13.0514335615976],
              [80.129232043758805, 13.0515041156783],
              [80.128882986728001, 13.051612731828801],
              [80.128623050641195, 13.051883808033599],
              [80.128288847101004, 13.052002635958999],
              [80.128014057523501, 13.052069476667],
              [80.126640109636199, 13.0520620499217],
              [80.126692096853503, 13.053274466097999],
              [80.127278809735202, 13.053709394871801],
              [80.127234249263196, 13.054202809265099],
              [80.128255334000102, 13.054200569],
              [80.128944188, 13.054718102],
              [80.129383198000099, 13.055364125],
              [80.130453956000096, 13.0554462170001],
              [80.131538990000095, 13.0554462170001],
              [80.132798915000095, 13.055467632],
              [80.134357462000096, 13.055443837],
              [80.135695908000102, 13.055568759],
              [80.137016509, 13.055593743],
              [80.137940930000099, 13.055650850000101],
              [80.138569108000098, 13.055725804000099],
              [80.139086640000102, 13.0562825970001],
              [80.139386452000096, 13.056443211],
              [80.139489959000102, 13.0583955590001],
              [80.139542307000099, 13.059240267000099],
              [80.143049503458499, 13.059028801219499],
              [80.143405987234701, 13.058883979685399],
              [80.144338043774496, 13.058839419213401],
              [80.144453158327295, 13.0591448441154],
              [80.144527425780595, 13.0595384616182],
              [80.144698240923404, 13.0598726651584],
              [80.144724234532006, 13.0600072749176],
              [80.144856059261798, 13.060642261644],
              [80.144987883991504, 13.061377509432299],
              [80.144995310736903, 13.061733993208501],
              [80.147189913983993, 13.062016209531301],
              [80.148125683896495, 13.062016209531301],
              [80.148764383995399, 13.062031063021999],
              [80.149432791075796, 13.062060770003299],
              [80.149781848106599, 13.0620459165127],
              [80.150257159808206, 13.062023636276701],
              [80.150680484292394, 13.061897381605901],
              [80.150962700615196, 13.061748846699199],
              [80.151452865807499, 13.0615186175937],
              [80.151631107695593, 13.0618639612519],
              [80.152035865316407, 13.062925985835101],
              [80.152232674067804, 13.063544262384401],
              [80.152258667676506, 13.064025144145001],
              [80.152202967086495, 13.0647678186787],
              [80.152212250518204, 13.0653591732762],
              [80.151972737981097, 13.0659867332572],
              [80.1519244641364, 13.06626894958],
              [80.151961597862993, 13.066673707200801],
              [80.152069285670393, 13.067145305529801],
              [80.152080425788398, 13.0673569677719],
              [80.151928177509006, 13.067455372147601],
              [80.151683094912897, 13.067564916641301],
              [80.151660814676902, 13.067737588470401],
              [80.151961597863107, 13.068665931637501],
              [80.152433196192007, 13.0700528763292],
              [80.152674565415396, 13.0709719360647],
              [80.154886975000096, 13.070557757000101],
              [80.155582968, 13.070480425],
              [80.156945209000099, 13.070486373000101],
              [80.157485239707597, 13.0705727485028],
              [80.157581787396893, 13.072538979330799],
              [80.159566707000096, 13.072382008],
              [80.160204402, 13.072562847],
              [80.161288899000098, 13.0723962320001],
              [80.161354106, 13.077656925],
              [80.16146832, 13.081816223],
              [80.161611087, 13.084947593],
              [80.162348829560202, 13.0849611392503],
              [80.162984744629696, 13.084996416290601],
              [80.162952252618794, 13.085356613439499],
              [80.162991243031897, 13.085399781396699],
              [80.163446131183704, 13.0854926157135],
              [80.164212942639793, 13.0856434714781],
              [80.164888776465503, 13.0857882930122],
              [80.165430928875097, 13.0857474459128],
              [80.165462492542801, 13.0860932537426],
              [80.166579402000096, 13.086127806],
              [80.168340203000099, 13.08575661],
              [80.16928247, 13.085318789],
              [80.170729182000102, 13.085471075],
              [80.172099752000094, 13.085623360000101],
              [80.172071198000097, 13.085975521],
              [80.174060427, 13.086261056000099],
              [80.175383408000101, 13.0863371990001],
              [80.17599255, 13.0864799660001],
              [80.175573765000095, 13.0878029470001],
              [80.175573765000095, 13.0880694460001],
              [80.17731553, 13.0879552320001],
              [80.177306012, 13.0881265530001],
              [80.179496863000097, 13.088119440000099],
              [80.180237508000104, 13.088117035],
              [80.183149286563093, 13.0879659539965],
              [80.183765706426001, 13.0879511005058],
              [80.184278151854301, 13.0879102534065],
              [80.185384736909498, 13.087869406307099],
              [80.185397733713899, 13.0889379292925],
              [80.185438580813198, 13.0897646188828],
              [80.185523988384602, 13.0903053787777],
              [80.186190538778604, 13.090234824696999],
              [80.186990770588693, 13.090156843870901],
              [80.187475365721895, 13.090128993575901],
              [80.188056508544605, 13.090115996771599],
              [80.188563383913802, 13.090112747570499],
              [80.188583807463502, 13.090507293416501],
              [80.188656218230506, 13.0914138205192],
              [80.189417459627606, 13.091374830106201],
              [80.189510293944295, 13.091895166451399],
              [80.189868634406807, 13.091838073346601],
              [80.1901007201986, 13.0931052617698],
              [80.190219548124006, 13.093100155882301],
              [80.190278962086694, 13.0935587574069],
              [80.19035497599999, 13.09383726],
              [80.190630994000102, 13.0937801530001],
              [80.191458886252093, 13.0937718121638],
              [80.191294105339907, 13.0952822264967],
              [80.192241015370399, 13.0952432360837],
              [80.193122941379201, 13.095176395375599],
              [80.193191294, 13.096559363],
              [80.193236199245604, 13.097834241863101],
              [80.193212062323198, 13.100287852853899],
              [80.193226915813895, 13.1017694885486],
              [80.193204635577899, 13.101888316474],
              [80.193189782087202, 13.1019830074771],
              [80.1935070480001, 13.102079356],
              [80.193770267667205, 13.102167954823599],
              [80.194032361637497, 13.102301913964],
              [80.194055658879293, 13.1029149226391],
              [80.194125550604795, 13.1037477990337],
              [80.194195442330198, 13.105131072766101],
              [80.194335225781003, 13.1057775712263],
              [80.194713805960404, 13.106774984391199],
              [80.195109859071195, 13.1075204961291],
              [80.195616574080503, 13.1083854062312],
              [80.196000978570297, 13.109061026243699],
              [80.196327139955599, 13.109684227461999],
              [80.196548463752805, 13.110281219283401],
              [80.196621267633503, 13.1106932892479],
              [80.196670774272306, 13.111149041540701],
              [80.196699895824594, 13.1115509189619],
              [80.196713439000106, 13.111821628],
              [80.196721700000097, 13.112009561],
              [80.19690016, 13.1135157590001],
              [80.197028651, 13.114700731],
              [80.197171418, 13.115899979000099],
              [80.197264217000097, 13.116799416],
              [80.197456954000103, 13.117834481],
              [80.198213622000097, 13.1193406800001],
              [80.198520573000096, 13.11986892],
              [80.198970291000094, 13.120661281],
              [80.199477116000097, 13.12181056],
              [80.199926834000095, 13.122395908],
              [80.200312307, 13.1228527640001],
              [80.200990453, 13.123323898],
              [80.2009619, 13.1237522010001],
              [80.201004730000093, 13.1245517],
              [80.200990453, 13.125358337],
              [80.201061837000097, 13.126279188000099],
              [80.201040422000105, 13.127142933000099],
              [80.201083252, 13.128484949],
              [80.20122602, 13.1297198890001],
              [80.201516596772294, 13.130968787088401],
              [80.200750905097706, 13.131087145981301],
              [80.200823315864696, 13.131451984846001],
              [80.199394595730496, 13.131805683592599],
              [80.197935240271704, 13.1320841865428],
              [80.1970273206543, 13.132240148194899],
              [80.196622098861894, 13.132318825278301],
              [80.196512786453894, 13.1323411055143],
              [80.196383282582104, 13.1323494606028],
              [80.196227320930006, 13.132320217793],
              [80.196007999856803, 13.1322826198948],
              [80.195687721464196, 13.1322826198948],
              [80.195391115822304, 13.1323661707798],
              [80.195116790416407, 13.1324984596811],
              [80.195023491928097, 13.1326780940839],
              [80.194983109000304, 13.1328034204115],
              [80.194909305718497, 13.132934316798099],
              [80.1947867644204, 13.1330401479191],
              [80.194601559958599, 13.1331195212599],
              [80.193906695097994, 13.1333604263118],
              [80.193417922420494, 13.1334495472558],
              [80.193348296682998, 13.133464516789401],
              [80.193286677905306, 13.133501766559],
              [80.193245250591403, 13.1335567708916],
              [80.193169010408894, 13.1338363182278],
              [80.193146730172899, 13.134155900363099],
              [80.193137678827, 13.134208119666299],
              [80.193123057422099, 13.1342457175646],
              [80.193100777186103, 13.134274960374301],
              [80.192898166289893, 13.1344149081068],
              [80.192721316916604, 13.134552767067101],
              [80.192685807790397, 13.1346307478931],
              [80.192666486648307, 13.134775395362899],
              [80.192653431822507, 13.1348615572131],
              [80.192641943575794, 13.1348840115134],
              [80.1926179226963, 13.134915343095299],
              [80.192569358744393, 13.1349524188005],
              [80.192386068990402, 13.135032314334399],
              [80.192072753171402, 13.1351404082919],
              [80.191726016998501, 13.135276178480099],
              [80.191563092772697, 13.1353189983087],
              [80.190928628239405, 13.1354265700732],
              [80.1907163567721, 13.135464167971399],
              [80.1906017354017, 13.1354939329742],
              [80.190503563111704, 13.135540408154],
              [80.190422362095305, 13.1357063349898],
              [80.190163354351697, 13.136332966627601],
              [80.190054738201198, 13.136671347711999],
              [80.190045338726605, 13.136750721052801],
              [80.1900505606569, 13.136929311069601],
              [80.190073537150298, 13.1371580316174],
              [80.190219751199095, 13.137532966214],
              [80.190476670170597, 13.138228527332],
              [80.190698080015906, 13.1386483705293],
              [80.190838027748399, 13.13892408845],
              [80.190867270558101, 13.138980485297401],
              [80.190889202665502, 13.13905672548],
              [80.190953954601397, 13.1395058114871],
              [80.191242205154694, 13.1410776125119],
              [80.189650560794703, 13.1404551584184],
              [80.189592075175099, 13.1406139051],
              [80.189013485296201, 13.140244192433601],
              [80.189310090938093, 13.142817559692901],
              [80.189967009771706, 13.142665079327699],
              [80.190603040884099, 13.1426410584483],
              [80.190999907587994, 13.142699544067799],
              [80.191703823794597, 13.1427924944274],
              [80.191703823794597, 13.142850980046999],
              [80.192334632976696, 13.142842624958501],
              [80.192799853000096, 13.1427953343805],
              [80.193391551672406, 13.1426755231884],
              [80.193782152059995, 13.142520954050999],
              [80.194055781208505, 13.142353852281],
              [80.194335676673404, 13.142132442435599],
              [80.1946239272268, 13.1418483694264],
              [80.195354475277796, 13.142246280516501],
              [80.196059435870296, 13.142760118459501],
              [80.196595205920602, 13.143237141793801],
              [80.197168573869206, 13.1438112930319],
              [80.196922269000098, 13.1452606440001],
              [80.196508243, 13.1458103000001],
              [80.196286953000097, 13.1462814330001],
              [80.196008556, 13.147152316000099],
              [80.195394655, 13.1476876950001],
              [80.195251887, 13.147837601],
              [80.194795031000098, 13.1488226980001],
              [80.193146064000103, 13.149436598],
              [80.192046753000099, 13.150307481],
              [80.190876059000104, 13.151421069],
              [80.190854643000094, 13.151563837],
              [80.190811813, 13.151949309],
              [80.190619077000093, 13.1523776120001],
              [80.190283573, 13.152941545],
              [80.188349071000104, 13.154590511],
              [80.187256898, 13.154975984],
              [80.185793530000097, 13.155575608],
              [80.184930457982702, 13.1557188607295],
              [80.180939429, 13.153305602],
              [80.179426092000099, 13.1526345940001],
              [80.177905617000107, 13.153327017],
              [80.177248885, 13.154097963],
              [80.176777752000106, 13.154304976],
              [80.176113882, 13.154055132000099],
              [80.175055022, 13.1531033480001],
              [80.172984891000098, 13.1504026600001],
              [80.17104563, 13.1479994040001],
              [80.170081949000107, 13.1474997170001],
              [80.168820834, 13.147606793],
              [80.167012444000093, 13.148510988000099],
              [80.165596665000095, 13.149855384],
              [80.164561599000095, 13.151116498],
              [80.163597918000093, 13.1528178130001],
              [80.163419458000106, 13.153698213],
              [80.163383766, 13.154768971000101],
              [80.1634313550001, 13.156946177000099],
              [80.163823966000095, 13.158492827],
              [80.164859032, 13.160027579],
              [80.16650086, 13.1613481800001],
              [80.167312098000096, 13.161878641],
              [80.169510720000105, 13.161688285],
              [80.171842591, 13.163944014],
              [80.172356555000107, 13.1645531560001],
              [80.174250606, 13.16722767],
              [80.17406024899999, 13.168807632],
              [80.173831821000107, 13.16992122],
              [80.173517732000093, 13.1713679320001],
              [80.177743655, 13.171929485],
              [80.182550166000098, 13.1725862160001],
              [80.184939145, 13.172881269000101],
              [80.187118731000098, 13.173005001],
              [80.188165694000105, 13.1711870930001],
              [80.188279908000098, 13.169702309000099],
              [80.18813714, 13.16876956],
              [80.188679657000094, 13.168712453000101],
              [80.188365569, 13.1677511510001],
              [80.188365569, 13.166865992000099],
              [80.189355424, 13.165466869000101],
              [80.190231066000095, 13.164952905000099],
              [80.190706958000106, 13.164743512],
              [80.191278029, 13.1646197810001],
              [80.192191742, 13.1645055660001],
              [80.192924616000099, 13.164372317000099],
              [80.193809775, 13.164115335000099],
              [80.194323739000097, 13.1640962990001],
              [80.194837702, 13.164115335000099],
              [80.195142273, 13.1641819600001],
              [80.195456362000101, 13.164952905000099],
              [80.195618166, 13.1655715650001],
              [80.19573238, 13.166228296],
              [80.195713344000097, 13.167065866],
              [80.195208898, 13.1672847770001],
              [80.195113720000094, 13.1674275440001],
              [80.195037577000093, 13.167732115],
              [80.195104202, 13.1681223470001],
              [80.1952374520001, 13.1684174000001],
              [80.195456362000101, 13.168569686000099],
              [80.195841130172596, 13.1688070053339],
              [80.195985951706703, 13.169074368165999],
              [80.196170201, 13.169283524],
              [80.196373999150595, 13.169347301057099],
              [80.196322486, 13.170882522000101],
              [80.196381425896007, 13.171762849978],
              [80.196529960802707, 13.171996792456101],
              [80.196626508492102, 13.173056960353],
              [80.198017166556497, 13.1728805751513],
              [80.197733093547399, 13.1733373199895],
              [80.198626159674106, 13.1736306764303],
              [80.199710464493293, 13.1739723067158],
              [80.200208056430895, 13.174113414877199],
              [80.200876463511307, 13.174039147423899],
              [80.201006431554703, 13.1740280073058],
              [80.201205140000098, 13.1748704990001],
              [80.201667411889701, 13.1764936867578],
              [80.201827086914406, 13.1763191582423],
              [80.202207707612999, 13.1759738145842],
              [80.202610608547502, 13.175639611044],
              [80.203299439177599, 13.175205146441799],
              [80.203485107811005, 13.1751104554387],
              [80.204305763170794, 13.174905291598799],
              [80.2050307991843, 13.1747168379359],
              [80.2057047763236, 13.174286086706299],
              [80.206139240925907, 13.1740929913275],
              [80.206518004938104, 13.1740372907375],
              [80.206811150000107, 13.174032928],
              [80.207550322539902, 13.174096704700199],
              [80.208216872933903, 13.1741654020946],
              [80.208974400958297, 13.174249881322799],
              [80.209264044026497, 13.174269376529301],
              [80.209503556563604, 13.1742470962933],
              [80.210249944469993, 13.1740716394347],
              [80.211692589751706, 13.1736260347145],
              [80.213185365564399, 13.173057888696199],
              [80.214101819000106, 13.1726623590001],
              [80.214596747, 13.1725766980001],
              [80.215125834869497, 13.1725325625065],
              [80.215668915622302, 13.172557627771999],
              [80.216131230519494, 13.172649533745499],
              [80.216380490659901, 13.1727038418208],
              [80.2166269657708, 13.172710804394599],
              [80.217114345933496, 13.172529777476999],
              [80.217518175211197, 13.172309760146399],
              [80.217713127276298, 13.1719811266652],
              [80.217944284724894, 13.171128907637801],
              [80.218055685905, 13.170181997607299],
              [80.218174049658799, 13.1699466626144],
              [80.218359254120699, 13.1698171587426],
              [80.218722700470593, 13.1697182901953],
              [80.218959427978206, 13.1696263842218],
              [80.219134884836805, 13.169506627953201],
              [80.219269958767597, 13.1693645914486],
              [80.219409210242702, 13.1692044522523],
              [80.219629227573293, 13.1691027986755],
              [80.219843674844896, 13.1690916585575],
              [80.220214083768596, 13.169180779501501],
              [80.220545399000102, 13.169321595],
              [80.220859488, 13.169492917000101],
              [80.221417216513203, 13.1699661578209],
              [80.221993717619995, 13.169141789088499],
              [80.222244370275106, 13.168740744840299],
              [80.222522873225202, 13.168016637169901],
              [80.223285971308599, 13.1662899188791],
              [80.223762211353403, 13.1656075866512],
              [80.224028181670704, 13.1652511028751],
              [80.224062994539494, 13.165196794799799],
              [80.224097807408299, 13.165136916665499],
              [80.2241312277623, 13.165019945426399],
              [80.224384752478798, 13.163793792672299],
              [80.224720783694494, 13.163749145168101],
              [80.2249549872692, 13.1637068475326],
              [80.225142976760495, 13.163677865819301],
              [80.225378746914203, 13.163666116476101],
              [80.226528615969599, 13.1635752548887],
              [80.227302506042307, 13.1634906596175],
              [80.227879007149099, 13.1633817823705],
              [80.227938537154699, 13.163519249686001],
              [80.228588667478903, 13.163262330714501],
              [80.229306160704198, 13.1627766911952],
              [80.230969867702598, 13.161363636851901],
              [80.231970911744, 13.1605552820392],
              [80.232415820206896, 13.1602356999039],
              [80.233828874550198, 13.159189225068699],
              [80.235254461526196, 13.1581145518099],
              [80.236777176406093, 13.157039878551],
              [80.237707724388201, 13.1563474505912],
              [80.239012684773996, 13.155247712066799],
              [80.241205895506397, 13.153549540328401],
              [80.241249759721001, 13.153500976376399],
              [80.241277174855199, 13.1534461461081],
              [80.241276391565606, 13.1533913158398],
              [80.240953676272099, 13.1511918387911],
              [80.241486313164302, 13.151085311412601],
              [80.241563075539901, 13.1510653375292],
              [80.2416253470589, 13.1510359641712],
              [80.241667253049698, 13.1510007161415],
              [80.241698584631607, 13.150943536004601],
              [80.241758114637193, 13.150598888603801],
              [80.241593819654597, 13.1504514343465],
              [80.2414998249089, 13.1503362907831],
              [80.241189642248202, 13.1495796330804],
              [80.241053349866903, 13.1490391632928],
              [80.240860660638305, 13.1480663176751],
              [80.239782070931795, 13.1456776762007],
              [80.240182282934697, 13.145617066112001],
              [80.240566927057998, 13.1456021958495],
              [80.240770153978801, 13.1455952563936],
              [80.241017991687102, 13.1455774120786],
              [80.241353068268694, 13.145534783992799],
              [80.241676248640402, 13.145494138608701],
              [80.241868570701996, 13.145462415381999],
              [80.242496095779501, 13.1452919030387],
              [80.242669582175395, 13.145254231707],
              [80.242799449134594, 13.145260179812],
              [80.242886192332506, 13.145296364117399],
              [80.242970457153305, 13.145366254351201],
              [80.243053234947894, 13.1454520061982],
              [80.243101811138601, 13.1455377580453],
              [80.243189050011907, 13.1458361546461],
              [80.243242582956896, 13.146222781471099],
              [80.243298098603603, 13.146619321804399],
              [80.243342213715806, 13.147039158882301],
              [80.243380380722797, 13.147501624046001],
              [80.244124885198602, 13.147400506261],
              [80.244786116204395, 13.147302362528499],
              [80.244910035058595, 13.147880320064299],
              [80.245967633000106, 13.147662498],
              [80.246457802000094, 13.147555422],
              [80.247047908000098, 13.1474531050001],
              [80.247513818022199, 13.1473702700606],
              [80.247479120742994, 13.1471055793881],
              [80.247418648342205, 13.1468012346823],
              [80.247240205192199, 13.1458852265124],
              [80.247912341057202, 13.1457940222357],
              [80.247789413553804, 13.1449989588675],
              [80.248988948062106, 13.1447848270875],
              [80.249137650687103, 13.145391533797399],
              [80.249520168000103, 13.145318728000101],
              [80.249932714055404, 13.1452175517262],
              [80.250019952928696, 13.1454832337495],
              [80.250137923677897, 13.145703313634501],
              [80.2502301193054, 13.1458103795245],
              [80.250518602397804, 13.1455833601837],
              [80.2508943243636, 13.1453543581412],
              [80.251088629126897, 13.145267119267899],
              [80.251292867000103, 13.1452140320001],
              [80.251752834185197, 13.1450807453112],
              [80.251798188485907, 13.145059431268299],
              [80.251836851168406, 13.1450282037171],
              [80.251859652237499, 13.144982353741],
              [80.251864113316302, 13.144948399975],
              [80.251862130614597, 13.144906515402299],
              [80.251851225755402, 13.1448051497796],
              [80.251852960619402, 13.1447927578942],
              [80.251858165211203, 13.1447807377653],
              [80.251867954800701, 13.1447700807438],
              [80.251880594000099, 13.144761935000099],
              [80.252342192255597, 13.1445072488542],
              [80.252349131711398, 13.1443605289309],
              [80.252357062518101, 13.144321866248401],
              [80.252380854938096, 13.1442871689692],
              [80.252422491673101, 13.144258419794999],
              [80.252532035940206, 13.144219261437099],
              [80.252702548283494, 13.1441627544396],
              [80.253042581619297, 13.1440626280055],
              [80.253524378124297, 13.1439436659055],
              [80.2539427281759, 13.143900046468801],
              [80.254358104175097, 13.1438663405405],
              [80.255061139403395, 13.1437776315967],
              [80.255452555494998, 13.1437255687222],
              [80.255569534893297, 13.1436938454955],
              [80.255645868907493, 13.1436581568655],
              [80.2557271596758, 13.1435937190613],
              [80.2559680579282, 13.143508462889701],
              [80.256229774548203, 13.1434807050664],
              [80.256655064055707, 13.1434514602168],
              [80.257731671060597, 13.143342411625101],
              [80.258140107604007, 13.143295818136],
              [80.258166874076494, 13.143406849429301],
              [80.258177778935604, 13.143547621247601],
              [80.258174804883097, 13.1436735228034],
              [80.258189675145601, 13.1438192513759],
              [80.258216441618103, 13.1441156652751],
              [80.258244199441407, 13.1444150532267],
              [80.258264026458093, 13.14487107461],
              [80.258311611298097, 13.1452715803466],
              [80.258379023154802, 13.145846563829901],
              [80.258841983993904, 13.1458039357441],
              [80.2604360761338, 13.1456671293291],
              [80.261724832216998, 13.1455759250524],
              [80.261895344560401, 13.1454133435158],
              [80.262375158363696, 13.145377654885801],
              [80.264040627763507, 13.145306277625799],
              [80.266820375500004, 13.1452150733491],
              [80.268914108459796, 13.145119903669199],
              [80.270097747000094, 13.144169449000101],
              [80.271435004000097, 13.143067758],
              [80.272693739, 13.142013657000099],
              [80.273907264000101, 13.140990489],
              [80.273864434000103, 13.1401529190001],
              [80.273804947, 13.138727621],
              [80.273724046, 13.137311842],
              [80.273990069, 13.135696664],
              [80.2741566320001, 13.134687773000101],
              [80.274389819000106, 13.133269614],
              [80.274618247, 13.1318133840001],
              [80.274889971282605, 13.1305430810171],
              [80.275444663, 13.132346038],
              [80.276366704000097, 13.135570208],
              [80.27723520799999, 13.1384434070001],
              [80.277681356000102, 13.139781854],
              [80.278669132000104, 13.141757405],
              [80.279337992000094, 13.1431814920001],
              [80.279787179, 13.144148165],
              [80.2807149060001, 13.146172296],
              [80.280965013000099, 13.146717983],
              [80.282719865, 13.150305020000101],
              [80.284540153000094, 13.1542013870001],
              [80.286086802000099, 13.157407711000101],
              [80.287410732306597, 13.1602736925062],
              [80.288419927454797, 13.1596075047462],
              [80.289167405983093, 13.1590345039646],
              [80.290063587136302, 13.158306852453],
              [80.290627665760695, 13.157784410563901],
              [80.291595224173903, 13.1594498799638],
              [80.293149662280399, 13.1584347367105],
              [80.293513983000096, 13.160524716000101],
              [80.293930388, 13.162826844000101],
              [80.293994039, 13.1630481340001],
              [80.293911019720397, 13.163922854923401],
              [80.293922915930395, 13.16431146445],
              [80.293903088913694, 13.16477541664],
              [80.294910301360304, 13.164747658816699],
              [80.296559909146794, 13.16473972801],
              [80.297602810223395, 13.164505769213401],
              [80.2984712335533, 13.16439473792],
              [80.299021433265807, 13.1654188033308],
              [80.299840375, 13.1669920910001],
              [80.300654150000099, 13.168826655],
              [80.301227188869703, 13.1701406073495],
              [80.302087681392905, 13.1719706409877],
              [80.302540728723699, 13.1729391907518],
              [80.303395273141803, 13.1748296967908],
              [80.303984135536794, 13.176120435575699],
              [80.304717735153403, 13.1777482336439],
              [80.305519737977704, 13.179512838127099],
              [80.307145553344199, 13.1791519864238],
              [80.307988201552504, 13.178824840648801],
              [80.309671515267397, 13.1783678279147],
              [80.3120111032338, 13.1777016401547],
              [80.3125841040154, 13.1775445110476],
              [80.312635654258798, 13.1775950699401],
              [80.312742720148705, 13.1777308850043],
              [80.313819327153595, 13.1773799468093],
              [80.3143814230761, 13.1775831737302],
              [80.314133585367799, 13.1772956819885],
              [80.313594290514501, 13.176609667211901],
              [80.313336539297794, 13.1761258880053],
              [80.313150165341199, 13.175747191987],
              [80.3130331859428, 13.1755290948037],
              [80.312898362229504, 13.1750770388237],
              [80.312895388176997, 13.174775668170399],
              [80.312889440071999, 13.1746646368771],
              [80.3128378898287, 13.1742998197704],
              [80.312822028215393, 13.1741887884771],
              [80.312813106057902, 13.173612813643],
              [80.313523971000095, 13.173371624],
              [80.314877098492701, 13.1729069718497],
              [80.314900890912696, 13.172831629186399],
              [80.3148959341585, 13.172771652461],
              [80.314831000679007, 13.172749347067199],
              [80.313376920000096, 13.173283108],
              [80.312761555814603, 13.1735077304547],
              [80.312583112664598, 13.172986279916399],
              [80.312458202459595, 13.1726749957548],
              [80.312055714021398, 13.171721316253199],
              [80.3119198989572, 13.1716489476423],
              [80.311710723931398, 13.1709986214957],
              [80.311556073201501, 13.170427603415799],
              [80.311495600800598, 13.170152503559599],
              [80.311492626748105, 13.1697733118658],
              [80.312230496, 13.1695211800001],
              [80.312961313007506, 13.169285567255899],
              [80.3128413595567, 13.169142812735901],
              [80.31210486, 13.1693784130001],
              [80.311539715912701, 13.169566119541701],
              [80.311450494337706, 13.169397589900001],
              [80.311430667321005, 13.16925483538],
              [80.311525837001, 13.1691775100151],
              [80.311369203569399, 13.168846398836701],
              [80.311180846911, 13.168606491935099],
              [80.310919130291097, 13.1679264252635],
              [80.310775384420296, 13.1672423931886],
              [80.310864110319898, 13.1670723765207],
              [80.311004882138207, 13.1670218176282],
              [80.310977124314903, 13.166982163594801],
              [80.312776921752601, 13.1662450942503],
              [80.313144217236399, 13.1660820170382],
              [80.313216585847201, 13.166044345706601],
              [80.313254752854306, 13.1660121268045],
              [80.313295398238395, 13.1659610722366],
              [80.313322164710897, 13.165898617134101],
              [80.313328112815995, 13.1658113782608],
              [80.313232943136001, 13.1643759022542],
              [80.3132260036801, 13.1643590492901],
              [80.313217577198003, 13.1643476487555],
              [80.313200724233894, 13.1643431876767],
              [80.313191120522703, 13.1643448605813],
              [80.313178418840096, 13.1643516141588],
              [80.3131703021552, 13.164371317256601],
              [80.313268445887701, 13.1658236462274],
              [80.313267454536899, 13.1658583435065],
              [80.313247627520198, 13.165908902399],
              [80.313226809152695, 13.1659426083274],
              [80.313183189716099, 13.1659882104657],
              [80.312106582711095, 13.1664472059015],
              [80.310927866570296, 13.1669299937573],
              [80.310880281730306, 13.1668754694615],
              [80.310680028861995, 13.166942881318199],
              [80.310367753349496, 13.166381776746499],
              [80.310284479879499, 13.166189454684901],
              [80.310030694066199, 13.165901962943201],
              [80.3098225103912, 13.1653666334932],
              [80.309644067241194, 13.1649542315466],
              [80.309544932157905, 13.164710359241599],
              [80.309402177637907, 13.1642047703166],
              [80.309433900864505, 13.164083825515],
              [80.309562776472902, 13.1640144309566],
              [80.309614326716201, 13.1639509845033],
              [80.309477520301201, 13.163828057],
              [80.309368471709504, 13.163580219291701],
              [80.309289163642802, 13.1634731534017],
              [80.309192011261203, 13.163346260495],
              [80.308975896779501, 13.163169800046701],
              [80.308835124961107, 13.1628773515509],
              [80.308480221362899, 13.162271388354],
              [80.3083172340001, 13.161803161],
              [80.308140202000104, 13.161421971],
              [80.308051686000098, 13.161253505],
              [80.307987441000094, 13.1609893850001],
              [80.307877510000097, 13.1605782140001],
              [80.307764723, 13.160221295],
              [80.307667641000094, 13.1599514640001],
              [80.30759911299999, 13.159731602],
              [80.307526301000095, 13.1595331550001],
              [80.307499175, 13.1594189410001],
              [80.307436358000103, 13.15914911],
              [80.307396383000096, 13.158997776],
              [80.307343559000103, 13.158900694000099],
              [80.307103709, 13.15852807],
              [80.306869570000103, 13.158196849],
              [80.306716809000093, 13.157972704],
              [80.306645425000099, 13.157845641000099],
              [80.306546915000098, 13.157605791],
              [80.306461255, 13.157318828],
              [80.306361317, 13.157041859000101],
              [80.306227116000102, 13.156616411000099],
              [80.306108618000096, 13.156212379000101],
              [80.306021530000095, 13.155938265],
              [80.305951574000105, 13.155611327],
              [80.305864486000104, 13.155170175],
              [80.305819435726306, 13.154790654966099],
              [80.305815470322997, 13.1543643741078],
              [80.305837280041303, 13.1541403288194],
              [80.306392436508006, 13.1539628770203],
              [80.3071260361246, 13.153728918223599],
              [80.3071518112463, 13.153625817737],
              [80.307109926673604, 13.1535435356178],
              [80.305433304576795, 13.1541428071965],
              [80.305237760624905, 13.1541388417932],
              [80.304960925904695, 13.1538359841136],
              [80.304743760000093, 13.15328707],
              [80.304523897000095, 13.1527602570001],
              [80.304376028913097, 13.152471885367],
              [80.304481112101399, 13.1521169817687],
              [80.304473181294796, 13.1518949191821],
              [80.304195603061402, 13.1515340674787],
              [80.304003280999794, 13.1512921778754],
              [80.303857172000093, 13.1509242650001],
              [80.303735616274807, 13.1505228896288],
              [80.303599801210694, 13.150066868245499],
              [80.303543294213199, 13.149847779711299],
              [80.303523467196499, 13.1495364955497],
              [80.304101176894704, 13.1493407037601],
              [80.304870465141306, 13.149084935245099],
              [80.305786473311201, 13.148765720276799],
              [80.305804317626198, 13.1486725332985],
              [80.305738888471197, 13.148607104143499],
              [80.303156667388194, 13.149524599339699],
              [80.303063480409904, 13.1493818448197],
              [80.3029477420001, 13.1490425870001],
              [80.302807830000106, 13.148582875],
              [80.302656496000097, 13.148108887],
              [80.302520867000098, 13.147740546],
              [80.302396659000095, 13.147472143000099],
              [80.3023124260001, 13.147282262],
              [80.302085426000104, 13.146945330000101],
              [80.301844148, 13.1465898390001],
              [80.30157289, 13.146261473],
              [80.301275933000099, 13.145907409000101],
              [80.301174568000107, 13.1456318680001],
              [80.301048933000104, 13.145296364],
              [80.300817822934803, 13.145018414127],
              [80.3008554942665, 13.1448954866237],
              [80.301386858313094, 13.1446952337553],
              [80.303040431503007, 13.144174774567899],
              [80.303392361048907, 13.1440478816612],
              [80.303433997783799, 13.143946763876199],
              [80.303390378347203, 13.143865473107899],
              [80.303288269211293, 13.143863490406201],
              [80.302221575714697, 13.144251108582001],
              [80.301404702628105, 13.1445465311304],
              [80.300780151603107, 13.144752732103701],
              [80.300672094362298, 13.144702173211201],
              [80.300551149560604, 13.144453344152],
              [80.300598734400594, 13.144214428601201],
              [80.300568498200207, 13.1441093454129],
              [80.300526403, 13.143981474],
              [80.30047072399999, 13.143804442],
              [80.300282989160195, 13.1434010252425],
              [80.300102563308599, 13.1432691755817],
              [80.300050516000098, 13.1431779600001],
              [80.2999907011109, 13.143029902654],
              [80.299858198048, 13.1426857829779],
              [80.299805779253902, 13.1425479409638],
              [80.299588338330196, 13.142335838991301],
              [80.299528153788799, 13.142083452204901],
              [80.299429625870204, 13.141669440803399],
              [80.2993432319318, 13.141313187147199],
              [80.299337650300998, 13.141021971624401],
              [80.299466755849394, 13.140968096752699],
              [80.300157907356805, 13.140739007208101],
              [80.301570302642304, 13.1402594723139],
              [80.301614955689104, 13.1402167607039],
              [80.301599909553801, 13.140117140727201],
              [80.301559624739795, 13.140096270281401],
              [80.300615601086804, 13.1404110257256],
              [80.299346872125795, 13.140832802874399],
              [80.299221649451098, 13.1403799627365],
              [80.299126519046993, 13.140045550244499],
              [80.299034481, 13.139851205000101],
              [80.298892575910301, 13.139492726110401],
              [80.298838889000095, 13.1393215370001],
              [80.298743235000103, 13.1389546240001],
              [80.298612038290102, 13.138590928708201],
              [80.298451869752498, 13.1381036280667],
              [80.298364901000099, 13.137809628000101],
              [80.298234982000096, 13.137247123000099],
              [80.298330636000102, 13.136844518],
              [80.298414011734593, 13.136566980824901],
              [80.298428087151393, 13.136413849995799],
              [80.298408672783296, 13.1362670288364],
              [80.2983720390001, 13.136102126],
              [80.298367902610096, 13.1357901634179],
              [80.298353341834002, 13.135489726070199],
              [80.298326161718506, 13.134903412151001],
              [80.298326161718506, 13.134859729822599],
              [80.298332956747402, 13.134774306602599],
              [80.298332956747402, 13.134711209905999],
              [80.298338053018995, 13.134657092354701],
              [80.298350624000093, 13.1346030660001],
              [80.298394882000096, 13.134436028],
              [80.298445802762402, 13.1343673329095],
              [80.298528313827205, 13.134284579165101],
              [80.298594322679094, 13.134261281923299],
              [80.2986205320761, 13.134260311204899],
              [80.2988117636027, 13.134197214508299],
              [80.298969019984995, 13.134108879133001],
              [80.301098776174996, 13.133496841175999],
              [80.301656939260297, 13.133294931746899],
              [80.301767601159, 13.133222127866199],
              [80.301772454751003, 13.133210479245299],
              [80.301843317194894, 13.1331755333825],
              [80.301985042082606, 13.1330406035236],
              [80.302039402313596, 13.1329546949444],
              [80.302070465302606, 13.1328624766955],
              [80.302816563000107, 13.131481158],
              [80.302908195289803, 13.1313326244825],
              [80.303043563000102, 13.1310414330001],
              [80.303263478227606, 13.1306074978308],
              [80.303323662769003, 13.130456065759001],
              [80.303425588202003, 13.1301556284113],
              [80.303337252826694, 13.1301129168013],
              [80.303312984866494, 13.130131360451101],
              [80.303037300838298, 13.130608225869601],
              [80.302554853788905, 13.1314639141474],
              [80.302512142178898, 13.131507596475799],
              [80.302461664821607, 13.1315444837754],
              [80.302406333872298, 13.1315881661038],
              [80.302372358727993, 13.1316405848979],
              [80.302349061486098, 13.131723338642299],
              [80.302317998497003, 13.1318252640752],
              [80.302198600132698, 13.131987374049601],
              [80.302128708407295, 13.132080563016901],
              [80.302052992371301, 13.132397017218301],
              [80.301758864693298, 13.1328736399572],
              [80.301662763570803, 13.132985272574301],
              [80.301580252506, 13.133048369270901],
              [80.301483180665102, 13.133099817346601],
              [80.301072566778004, 13.1332201864293],
              [80.300381415270607, 13.1334381127122],
              [80.299468939965905, 13.133746801166399],
              [80.299439333054394, 13.133731755031],
              [80.2992922692154, 13.133253676214499],
              [80.299209758150596, 13.133006143020101],
              [80.299170929414203, 13.132931883061801],
              [80.299798013506603, 13.1327484172824],
              [80.300219305296196, 13.132625621403699],
              [80.300225129606702, 13.132613972782799],
              [80.300259104751007, 13.132605721676301],
              [80.300248426848498, 13.132560097911],
              [80.299425742996604, 13.1327906435332],
              [80.298876316377005, 13.1329362512946],
              [80.298829721893398, 13.1329343098578],
              [80.298365718493699, 13.133044971756499],
              [80.298183223432801, 13.133068268998301],
              [80.298036887632605, 13.133073365269899],
              [80.2979691800235, 13.133055649658999],
              [80.297894920065204, 13.1330112392918],
              [80.297793965350706, 13.132864418132399],
              [80.297549344311506, 13.1319769388267],
              [80.297467803965105, 13.131322674618801],
              [80.297448389596994, 13.1311571671301],
              [80.297436740976096, 13.130846537239099],
              [80.297455669984998, 13.130944094439201],
              [80.297563905087699, 13.131582341793299],
              [80.297624089628997, 13.131605639035101],
              [80.298937471636805, 13.131427026847801],
              [80.298950090976106, 13.131417319663701],
              [80.298956886005001, 13.131403729605999],
              [80.298956886005001, 13.1313881981115],
              [80.298947178820896, 13.131377520209],
              [80.298933588763106, 13.1313697544617],
              [80.297625545706694, 13.1315486093286],
              [80.297612683687703, 13.131541086260899],
              [80.297454456587005, 13.1305905102586],
              [80.297464163771096, 13.1305109113491],
              [80.2974816367025, 13.1302716292612],
              [80.297525319030896, 13.1297916090078],
              [80.297553469864795, 13.1297168636903],
              [80.2976320980559, 13.129606201791701],
              [80.298063097029598, 13.129432200516799],
              [80.298095130737096, 13.129475882845201],
              [80.298168419977003, 13.1294547697198],
              [80.298190018461597, 13.1294130288282],
              [80.298181039316404, 13.1292925384057],
              [80.2982451067314, 13.1292619607758],
              [80.298312086301607, 13.1294381461671],
              [80.298345090727494, 13.129516288999],
              [80.298350915038, 13.129554147017],
              [80.298363049018107, 13.1295667663563],
              [80.298402363113695, 13.129591034316499],
              [80.298917814588904, 13.129435234011799],
              [80.299694146636796, 13.129219734525],
              [80.299702640422893, 13.129215366292099],
              [80.299710163490502, 13.129208813942901],
              [80.299714046364201, 13.129201048195601],
              [80.299715017082605, 13.129189399574701],
              [80.299711862247804, 13.1291753241577],
              [80.299706280616903, 13.129168043769701],
              [80.299699970947202, 13.129164160896],
              [80.299681527297494, 13.129161491420399],
              [80.298405032589301, 13.1295264815423],
              [80.298405517948495, 13.129509979329301],
              [80.298294856049907, 13.129234295301099],
              [80.298315241136507, 13.129224588116999],
              [80.298279324555295, 13.129102034917899],
              [80.298285148865801, 13.129085532704901],
              [80.299418947967794, 13.1287855807164],
              [80.300018366585505, 13.128623956101301],
              [80.3000251616144, 13.1286157049948],
              [80.300030985924806, 13.128601629577901],
              [80.300029044487999, 13.128589495597801],
              [80.300019822663103, 13.1285768762584],
              [80.300005747246203, 13.128570566588801],
              [80.299327700437303, 13.1287501494945],
              [80.2986307246194, 13.1289326445554],
              [80.298294856049793, 13.1290141849018],
              [80.298263793060698, 13.129031657833201],
              [80.298127892483393, 13.1285346500076],
              [80.299841695834999, 13.128034730026901],
              [80.299849461582298, 13.1280216253283],
              [80.299849461582298, 13.128009734027801],
              [80.299846549427002, 13.127997600047699],
              [80.2998378129614, 13.127986922145199],
              [80.299823737544401, 13.1279830392716],
              [80.298110904911297, 13.1284771349419],
              [80.2976524831425, 13.1267917251038],
              [80.297549586991096, 13.1263917891192],
              [80.297511728973106, 13.126185754136801],
              [80.298193173296397, 13.1259882129405],
              [80.298200453684501, 13.125982388630099],
              [80.298205307276504, 13.1259753509216],
              [80.298207734072605, 13.1259586060291],
              [80.298202637800898, 13.125946229369299],
              [80.298194386694405, 13.1259372502241],
              [80.298176913763101, 13.12593361003],
              [80.297499109633804, 13.126129937828299],
              [80.297424364316299, 13.125671758739101],
              [80.298180311277505, 13.125447522786599],
              [80.298201667082495, 13.1254395143597],
              [80.298210403548197, 13.125428593777601],
              [80.298213315703407, 13.1254171878363],
              [80.298212344985004, 13.1254055392154],
              [80.298207006033806, 13.125394133274099],
              [80.298197784208895, 13.1253870955656],
              [80.298174486967099, 13.1253870955656],
              [80.297415627850597, 13.125616913149001],
              [80.297274873681303, 13.125151939030999],
              [80.297263225060405, 13.125139562371199],
              [80.298451202383603, 13.1247847647927],
              [80.298463518373396, 13.124778212443401],
              [80.2984707987614, 13.124767837890399],
              [80.298472376178907, 13.1247577666869],
              [80.298470920101295, 13.1247486662018],
              [80.298467765266395, 13.1247404150953],
              [80.298461091577394, 13.124733741406301],
              [80.2984504136749, 13.124730222552],
              [80.297218572013506, 13.1250901164023],
              [80.297204617936401, 13.1250699739953],
              [80.297147830909395, 13.1250330866957],
              [80.2970454201172, 13.1250214380748],
              [80.296790121175604, 13.1250175552012],
              [80.2967248403626, 13.1249508183105],
              [80.296722898925793, 13.1247496369202],
              [80.296736488983498, 13.124649410244499],
              [80.296744740090006, 13.124624171565801],
              [80.296787451699998, 13.1245152084244],
              [80.296862962, 13.1243240910001],
              [80.296893745365793, 13.1242681605892],
              [80.2969466495191, 13.1242113735623],
              [80.2970524578257, 13.1240907617999],
              [80.297096140154196, 13.1240373722874],
              [80.297189329121395, 13.1238721074783],
              [80.297221362828907, 13.123818717965801],
              [80.297265045157303, 13.1237430019298],
              [80.297318434669805, 13.1236932526114],
              [80.2975368463119, 13.123520464734501],
              [80.297787291661507, 13.123388204351301],
              [80.2980823900579, 13.123252303774001],
              [80.298314391757799, 13.123147466185801],
              [80.298492033226594, 13.1230639844026],
              [80.298638611706394, 13.1230183606373],
              [80.299239486401703, 13.1229960341139],
              [80.299779205837297, 13.1228931379625],
              [80.3000937186019, 13.1230756330235],
              [80.300600433611507, 13.1233726728567],
              [80.300678091084194, 13.1234323720389],
              [80.300962754257796, 13.1238221154802],
              [80.301250814945703, 13.1242395243962],
              [80.301214655684902, 13.124318880626101],
              [80.301254940498893, 13.1243793078471],
              [80.301348857505005, 13.124374454254999],
              [80.301610223436697, 13.1248880856333],
              [80.3018228107684, 13.1252438539303],
              [80.302209642054507, 13.1258398750336],
              [80.302190227686296, 13.1259447126218],
              [80.302243131839603, 13.1259908217462],
              [80.302317877157094, 13.125995189979101],
              [80.3024498948607, 13.126201467641],
              [80.302727520325803, 13.126644115235701],
              [80.303031355187898, 13.1271469473716],
              [80.303017279770998, 13.1272207219707],
              [80.3030502841969, 13.1272721700464],
              [80.3031216319999, 13.1272561531927],
              [80.303241758403004, 13.1274260289143],
              [80.303465751676001, 13.1277982994242],
              [80.303583693962693, 13.1280686445012],
              [80.303579811089094, 13.1281482434108],
              [80.303752891, 13.128355501],
              [80.304154961746605, 13.128964132233699],
              [80.304317071720902, 13.1292359333883],
              [80.303921503969207, 13.129530061066299],
              [80.303983629947396, 13.1296116014127],
              [80.304243782480995, 13.1294519182344],
              [80.304391331679199, 13.1295062784653],
              [80.304601006855606, 13.1297873014448],
              [80.304657086000105, 13.130172219],
              [80.304728899005994, 13.1307470992719],
              [80.3047997614499, 13.1314003927613],
              [80.304858489913599, 13.1314569371087],
              [80.3049118794262, 13.131469556448],
              [80.304983712588395, 13.1314501420798],
              [80.305001185519799, 13.131430727711599],
              [80.305054575032301, 13.131320065813],
              [80.304851694884803, 13.1299377627982],
              [80.304757535199101, 13.1296659616436],
              [80.304654707000097, 13.129435776],
              [80.304449817463293, 13.1290820745205],
              [80.303714012909097, 13.127968175145799],
              [80.303246126635898, 13.1271925711369],
              [80.302973354762898, 13.126780986531299],
              [80.302721938694901, 13.1264082306622],
              [80.302487024839806, 13.126067508500499],
              [80.302265701042501, 13.1257466860663],
              [80.301991958451097, 13.1253579133434],
              [80.301873530805196, 13.125143869934099],
              [80.301757044596101, 13.1249322533209],
              [80.301588139592894, 13.124629389177199],
              [80.301388171600607, 13.1242794451907],
              [80.301095014641007, 13.1237664205114],
              [80.300836803544101, 13.1233344508193],
              [80.300592182505, 13.122908790796901],
              [80.300563060952697, 13.1228733595749],
              [80.300293201234993, 13.1223122843344],
              [80.299978688470304, 13.1216764637763],
              [80.299990963000099, 13.121616272],
              [80.300093233242507, 13.1212211968424],
              [80.300260196808907, 13.1209853122689],
              [80.300940388516096, 13.1192528663421],
              [80.301620877030501, 13.117566994420899],
              [80.301738555495405, 13.1171052343576],
              [80.301794836500406, 13.116926158432699],
              [80.301851117505294, 13.116496376213],
              [80.301753904860405, 13.1161164794296],
              [80.301697623855503, 13.115891355409801],
              [80.301400869465695, 13.115139236525399],
              [80.301651575760502, 13.114552762871501],
              [80.301729601699193, 13.1141926923512],
              [80.302077520638903, 13.113793608861499],
              [80.302686378783406, 13.113312022535],
              [80.303579200180195, 13.112378909055099],
              [80.304746391476101, 13.1111062308472],
              [80.304723367428593, 13.110918201126101],
              [80.3046952269261, 13.1108305818343],
              [80.304526383911295, 13.1102089325523],
              [80.304411263673899, 13.1094293127223],
              [80.304234745976501, 13.1081463616321],
              [80.304127300421598, 13.1072471446666],
              [80.303981481454201, 13.106652996330199],
              [80.303649551436393, 13.105643455803801],
              [80.303486944100996, 13.105451548769199],
              [80.303444733347305, 13.1055129462291],
              [80.303429224093094, 13.1054678974418],
              [80.303158691535202, 13.104886660159799],
              [80.303152295966399, 13.1048778262805],
              [80.303144781173202, 13.1048735092716],
              [80.303138385604399, 13.1048720702686],
              [80.303129991420406, 13.1048717904625],
              [80.3031222367933, 13.104873029603899],
              [80.303117280227596, 13.1048746284961],
              [80.303112963218695, 13.1048771867236],
              [80.3031097654343, 13.104880264591101],
              [80.303106247871497, 13.1048835822924],
              [80.303103689644004, 13.104887299716699],
              [80.303101131416497, 13.104892855867],
              [80.303100172081201, 13.1048991315189],
              [80.303216571432301, 13.1051600707237],
              [80.3033783793216, 13.1055017539838],
              [80.303405240710305, 13.105583777153001],
              [80.303395007800304, 13.105587774383499],
              [80.303414194506601, 13.105655247633701],
              [80.303589433090195, 13.1062279708148],
              [80.303725019147507, 13.1066149027239],
              [80.303794091290001, 13.106850259653701],
              [80.303809440655002, 13.107035091590401],
              [80.303914327982397, 13.107757151301699],
              [80.304021773537301, 13.1083385085006],
              [80.304136893774697, 13.109014520116901],
              [80.304226431737106, 13.109549829220899],
              [80.304356901339503, 13.110177874071599],
              [80.304460509553195, 13.1106322792309],
              [80.30457371112, 13.1109542961172],
              [80.304582664916197, 13.111011216679101],
              [80.304575629790605, 13.1110633405643],
              [80.304538535491901, 13.111131453371501],
              [80.304431089936998, 13.111237939591099],
              [80.303841418498706, 13.1118224945743],
              [80.303398845141601, 13.1122861733083],
              [80.302933247737002, 13.1127747947604],
              [80.302654400939701, 13.1130932940839],
              [80.301908677623999, 13.1137127049169],
              [80.301567154253107, 13.1139905923788],
              [80.301465464710006, 13.1137795785826],
              [80.300962773006702, 13.113487940647801],
              [80.300845094541799, 13.1132129311918],
              [80.300681367981895, 13.112806173019599],
              [80.301111150201606, 13.1127012856922],
              [80.301040798945394, 13.1124505793974],
              [80.300782417968094, 13.111644098178701],
              [80.300199142098606, 13.109857176271399],
              [80.299702845964006, 13.1083657296401],
              [80.299331219000095, 13.1070951670001],
              [80.298888044000094, 13.10575672],
              [80.298413499304999, 13.104177911226],
              [80.297830223435497, 13.1023347083137],
              [80.297818711411693, 13.102309765595599],
              [80.297750918383002, 13.1022649966144],
              [80.297721498766805, 13.1022624383869],
              [80.297438814628293, 13.1013875245826],
              [80.297759872179299, 13.101008906912901],
              [80.298158955668995, 13.100635405698201],
              [80.298578504978593, 13.099949161171899],
              [80.298769904, 13.099889445],
              [80.299942502000107, 13.099491599],
              [80.301049752741605, 13.0991305283726],
              [80.301689949172996, 13.098914358149001],
              [80.301683553604207, 13.0988686298325],
              [80.301653814209502, 13.098825779521899],
              [80.301060305429999, 13.099002297219201],
              [80.301046235178802, 13.098993343423],
              [80.300474151554596, 13.0991711402341],
              [80.299928449651503, 13.097409640712501],
              [80.299924772199503, 13.097402925365399],
              [80.2999183766307, 13.097397808910401],
              [80.299908303609996, 13.0973936517907],
              [80.299897750921502, 13.097393332012199],
              [80.299890715795897, 13.0973941314583],
              [80.299882241667305, 13.097397808910401],
              [80.299878244436897, 13.097402285808499],
              [80.2998729680926, 13.097409640712501],
              [80.299872328535798, 13.097424510409899],
              [80.300415632100595, 13.099189687383401],
              [80.299306000923394, 13.099532489868199],
              [80.298865985793697, 13.098191978659299],
              [80.298861988563303, 13.098179827078599],
              [80.298851116096401, 13.098170233725501],
              [80.298833528282401, 13.0981711930608],
              [80.2988204173665, 13.098176629294301],
              [80.298813382240795, 13.098191339102399],
              [80.298812263016202, 13.0982073280242],
              [80.299250999032097, 13.0995452810057],
              [80.298390155479098, 13.099790870845499],
              [80.298239220056701, 13.099339343692099],
              [80.296795903, 13.099758099],
              [80.296480438651997, 13.099850509524],
              [80.296260431087106, 13.0991361244952],
              [80.296032572000101, 13.0983818190001],
              [80.295671399205702, 13.097196348494901],
              [80.295359295450993, 13.096178173950699],
              [80.295126496748694, 13.095422217725099],
              [80.294869394885197, 13.094592072902],
              [80.294583956000096, 13.093700943],
              [80.294205534849496, 13.092463627623699],
              [80.295159433927694, 13.0921504046444],
              [80.295144404341201, 13.092081812169701],
              [80.294197540388495, 13.092359699631601],
              [80.294106723312296, 13.0921201855821],
              [80.294093292618001, 13.092077335271499],
              [80.294086257492395, 13.092017856482199],
              [80.294091373947396, 13.0919607760312],
              [80.294124630904804, 13.0919294377443],
              [80.294205215071003, 13.09189554123],
              [80.294203935957299, 13.091758995837299],
              [80.294182191023495, 13.0915773616849],
              [80.294147015395396, 13.0914651194534],
              [80.2940977695161, 13.0913595925691],
              [80.293924449603097, 13.0907769562565],
              [80.293616183189599, 13.090796462741199],
              [80.2934646082104, 13.0903458949231],
              [80.293868168598195, 13.090201674847901],
              [80.293808689808799, 13.090156745977501],
              [80.293570135094697, 13.090221980778701],
              [80.293463648875004, 13.0898676662702],
              [80.293742495672305, 13.089761819607499],
              [80.293678333000102, 13.089470261],
              [80.2939030244478, 13.0893003793225],
              [80.293785026204503, 13.0890922035599],
              [80.293722669409206, 13.089001066705301],
              [80.293699965140206, 13.0889674899694],
              [80.293672783972994, 13.0888880250277],
              [80.293660952170796, 13.0887780212453],
              [80.293651358817698, 13.088626925933699],
              [80.293632491889895, 13.0886031024401],
              [80.293608188728697, 13.088578799278899],
              [80.293554146172795, 13.088578159721999],
              [80.293517691430907, 13.088589032188899],
              [80.293494987161907, 13.0886063002245],
              [80.293475480677202, 13.088624207817],
              [80.293466526881005, 13.088651708762599],
              [80.2934873124794, 13.088762511991099],
              [80.293513534311202, 13.088806641415401],
              [80.293503940958104, 13.088833502804199],
              [80.293463648875004, 13.0888552477379],
              [80.293454695078793, 13.088857486187001],
              [80.293444142390399, 13.0888571664085],
              [80.293421351000106, 13.088844939000101],
              [80.293140192985803, 13.0886811283788],
              [80.292729597472302, 13.0884227474015],
              [80.292549881990595, 13.0878605769089],
              [80.293300082204397, 13.087650802254],
              [80.293761202710897, 13.0884259451859],
              [80.294197380499298, 13.089072537186],
              [80.294271569096693, 13.089103235915999],
              [80.295030723106706, 13.0900120462346],
              [80.295468461, 13.091518115],
              [80.295691536, 13.09224385],
              [80.297306595000094, 13.091756061000099],
              [80.297969988, 13.091546430000101],
              [80.297977801184302, 13.0914705556869],
              [80.297976522070599, 13.091424507591899],
              [80.297962374000093, 13.09138653],
              [80.297508366438507, 13.0908703315602],
              [80.297097770925006, 13.0911466201299],
              [80.296118401000101, 13.089893528000101],
              [80.29524692299999, 13.0887424630001],
              [80.294443855000097, 13.0876687320001],
              [80.293512296000102, 13.086392746000101],
              [80.294202340000098, 13.085893059000099],
              [80.294615011138305, 13.0855984642438],
              [80.295356379000097, 13.086556929],
              [80.296155877, 13.087630065],
              [80.296860198, 13.088584229],
              [80.297702527000098, 13.089709714],
              [80.298199087862898, 13.0903535696056],
              [80.297788492349497, 13.090689336964701],
              [80.298365372650295, 13.091397965981599],
              [80.298498400480099, 13.0914152340172],
              [80.300092176211294, 13.090939403702601],
              [80.300268693908706, 13.091074989759999],
              [80.302228857, 13.095532226],
              [80.302643835000097, 13.096474492],
              [80.303282605679698, 13.097913291751199],
              [80.303147339400695, 13.098009545060799],
              [80.302967442000096, 13.098202933],
              [80.302608832512405, 13.098396956637499],
              [80.302609791847701, 13.098419660906499],
              [80.302691974906097, 13.0984586738759],
              [80.303112483551104, 13.098265048032101],
              [80.3032583025185, 13.0981051588135],
              [80.303512846154504, 13.0981269037472],
              [80.303610698356295, 13.0985055214169],
              [80.303599825889407, 13.098539417931301],
              [80.3035972676619, 13.098585466026201],
              [80.303911609865807, 13.0995332893142],
              [80.304416220239702, 13.1007938559138],
              [80.304445639855999, 13.1008616489425],
              [80.304494885735295, 13.101188142726899],
              [80.304456512322801, 13.1013747334451],
              [80.304423255365407, 13.1014214210969],
              [80.304409824670998, 13.1015456550198],
              [80.304352264552307, 13.1016686098289],
              [80.304304937343602, 13.101740400088],
              [80.304297262661095, 13.1018030766617],
              [80.304355142558194, 13.101840490738899],
              [80.304391597300096, 13.101832176499499],
              [80.304445320077505, 13.101769499925799],
              [80.304488809944999, 13.101687316867499],
              [80.304569394111198, 13.101547893468799],
              [80.304605209296199, 13.1014455643689],
              [80.304647420049903, 13.1013138156528],
              [80.304639105810494, 13.1010560742324],
              [80.304619919104297, 13.100857491822801],
              [80.3047017823842, 13.1007861812313],
              [80.304797715915399, 13.100857811601299],
              [80.305814611345795, 13.1010973256508],
              [80.306445214424002, 13.1015558879298],
              [80.306956859923602, 13.1020579400762],
              [80.307271521905903, 13.1024346390753],
              [80.307637348438107, 13.102974425077401],
              [80.307930265486604, 13.1035340373425],
              [80.308182250895101, 13.104047601512701],
              [80.308619068240404, 13.1049289108858],
              [80.309030942867594, 13.105720042739501],
              [80.309345604849796, 13.106365995182699],
              [80.309900740216904, 13.107451962755601],
              [80.309996673748003, 13.1074110311156],
              [80.309394211172304, 13.1061933148266],
              [80.308864658080196, 13.1051150219362],
              [80.308154749949495, 13.1036504366937],
              [80.307763341142405, 13.1029091902762],
              [80.307556124715006, 13.102599644748899],
              [80.307159599452802, 13.102076487225601],
              [80.306916567840503, 13.1017848492908],
              [80.306458645118397, 13.1009060981453],
              [80.305744899646498, 13.099188248380401],
              [80.305146274411996, 13.0976968017491],
              [80.304605209296199, 13.096395943066399],
              [80.304254732128996, 13.0956502197508],
              [80.303868439776807, 13.0947695499346],
              [80.303766110676904, 13.0945521005973],
              [80.303644594870704, 13.0941344699583],
              [80.3035013341308, 13.093652244074899],
              [80.303394039000096, 13.093193017],
              [80.303354064000104, 13.0927780390001],
              [80.303300764000099, 13.092357351],
              [80.3032170070001, 13.0916549340001],
              [80.303144672, 13.0909791670001],
              [80.303078047, 13.090394771],
              [80.303039574067398, 13.0899741524898],
              [80.302955152560003, 13.0898360082049],
              [80.302927012057495, 13.0897854832118],
              [80.302875847507593, 13.089735597775601],
              [80.302728749426393, 13.089615361083199],
              [80.302591884255307, 13.089553963623301],
              [80.302388505169205, 13.089504078187],
              [80.302242686201794, 13.0894180577874],
              [80.3020827969832, 13.0893221242563],
              [80.301883255238394, 13.089170869055399],
              [80.301019853457802, 13.0880458885132],
              [80.300300991530904, 13.0871188508237],
              [80.299821323874994, 13.0864927246436],
              [80.299350610015395, 13.0858985763072],
              [80.298821056923302, 13.0852020988709],
              [80.2982633633288, 13.0844947489677],
              [80.297677529231805, 13.0837291993889],
              [80.297073787542303, 13.0829502191158],
              [80.296869129342397, 13.082675849216701],
              [80.296441905350306, 13.082045885695299],
              [80.295971191490693, 13.081321267756501],
              [80.295572108000997, 13.0807264798632],
              [80.294988832131494, 13.079687839499099],
              [80.294385090442006, 13.078590359902501],
              [80.294098568962198, 13.078007084032899],
              [80.293863212032406, 13.0774033423434],
              [80.293397614627807, 13.076408191846699],
              [80.292819455213206, 13.075141869235299],
              [80.292287343893705, 13.0740878795061],
              [80.291796164214105, 13.073074821416901],
              [80.2915915060142, 13.0726731796998],
              [80.291182189614602, 13.0710768457411],
              [80.290711475754904, 13.0693960902749],
              [80.290384022635195, 13.0681886068959],
              [80.289942594626794, 13.066880102442401],
              [80.289521125484399, 13.0658442702559],
              [80.289319610436706, 13.065517283574801],
              [80.289140908413302, 13.0652273680581],
              [80.288950799877796, 13.0648699640112],
              [80.288737878318003, 13.0645876528359],
              [80.288639021879504, 13.0642986878619],
              [80.288608604513797, 13.064127590179901],
              [80.288429902490407, 13.0637853948159],
              [80.288224585272005, 13.063590533567],
              [80.288182761394197, 13.063396622860701],
              [80.287966037663594, 13.0628975879549],
              [80.287931818127205, 13.0627531054679],
              [80.287867181225195, 13.0626086229809],
              [80.2878025443231, 13.062356729171301],
              [80.287650457494607, 13.0620525555144],
              [80.287517381519706, 13.0615421140965],
              [80.287380503374195, 13.061082051440501],
              [80.287068725375903, 13.060548796998299],
              [80.287007890644503, 13.060262683652301],
              [80.2869736711081, 13.0599851251904],
              [80.286931847230306, 13.0598254340205],
              [80.286825386450403, 13.059612512460699],
              [80.286810177767507, 13.0594794364858],
              [80.286711321328994, 13.0593577670231],
              [80.2866086627198, 13.0590383846833],
              [80.286464180232798, 13.0587940952152],
              [80.286080160991006, 13.0574956539174],
              [80.285928074162598, 13.0572979410404],
              [80.285957732740101, 13.057197818131501],
              [80.285607960056296, 13.0559978287703],
              [80.285338904145803, 13.0549834879874],
              [80.284757743378904, 13.0531162399681],
              [80.284316491685601, 13.0515933835142],
              [80.284155058139206, 13.050745857395899],
              [80.283638470790905, 13.048214041277401],
              [80.283197219097602, 13.0468337844561],
              [80.282750586285999, 13.044924832770601],
              [80.282061803154903, 13.0428140891522],
              [80.281354186110093, 13.0406475164322],
              [80.281093201876899, 13.0389968584209],
              [80.280974817276203, 13.03821726892],
              [80.28079494399999, 13.037101466],
              [80.280773697983093, 13.036954219579799],
              [80.279507453603799, 13.0282366399172],
              [80.2794455707444, 13.027806823100001],
              [80.279241088252306, 13.026444054913],
              [80.278979431379398, 13.0248646967179],
              [80.278785711123703, 13.023062022116999],
              [80.278430557321798, 13.019147258618201],
              [80.278269123775402, 13.0182459213177],
              [80.278183025884005, 13.0168575928192],
              [80.278021592337694, 13.016265669815899],
              [80.278000067864895, 13.0156199356305],
              [80.277957018919196, 13.014511425279],
              [80.277696601366898, 13.013225124504499],
              [80.277232849310096, 13.0117527117243],
              [80.275747092000103, 13.0082655640001],
              [80.275589096, 13.0079115],
              [80.275396836000098, 13.0074318010001],
              [80.275360668000104, 13.007338526000099],
              [80.27518744299999, 13.006649434000099],
              [80.27503515799999, 13.0060935920001],
              [80.274957111, 13.005714782],
              [80.274936172000096, 13.005497775],
              [80.27486954699999, 13.005295997000101],
              [80.274648733, 13.004612616],
              [80.274513580000104, 13.0042490340001],
              [80.274368909, 13.003689385],
              [80.274245177000097, 13.003289635],
              [80.274012941000095, 13.002573893],
              [80.27389492, 13.0022046010001],
              [80.273801645, 13.0019495230001],
              [80.273721695000106, 13.0016144950001],
              [80.273626517000096, 13.00122807],
              [80.273426642000103, 13.000613218000099],
              [80.273325753000094, 13.000365754],
              [80.273241996000095, 13.000106868],
              [80.273192503000104, 12.999872729000099],
              [80.273063060000098, 12.9991760230001],
              [80.272926003000094, 12.9986011450001],
              [80.272775621000093, 12.9980605320001],
              [80.272665214000099, 12.997592254],
              [80.272590975000099, 12.9971639510001],
              [80.272472954000094, 12.996629048],
              [80.272280694000102, 12.99580861],
              [80.272179804000103, 12.9952756110001],
              [80.271809643, 12.994208321],
              [80.270548528000106, 12.9894018090001],
              [80.269689543000098, 12.98549295],
              [80.268718723000106, 12.98147404],
              [80.267933501000101, 12.978276045],
              [80.267105448000095, 12.974671161000099],
              [80.266847317, 12.973595613],
              [80.266462994000094, 12.971994268000101],
              [80.265563558000096, 12.9677897600001],
              [80.264721228, 12.9638779260001],
              [80.263993113000097, 12.9599232620001],
              [80.263165061000095, 12.955518880000101],
              [80.262508330000102, 12.9519925180001],
              [80.261758799000106, 12.947959332],
              [80.261094930000098, 12.944290203],
              [80.2603311230001, 12.939871544],
              [80.259738637000098, 12.93640229],
              [80.258960553000094, 12.931940800000101],
              [80.258475143000098, 12.928186011],
              [80.258168193000103, 12.92563047],
              [80.25801114799999, 12.924167101],
              [80.257782720000094, 12.921390270000099],
              [80.257504323, 12.919027466],
              [80.257097435, 12.916036483],
              [80.25674051599999, 12.9131454380001],
              [80.256255106000097, 12.909697599],
              [80.255712589, 12.9060713000001],
              [80.255212902, 12.902316511],
              [80.254648970000105, 12.8980477580001],
              [80.254279497216999, 12.895310536033399],
              [80.251161968812497, 12.895776373611101],
              [80.248402777006305, 12.8961605900747],
              [80.246878851084404, 12.8963442375813],
              [80.246555352766507, 12.896392015794399],
              [80.246034769319706, 12.896508475188799],
              [80.244722859218498, 12.896750352392599],
              [80.244011986, 12.8968675450001],
              [80.240557009, 12.896962724],
              [80.238542548278701, 12.897039012430101],
              [80.238548744000099, 12.898314258],
              [80.238815243, 12.900608058],
              [80.239348242000105, 12.9043009810001],
              [80.239747992, 12.9068422460001],
              [80.240452312000102, 12.910430473000099],
              [80.240937722000098, 12.912790898],
              [80.241695910342997, 12.916032842894399],
              [80.242436472645906, 12.9191702788876],
              [80.243113330664798, 12.9224590125557],
              [80.243783558000104, 12.92536397],
              [80.2443927, 12.929056894],
              [80.245001842000093, 12.932245371],
              [80.245487252, 12.9342726720001],
              [80.245506535000104, 12.9343901],
              [80.245536208728595, 12.9345708029426],
              [80.243826426716595, 12.934842497057399],
              [80.242692689535005, 12.935041324100499],
              [80.242202962850797, 12.9346610891546],
              [80.241960090267597, 12.934207196130201],
              [80.241898376742299, 12.934024046313301],
              [80.2406700785141, 12.9342211314423],
              [80.239993220495194, 12.934378401393801],
              [80.239881737998004, 12.9343395815956],
              [80.239814052196095, 12.9342076938199],
              [80.239749850222296, 12.9340678430087],
              [80.239489558498803, 12.933525361214199],
              [80.239379071381094, 12.933454689274001],
              [80.239183977010896, 12.9334143764067],
              [80.239054577683802, 12.933151347389799],
              [80.238877400143593, 12.932714126971],
              [80.238640499837004, 12.932149000294199],
              [80.238412557945395, 12.932293330313],
              [80.2379178543639, 12.9324585632999],
              [80.237482873548899, 12.9326203124588],
              [80.237116076225405, 12.932761656339199],
              [80.236697519171102, 12.932935847741099],
              [80.236456139657093, 12.9329696906421],
              [80.236082374677594, 12.933015975786001],
              [80.236250593802794, 12.933583839756199],
              [80.234955605152095, 12.933890914313301],
              [80.2354751932195, 12.935084871950901],
              [80.233751196030298, 12.935819461977299],
              [80.233344085839505, 12.9349181458948],
              [80.2332137920001, 12.934913922],
              [80.233023436000096, 12.934956752000099],
              [80.232390499000104, 12.935199457000101],
              [80.231781357, 12.935408849000099],
              [80.231262634000103, 12.935646795],
              [80.231043724000102, 12.9356991440001],
              [80.229563699, 12.935808599],
              [80.228973593, 12.9358704650001],
              [80.227074783, 12.935927572],
              [80.227089060000097, 12.936955499],
              [80.22715092599999, 12.938416488],
              [80.2271794790001, 12.940358128000099],
              [80.227188997, 12.941576412000099],
              [80.227522122, 12.942918428],
              [80.2277981390001, 12.9441938190001],
              [80.228550049, 12.945478728000101],
              [80.229397137, 12.947087244],
              [80.230682046000098, 12.9499330790001],
              [80.2311083660001, 12.9508602760001],
              [80.231274928000104, 12.953043431],
              [80.231358209000106, 12.954173675],
              [80.231096469000093, 12.9555894550001],
              [80.23016253, 12.957707175000101],
              [80.229847252000098, 12.9590218270001],
              [80.229567665, 12.9601044820001],
              [80.228853827000094, 12.9624244570001],
              [80.228571687000098, 12.963532393],
              [80.228345638000107, 12.965566832],
              [80.228559790000105, 12.968422185000099],
              [80.228866203000095, 12.972588264],
              [80.226764252000095, 12.9704134740001],
              [80.2248963750001, 12.968735954000101],
              [80.223754234000097, 12.968069705000101],
              [80.222826244, 12.967665196],
              [80.221529438000104, 12.9674272500001],
              [80.219994686000106, 12.9673677640001],
              [80.218971518, 12.9674510450001],
              [80.217596500978104, 12.9677721605029],
              [80.217353579266401, 12.967120597676001],
              [80.216887757624207, 12.9658851252118],
              [80.216329368860897, 12.964471237086499],
              [80.2161293044377, 12.9639867527183],
              [80.215794868386993, 12.963082730268599],
              [80.215203633225798, 12.961656151489599],
              [80.214698993113501, 12.9604124674259],
              [80.214428756840405, 12.959735383725],
              [80.213643707000102, 12.959991655000101],
              [80.212751409000106, 12.9602325750001],
              [80.212387801120002, 12.9603437885941],
              [80.212151531008203, 12.9597491941422],
              [80.2119708758201, 12.9593229374793],
              [80.211621165000096, 12.958477723],
              [80.211222873760093, 12.9575499785049],
              [80.211175097181396, 12.957240923761599],
              [80.210649554816001, 12.9561614716871],
              [80.210467126000097, 12.955800829000101],
              [80.210324359000097, 12.955342783],
              [80.210238103000094, 12.955143503],
              [80.210164323938699, 12.955029763979599],
              [80.210035924383504, 12.9547662462879],
              [80.209685811642899, 12.9540645277885],
              [80.209351754000096, 12.954129258],
              [80.209205806328995, 12.9541570949097],
              [80.208974014, 12.9541798220001],
              [80.207974641000106, 12.954292846],
              [80.207519569000098, 12.954364230000101],
              [80.207445211, 12.953879415000101],
              [80.207365661540805, 12.953452390374199],
              [80.207246220094106, 12.953064578927],
              [80.207196950497405, 12.9526920709151],
              [80.207031971999101, 12.9520840393005],
              [80.206908798007206, 12.951539834208999],
              [80.206782637979103, 12.9509090340686],
              [80.205901535000095, 12.951012164000099],
              [80.205315593000094, 12.9510805740001],
              [80.204657326736907, 12.951148663471001],
              [80.2045080249286, 12.951255414264001],
              [80.204131037862396, 12.951297218770399],
              [80.203616319878094, 12.951342009312899],
              [80.2033671724854, 12.9513642179569],
              [80.203200887596296, 12.9513722429291],
              [80.203180545224896, 12.9513627249388],
              [80.203164681907793, 12.951348541267],
              [80.203150124981406, 12.951321480314199],
              [80.203143670744197, 12.9509928370181],
              [80.202331764444295, 12.951032021433299],
              [80.202323421000102, 12.950533298],
              [80.201306201000094, 12.950515452],
              [80.200874924000104, 12.950533298],
              [80.200634004000094, 12.950577912],
              [80.200482313000094, 12.950667142],
              [80.2003246740001, 12.950780167000101],
              [80.200164060000105, 12.9509972920001],
              [80.199786321, 12.951556466000101],
              [80.199381812000098, 12.951610004],
              [80.199146840000097, 12.951601081],
              [80.198786947, 12.951574312],
              [80.198418131, 12.951621901000101],
              [80.19793629, 12.9517468230001],
              [80.197683472000094, 12.951815232000101],
              [80.19718378499999, 12.951818206],
              [80.196678150000096, 12.9517735920001],
              [80.196160617, 12.9516903100001],
              [80.195898876000101, 12.951672464000101],
              [80.19566093, 12.9516903100001],
              [80.195393241, 12.9517973860001],
              [80.195229653000098, 12.951901488],
              [80.195107705000098, 12.9520829210001],
              [80.194967912, 12.952255432000101],
              [80.194724017000098, 12.952427943],
              [80.194509866, 12.9524963530001],
              [80.194307612, 12.9524963530001],
              [80.1940785890001, 12.952445789],
              [80.193897155000101, 12.952326816],
              [80.193537261000102, 12.952091844000099],
              [80.193349879, 12.952029384000101],
              [80.19279070499999, 12.951996666000101],
              [80.19221666, 12.9520234350001],
              [80.191428464000097, 12.9516873360001],
              [80.191119134000104, 12.951467236],
              [80.190738420000102, 12.9512292900001],
              [80.190577806000107, 12.951041907],
              [80.190274425, 12.950637399000099],
              [80.189733098, 12.950637399000099],
              [80.188448189, 12.950750423],
              [80.188106141, 12.950780167000101],
              [80.187716505000097, 12.9506730910001],
              [80.187436918000103, 12.9505451950001],
              [80.187029435000099, 12.9505808870001],
              [80.186726054000104, 12.950637399000099],
              [80.186119291000097, 12.950577912],
              [80.18570586, 12.950533298],
              [80.185447094, 12.950408376],
              [80.185119918000098, 12.9502447880001],
              [80.18507232899999, 12.950033611],
              [80.185009868000094, 12.94990274],
              [80.184965253, 12.9497302290001],
              [80.1849087410001, 12.94948336],
              [80.184938484, 12.949182953],
              [80.184394182000105, 12.9492305430001],
              [80.183314502, 12.949355464000099],
              [80.182148566000095, 12.9495458210001],
              [80.180500789000106, 12.949855151000101],
              [80.179650132000106, 12.950018739],
              [80.178228404, 12.950384581],
              [80.176490607451797, 12.9508267314467],
              [80.176583921081999, 12.9524839815197],
              [80.176624232570205, 12.9530979852066],
              [80.176685446311694, 12.953646669352301],
              [80.176835494629103, 12.9549120021783],
              [80.176889243280101, 12.955453967742599],
              [80.176907159497105, 12.9559765240719],
              [80.177022121889493, 12.957015664658201],
              [80.177034066034196, 12.9570893824261],
              [80.177186353878696, 12.957971756113499],
              [80.177128126173599, 12.958019159437701],
              [80.177453195000098, 12.959142039],
              [80.177110553, 12.959241976],
              [80.1769784511106, 12.9592635900104],
              [80.17684286399999, 12.959299083000101],
              [80.176688805602296, 12.9594016941832],
              [80.176479783070604, 12.959574884280901],
              [80.176546968884395, 12.9596961920002],
              [80.176596589, 12.959805908],
              [80.176621619788605, 12.9599914363263],
              [80.176625143000095, 12.960180673],
              [80.176649240623107, 12.960337070012599],
              [80.176702989274105, 12.9605386274539],
              [80.176725384545307, 12.960781616147001],
              [80.176705975310298, 12.9608458159246],
              [80.176680594002804, 12.9609294249373],
              [80.176988155728097, 12.9608771693044],
              [80.177124020373697, 12.9608562670512],
              [80.177267597000096, 12.960876666],
              [80.177595814088093, 12.961154124158901],
              [80.177627167467904, 12.961188463574899],
              [80.177713746000094, 12.9612050310001],
              [80.178021324242195, 12.961210858846099],
              [80.178302758150807, 12.9612601284429],
              [80.178422946106707, 12.961287002768399],
              [80.1785453735896, 12.9613008131857],
              [80.178792468082506, 12.9613205956753],
              [80.179091071699204, 12.961333286328999],
              [80.179315402945804, 12.9612762293436],
              [80.179480002909997, 12.961210858846099],
              [80.1795083702536, 12.9613525089368],
              [80.1795135958169, 12.961594937748201],
              [80.179559879377507, 12.9617602895009],
              [80.179559879377507, 12.9618304613509],
              [80.179517328362095, 12.9619286272899],
              [80.179455368111604, 12.9620182083749],
              [80.179288150086293, 12.962316811991601],
              [80.179286657068204, 12.962454169655301],
              [80.1792418665257, 12.962531806595701],
              [80.179268740851199, 12.962860270574099],
              [80.179337419682994, 12.963370136249701],
              [80.179409084550997, 12.963629921396199],
              [80.179482988946205, 12.963780716222701],
              [80.179567344467898, 12.9639016506875],
              [80.179650953480603, 12.964050205986799],
              [80.1796987300593, 12.9641905496867],
              [80.179638262826899, 12.9643189492419],
              [80.179657766, 12.964389345],
              [80.179726350893802, 12.964532264200599],
              [80.179821157542094, 12.964702095007601],
              [80.179951050115406, 12.9649111175393],
              [80.179805107597701, 12.965063778638401],
              [80.179769648418201, 12.9651350702519],
              [80.179547358404307, 12.9652520478224],
              [80.178935805806503, 12.965519966103299],
              [80.178654297467801, 12.9656170379443],
              [80.178238344629406, 12.9658225875674],
              [80.1784849071054, 12.9672073173782],
              [80.178579066791002, 12.967812560306401],
              [80.178703318747495, 12.9681646884094],
              [80.178962015203595, 12.9679644777375],
              [80.179143054186895, 12.9678266357234],
              [80.179279925482604, 12.967761112230701],
              [80.179336539000104, 12.968288687],
              [80.179315718000097, 12.969421905],
              [80.179339513000102, 12.970638405],
              [80.179339513000102, 12.9716853670001],
              [80.179336539000104, 12.972348642],
              [80.178101004, 12.972633821],
              [80.176487729, 12.973030001],
              [80.175895243000099, 12.973233445000099],
              [80.175733082201404, 12.9745256443589],
              [80.1750386370001, 12.974611153000099],
              [80.173982157000097, 12.974757490000099],
              [80.172690109, 12.974950226],
              [80.171986979000096, 12.975043025],
              [80.170980467, 12.975499881000101],
              [80.170520041, 12.975699756],
              [80.169617035, 12.976420733000101],
              [80.168831813000097, 12.9770596180001],
              [80.168278589000096, 12.9773094610001],
              [80.167061494000095, 12.977880532],
              [80.166822358, 12.977962623000099],
              [80.165744463000095, 12.977984039],
              [80.165323298, 12.9786728930001],
              [80.165408959, 12.978779968],
              [80.165408959, 12.978879906],
              [80.165348282000096, 12.979029812],
              [80.165230499000103, 12.979172579],
              [80.164969948000106, 12.979354608000101],
              [80.16482718, 12.979415284],
              [80.16465942799999, 12.979425992000101],
              [80.164516661000107, 12.979401008],
              [80.164306078, 12.9793046390001],
              [80.164124050000098, 12.979197564000099],
              [80.163119493000096, 12.9783232940001],
              [80.1624235, 12.979355386000099],
              [80.162172793169603, 12.9796795119665],
              [80.160671415363197, 12.978630488938901],
              [80.159973792399796, 12.978119243909999],
              [80.159484550321494, 12.9787773909915],
              [80.158762335825003, 12.979955843140299],
              [80.158377931334996, 12.9797785252442],
              [80.157681948000103, 12.9808252980001],
              [80.157107309000097, 12.981606951000099],
              [80.156871742000106, 12.981992423000101],
              [80.156507684000104, 12.9827383840001],
              [80.155472619000093, 12.9829917970001],
              [80.1546302900001, 12.983195241],
              [80.153734423000103, 12.9828597370001],
              [80.152174686000095, 12.9822708200001],
              [80.151753521, 12.9821280520001],
              [80.150200923000099, 12.982766938000101],
              [80.149451393, 12.9825028180001],
              [80.148648325000096, 12.982288666],
              [80.148027285000097, 12.982038823],
              [80.14753783, 12.9818541770001],
              [80.146621738000107, 12.9815061800001],
              [80.145681851000106, 12.981125467],
              [80.1453665720001, 12.981003519000099],
              [80.145259496000094, 12.981000545],
              [80.145200010000096, 12.981048134],
              [80.145170267000097, 12.981146287000101],
              [80.145167292000096, 12.9813366440001],
              [80.145307086000102, 12.981503206000101],
              [80.145907899000093, 12.9817381780001],
              [80.146702045, 12.98197315],
              [80.147793622000094, 12.982413350000099],
              [80.14882868799999, 12.982820833000099],
              [80.15021175, 12.983299699],
              [80.150946408, 12.983549542],
              [80.151008869000094, 12.98365067],
              [80.150979126, 12.983811283],
              [80.150848255000099, 12.984322867000101],
              [80.150756051, 12.984929630000099],
              [80.150791743000099, 12.985006962000099],
              [80.1508512300001, 12.985054552000101],
              [80.151761374, 12.9854322910001],
              [80.152433571000103, 12.985720801000101],
              [80.153111718, 12.986092591],
              [80.153171204, 12.98620859],
              [80.153150384000099, 12.9862740250001],
              [80.152965976000104, 12.9865952530001],
              [80.1529094630001, 12.9868748390001],
              [80.153144435000101, 12.98713658],
              [80.153367510000095, 12.9872377070001],
              [80.153656019, 12.98722581],
              [80.154274679000096, 12.987094939],
              [80.15455724, 12.9870622220001],
              [80.154881442, 12.9872555530001],
              [80.155333539000097, 12.987439961000099],
              [80.1556161, 12.9874578070001],
              [80.155984917000097, 12.987538114000101],
              [80.156469732000105, 12.987716574],
              [80.157055674, 12.987799855],
              [80.157522644, 12.988049698000101],
              [80.1583376090001, 12.988454206],
              [80.158519043000098, 12.98879328],
              [80.159048473, 12.9895725530001],
              [80.159438110000096, 12.990215008000099],
              [80.159625492, 12.990396442000099],
              [80.160184666000106, 12.990351827],
              [80.160963939000098, 12.9901912130001],
              [80.161636137000102, 12.9900841370001],
              [80.161802699000106, 12.990426185],
              [80.162019825000101, 12.9910656650001],
              [80.162132849, 12.991470173],
              [80.162076337, 12.991609967],
              [80.161332755, 12.991862784],
              [80.161276243, 12.991972834],
              [80.161508241, 12.993010874],
              [80.161662906000103, 12.993680098],
              [80.161802699000106, 12.9937514820001],
              [80.162115003000096, 12.993671175],
              [80.162248848, 12.9936919950001],
              [80.162317257, 12.993748507000101],
              [80.16232618, 12.993828814],
              [80.162275617, 12.9939239920001],
              [80.161749161, 12.994902546],
              [80.1611037320001, 12.99596438],
              [80.160345279000097, 12.996972677],
              [80.160339330000099, 12.9970678550001],
              [80.160389894, 12.99718088],
              [80.161189988, 12.9985579930001],
              [80.161939518000096, 12.9999618750001],
              [80.162275617, 13.0007887370001],
              [80.162299411, 13.0008779670001],
              [80.162266694, 13.0009196080001],
              [80.162162592000101, 13.0009880170001],
              [80.161326807000094, 13.001478781000101],
              [80.159845592000096, 13.00221344],
              [80.157828999000102, 13.0031622500001],
              [80.156833790000107, 13.0036492460001],
              [80.155772550000094, 13.0042536290001],
              [80.155453702, 13.0044439860001],
              [80.155087265000105, 13.004562959000101],
              [80.154749977000094, 13.0046884760001],
              [80.154280033000106, 13.004884781],
              [80.154158086000095, 13.004988883000101],
              [80.154101573, 13.0050870360001],
              [80.153819012, 13.005702721],
              [80.153798192000096, 13.005827643],
              [80.154018292, 13.0066336850001],
              [80.154220546, 13.007163115],
              [80.154333571000095, 13.0072404480001],
              [80.15455962, 13.0073058830001],
              [80.154976025000096, 13.00738619],
              [80.155796939000098, 13.007570598],
              [80.156704109, 13.007844236],
              [80.157519074, 13.008073259000099],
              [80.158372706000094, 13.0075735720001],
              [80.158842649000107, 13.0073326520001],
              [80.15922931199999, 13.008992326000101],
              [80.159363157, 13.0095901650001],
              [80.159699255000106, 13.010039289000099],
              [80.159880689, 13.010161236],
              [80.160029406, 13.010205851],
              [80.160430940000097, 13.010265338000099],
              [80.160654014, 13.010342670000099],
              [80.160767039000106, 13.010479489],
              [80.160808679000098, 13.010744204],
              [80.160874114000094, 13.011574041000101],
              [80.160990113000096, 13.012127266],
              [80.161076369000099, 13.012296802],
              [80.161635542000099, 13.0129749490001],
              [80.161974615000105, 13.0132009980001],
              [80.162275022000102, 13.013415149],
              [80.162429687, 13.013614429],
              [80.162533788000104, 13.0138672470001],
              [80.162634915000098, 13.014253909],
              [80.162753889, 13.014462112],
              [80.162976750507099, 13.014681839733999],
              [80.163198737897801, 13.0147670209421],
              [80.164517755998304, 13.014767666254301],
              [80.164551312231794, 13.0141855946659],
              [80.164605518455105, 13.013625463691699],
              [80.164711349653004, 13.0136151386968],
              [80.164859712000094, 13.014134936000101],
              [80.164996531, 13.014474009],
              [80.165469448000096, 13.0150837460001],
              [80.165819270005898, 13.0156870747859],
              [80.166335519751698, 13.0155747904662],
              [80.166412957213595, 13.0155864060855],
              [80.166459419690696, 13.015635449811301],
              [80.166547182147497, 13.016357554143299],
              [80.166797563274201, 13.0173861817618],
              [80.166960181944106, 13.0184122281316],
              [80.1670505256496, 13.019156918389999],
              [80.167559031649304, 13.019063993435701],
              [80.168114000125996, 13.0189917184713],
              [80.168635412369298, 13.0189116997607],
              [80.169172312104905, 13.018844587293801],
              [80.169407205739304, 13.018808449811599],
              [80.169796974297398, 13.018725849852199],
              [80.170098980398706, 13.018645831141599],
              [80.170308061545796, 13.018586462420901],
              [80.170646205129302, 13.0184857937204],
              [80.171054042428494, 13.018423843750901],
              [80.171588360915393, 13.018346406289099],
              [80.172450497990894, 13.0180953798502],
              [80.172844138422093, 13.017968253350199],
              [80.173853406675093, 13.0176546316297],
              [80.175026584222493, 13.017300677897699],
              [80.175088534192, 13.017551059024401],
              [80.175506696486096, 13.0185377413511],
              [80.175609946435301, 13.018782314668201],
              [80.175387959044599, 13.019288239419099],
              [80.175431840272907, 13.0193914893682],
              [80.175698156000095, 13.020083588],
              [80.175808206000099, 13.020381021],
              [80.175879590000093, 13.020604096000101],
              [80.176102665, 13.0211037820001],
              [80.176400097000098, 13.022034747000101],
              [80.176697530000098, 13.0228378150001],
              [80.176765940000095, 13.0231055040001],
              [80.176759991, 13.023373193000101],
              [80.176762965000094, 13.023649806],
              [80.175339781, 13.024080306],
            ],
          ],
        ],
      },
    },
  ],
};
