import { all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Action Constants
import { DataFile } from "../../constants/action-constants/congestion/CongestionDownloadActionConstants";

// Utils
import { saveFile } from "../../utils/FileUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";

// APIs
import { downloadCongestionDataApi } from "../../apis/congestion/CongestionDownloadAPI";

// Constants
import { Reports } from "../../constants/ReportConstants";


// Download Data File
function* downloadCongestionInfo(action) {

  const { criteria } = action.payload;

  try {
    const data = yield downloadCongestionDataApi(criteria);

    // Downloading File
    saveFile(Reports.CONGESTION_HISTORY, data);

    // Dispatching Action
    yield put({ type: DataFile.DOWNLOAD_CONGESTION_DATA_SUCCESS, });
  } catch (error) {
    // Show error toaster or redirect to error page
    const errorMessage = getErrorMessage(error);

    yield put({
      type: DataFile.DOWNLOAD_CONGESTION_DATA_FAILURE,
      payload: { error },
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(DataFile.DOWNLOAD_CONGESTION_DATA, downloadCongestionInfo),
  ]);
}