export const CongestionsData = [
  {
    date: "March 28 2023",
    startTime: "5:00 PM",
    duration: "6 mins",
    police: "Sarvanan",
    policeId: "emp-01",
  },
  {
    date: "March 28 2023",
    startTime: "5:00 PM",
    duration: "6 mins",
    police: "Sravan",
    policeId: "emp-02",
  },
  {
    date: "March 28 2023",
    startTime: "5:00 PM",
    duration: "6 mins",
    police: "Shakthivel",
    policeId: "emp-03",
  },
  {
    date: "March 28 2023",
    startTime: "5:00 PM",
    duration: "6 mins",
    police: "Narayan Murthy",
    policeId: "emp-04",
  },
  {
    date: "March 28 2023",
    startTime: "5:00 PM",
    duration: "6 mins",
    police: "Ramachandran",
    policeId: "emp-05",
  },
  {
    date: "March 28 2023",
    startTime: "5:00 PM",
    duration: "6 mins",
    police: "Prasanna",
    policeId: "emp-06",
  },
  {
    date: "March 28 2023",
    startTime: "5:00 PM",
    duration: "6 mins",
    police: "Shankar N",
    policeId: "emp-07",
  },
  {
    date: "March 28 2023",
    startTime: "5:00 PM",
    duration: "6 mins",
    police: "Valli Narayan",
    policeId: "emp-08",
  },
];
