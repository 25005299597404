// React Related Imports
import { all, put, takeLatest } from "redux-saga/effects";

// Constants
import { Auth } from "../../constants/action-constants/auth/AuthActionConstants";
import { LOCAL_STORAGE_KEYS } from "../../constants/GeneralConstants";

//Urls
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";

// Api
import { loginApi } from "../../apis/auth/AuthAPI";

// Utilities
import { setToken } from "../../utils/TokensUtils";
import { setItem } from "../../utils/LocalStorageUtils";

export function* login(action) {
  try {
    //getting email and password from action
    const { email, password, navigate } = action.payload;

    const loggedInData = yield loginApi(email, password);

    const { org: orgDetails = {}, privileges = [], token } = loggedInData;

    //setting token in localstorage
    setToken(token);

    //store permissions in local storage
    setItem(LOCAL_STORAGE_KEYS.PRIVILEGES, privileges);

    //set user details in local storage
    setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(loggedInData));

    //set org type in local storage
    setItem(LOCAL_STORAGE_KEYS.ORG_TYPE, orgDetails.type);

    //dispatching action
    yield put({
      type: Auth,
      payload: { loggedInData },
    });

    navigate(RedirectTo.HomePageUrl);
  } catch (error) {
    yield put({
      type: Auth.USER_LOGIN_FAILURE,
      payload: { error: "Login Error" },
    });
  }
}

/**
 * Auth Sagas
 */
export default function* root() {
  yield all([takeLatest(Auth.USER_LOGIN, login)]);
}
