import { Link } from "react-router-dom";

//Urls
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";

//Components
import TableHeaders from "../../components/table/TableHeaders";
import TableLoader from "../../components/table/TableLoader";
import TableDataNotFound from "../../components/table/TableDataNotFound";
import TablePagination from "../../components/table/TablePagination";

//Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Station",
    },
  },
  {
    title: {
      displayName: "Designation",
    },
  },
  {
    title: {
      displayName: "Phone No",
    },
  },
  {
    title: {
      displayName: "Alt. Phone No",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

//Page Components
function PoliceStaffTableNameCell({ name, id }) {
  return (
    <td className="text-primary">
      <Link to={RedirectTo.PoliceStaffInfoPageUrl.replace(":staffId", id)} className="text-decoration-none">
        {name}
      </Link>
    </td>
  );
}

function PoliceStaffTableActionCell({ id }) {
  return (
    <td className="text-primary">
      <Link to={RedirectTo.EditPoliceStaffPageUrl.replace(":staffId", id)} className="text-decoration-none">
        EDIT
      </Link>
    </td>
  );
}

function PoliceStaffTableBody({ userList, userListLoading }) {
  //Loader
  if (userListLoading) {
    return <TableLoader colSpan={7} />;
  }

  if (userList.length === 0) {
    return <TableDataNotFound colSpan={7} message="No users data present" />;
  }

  return (
    <>
      {userList.map((user) => {
        const { id, name, badgeNo, station, designation, phoneNo, phoneNoAlt } = user;

        return (
          <tr key={id}>
            {/* Police Staff Table Name Cell */}
            <PoliceStaffTableNameCell name={name} id={id} />

            <td> {station}</td>
            <td className="text-primary">{designation}</td>
            <td className="text-primary">{phoneNo}</td>
            <td className="text-primary">{phoneNoAlt}</td>

            {/* Police Staff Table Action Cell */}
            <PoliceStaffTableActionCell id={id} />
          </tr>
        );
      })}
    </>
  );
}

/**
 * Police staff table
 */
export default function PoliceStaffTable({ userList, userListLoading, userListPagination, pageNumber, searchValue }) {
  const { totalPages, pageSize } = userListPagination;

  return (
    <div className="container">
      <table className="table table-compact mt-3 bg-white">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Police Staff Table Body */}
          <PoliceStaffTableBody userList={userList} userListLoading={userListLoading} />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalPages={totalPages} />
    </div>
  );
}
