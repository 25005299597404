/* eslint-disable import/no-anonymous-default-export */
import { CongestionSearch } from "../../constants/action-constants/congestion/CongestionSearchActionConstants";

//initial state
const initialState = {
  congestionList: [],
  congestionListPagination: {},
  congestionListLoading: false,
  congestionListError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CongestionSearch.SEARCH_CONGESTIONS:
      return {
        ...state,
        congestionListLoading: true,
      };

    case CongestionSearch.SEARCH_CONGESTIONS_SUCCESS: {
      const { congestionList, congestionListPagination } = action.payload;
      return {
        ...state,
        congestionList,
        congestionListPagination,
        congestionListLoading: false,
      };
    }

    case CongestionSearch.SEARCH_CONGESTIONS_FAILURE:
      return {
        ...state,
        congestionListError: action.payload,
        congestionListLoading: false,
      };

    default:
      return state;
  }
};
