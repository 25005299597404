import { Link } from "react-router-dom";

// Constants
import { STATUS_COLOR } from "../../constants/GeneralConstants";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";

// Components
import TableLoader from "../../components/table/TableLoader";
import TableDataNotFound from "../../components/table/TableDataNotFound";

// Utils
import { getDurationMins, getDelayStyleInfo } from "../../utils/TrafficUtils";

// Page Components
// ----------------------------------------------------------------------------

function JunctionNameCell({ url, junctionName, isLive, junctionState }) {
  // Return Just Name if not Live
  if (!isLive) {
    return <td>{junctionName}</td>;
  }

  // Get Police and Device Info
  const policeMap = isLive ? junctionState?.policeMap || {} : {};
  const policeList = Object.values(policeMap) || [];

  return (
    <td className="" title={junctionName}>
      <Link to={url} className="d-block truncate">
        {junctionName}
      </Link>

      {isLive &&
        policeList.map((p) => {
          const { id, name, phoneNo } = p;
          return (
            <small key={id} className="mt-2 mb-0 text-muted">
              <i className="fas fa-user-circle"></i>
              <span className="ms-2">{name} &nbsp; | &nbsp;</span>
              <i className="fas fa-phone"></i>
              <span className="text-phone ms-1">{phoneNo}</span>
            </small>
          );
        })}
    </td>
  );
}

function CongestionStatusCell({ isLive, delayInSecs }) {
  // Get style info
  const styleInfo = getDelayStyleInfo(delayInSecs);
  const { colorCode } = styleInfo;

  return (
    <td>
      <i className={`fa-solid fa-circle fa-2xl`} style={{ color: STATUS_COLOR[colorCode] }}></i>
    </td>
  );
}

function getDurationMinsText(durationMins) {
  // Sanity checks
  if (!durationMins || durationMins <= 0) {
    return "No Delay";
  }

  const durationTxt = durationMins + " " + (durationMins === 1 ? "min" : "mins");
  return durationTxt;
}

function DelayDurationCell({ isLive, freeFlowDelay, delayInSecs, usualDelayInSecs }) {
  // Convert Delay Secs to Mins
  const delayMins = getDurationMins(delayInSecs);
  const usualDelayMins = getDurationMins(usualDelayInSecs);

  // Check if Usual delay should be displayed
  const showUsualDelayMins = isLive && !freeFlowDelay;

  return (
    <td>
      <span>{!freeFlowDelay && "+"}</span> {getDurationMinsText(delayMins)}
      {/** Usual Delay info */}
      {showUsualDelayMins && ( //
        <span className="sub-text">{getDurationMinsText(usualDelayMins)}</span>
      )}
    </td>
  );
}

function CongestionTableBody({ isLive, freeFlowDelay, junctionStatesMap, congestionList, congestionListLoading }) {
  //Loader
  if (congestionListLoading) {
    return <TableLoader colSpan={3} />;
  }

  //If there is no data
  if (congestionList.length === 0) {
    const message = isLive ? "No live congestion data present" : "No congestion history data ";
    return <TableDataNotFound colSpan={3} message={message} />;
  }
  return (
    <>
      {congestionList.map((congestion, index) => {
        const { junctionId, junctionName, delayInSecs } = congestion;
        const junctionState = junctionStatesMap[junctionId] || {};

        const dateTime = new Date(congestion.timestamp);
        const hourOfDay = dateTime.getHours();
        const usualDelayInSecs = isLive ? junctionState?.hourlyAvg["_" + hourOfDay] || 0 : 0;

        // Redirect
        const url = RedirectTo.signalLiveMonitorPageUrl.replace(":congestionId", junctionId);

        return (
          <tr key={index}>
            <JunctionNameCell isLive={isLive} url={url} junctionName={junctionName} junctionState={junctionState} />

            {/* Status */}
            <CongestionStatusCell isLive={isLive} delayInSecs={delayInSecs} />

            {/* Delay */}
            <DelayDurationCell
              isLive={isLive}
              freeFlowDelay={freeFlowDelay}
              delayInSecs={delayInSecs}
              usualDelayInSecs={usualDelayInSecs}
            />
          </tr>
        );
      })}
    </>
  );
}

/**
 * Congestion section
 */
export default function CongestionTable({
  isLive = true,
  freeFlowDelay = true,
  junctionStatesMap = {},
  congestionList,
  congestionListLoading,
}) {
  // Sub-text rendering
  const subTextClsName = isLive ? "sub-text" : "d-none";
  const delayColName = isLive ? "Live Delay" : "Delay";

  return (
    <div className="table-responsive">
      <table className="table table-compact mt-3">
        <thead>
          <tr>
            <th className="">
              Signal / Junction Info <span className={subTextClsName}>Active Officer</span>
            </th>
            <th className="">
              Status <span className={subTextClsName}> &nbsp; </span>
            </th>
            <th className="">
              {delayColName} <span className={subTextClsName}>{freeFlowDelay ? "  " : "Usual Delay"} &nbsp;</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {/* Congestion Table Body */}
          <CongestionTableBody
            isLive={isLive}
            freeFlowDelay={freeFlowDelay}
            junctionStatesMap={junctionStatesMap}
            congestionList={congestionList}
            congestionListLoading={congestionListLoading}
          />
        </tbody>
      </table>
    </div>
  );
}
