export function saveFile(filename, data) {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = filename;
  link.href = URL.createObjectURL(
    new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  link.click();
}