import { useEffect, useState } from "react";

/**
 * Date and Time Component
 */
export default function ShowDateAndTime() {
  
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(refreshDateTime, 1000);

    return () => clearInterval(timer);
  }, []);

  const refreshDateTime = () => {
    setDateTime(new Date());
  };

  return (
    <div className="nav-date d-inline-block align-middle mx-3">
      <span className="date">{dateTime.toDateString()}</span>
      <span className="time">{dateTime.toLocaleTimeString()}</span>
    </div>
  );
};


