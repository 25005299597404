
// Constants
import { ADD_NEW_SIGNAL_LABELS } from "../../constants/signals/signalConstants";

// Components
import { Button } from "../../components/button/Button";
import Input from "../../components/input/Input";

export function SignalHeader({value}) {
    return (
      <div className="row justify-content-between g-0 pt-5">
        <h4 className="col-4">{value}</h4>
        <Button label="Save" size="sm" type="submit" className="col-1 btn btn-primary" />
      </div>
    );
}

export function SignalForm({ formik }) {
    const { values, handleChange, handleBlur, errors, touched } = formik;
  
    return (
      <div className="col-sm-6 mb-3">
        {ADD_NEW_SIGNAL_LABELS.map((input, index) => {
          const { label, name, type, placeholder } = input;
  
          return (
            <div className="mb-3 col-6" key={index}>
              <label className="mb-2">{label}</label>
              <Input
                id={name}
                className={`shadow-none ${touched[name] && errors[name] && "border-danger"
                  }`}
                name={name}
                type={type}
                value={values[name]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched[name] && errors[name]}
                placeholder={placeholder}
              />
            </div>
          );
        })}
      </div>
    );
  }