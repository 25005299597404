import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

//Action Constants
import { SnapshotCongestionList } from "../../constants/action-constants/snapshot/SnapshotCongestionListActions";

//Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

//Api
import { getSnapshotCongestionListApi } from "../../apis/snapshot/SnapshotCongestionListAPI";

/**
 * Saga to get the congestion details (History)
 * @param {*} action 
 */
export function* getSnapshotCongestionList(action) {
  const { searchText, minDelayMins, division, subDivision, station, time } = action.payload;

  try {
    const { junctionsMap, congestions } = yield getSnapshotCongestionListApi(searchText, minDelayMins, division, subDivision, station, time);
    const congestionList = []

    for (const eachCongestion of congestions) {
      const { junctionId: congensionId } = eachCongestion;
      eachCongestion["location"] = junctionsMap[congensionId]["location"];
      congestionList.push(eachCongestion);
    }

    yield put({
      type: SnapshotCongestionList.GET_SNAPSHOT_CONGESTION_LIST_SUCCESS,
      payload: {
        congestionList,
      },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SnapshotCongestionList.GET_SNAPSHOT_CONGESTION_LIST_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(SnapshotCongestionList.GET_SNAPSHOT_CONGESTION_LIST, getSnapshotCongestionList)]);
}
