import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

// Constants
import { CongestionSearch } from "../../constants/action-constants/congestion/CongestionSearchActionConstants";

// API's
import { searchCongestionsAPI } from "../../apis/congestion/CongestionSearchAPI";

// Export Functions
// ----------------------------------------------------------------------------

export function* searchCongestions(action) {
  const { criteria, pageNumber, pageSize } = action.payload;

  try {
    const response = yield searchCongestionsAPI(criteria, pageNumber, pageSize);
    const { pagination: congestionListPagination, items: congestionList } =
      response;

    yield put({
      type: CongestionSearch.SEARCH_CONGESTIONS_SUCCESS,
      payload: { congestionList, congestionListPagination },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: CongestionSearch.SEARCH_CONGESTIONS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(CongestionSearch.SEARCH_CONGESTIONS, searchCongestions),
  ]);
}
