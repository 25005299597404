//Action Constants
import { SnapshotCongestionList } from "../../constants/action-constants/snapshot/SnapshotCongestionListActions";

export const getSnapshotCongestionList = ({
  searchText,
  minDelayMins,
  division,
  subDivision,
  station,
  time,
}) => ({
  type: SnapshotCongestionList.GET_SNAPSHOT_CONGESTION_LIST,
  payload: { searchText, minDelayMins, division, subDivision, station, time },
});