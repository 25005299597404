//Action constants
import { User } from "../../constants/action-constants/user/UserActionConstants";

export const getUserInfoById = ({ staffId }) => ({
  type: User.GET_USER_INFO_BY_ID,
  payload: { staffId },
});

export const addUser = ({ userData, navigate }) => ({
  type: User.ADD_USER,
  payload: { userData, navigate },
});

export const editUserById = ({ userData, staffId, navigate }) => ({
  type: User.EDIT_USER_BY_ID,
  payload: { userData, staffId, navigate },
});
