// Utils
import { downloadFile } from "../../utils/api/ApiUtils";

// Urls
import { downloadCongestionDataURL } from "../../urls/api-urls/CongestionURL";


// Download Data File Api
export async function downloadCongestionDataApi(criteria) {

  // Headers
  const headers = {
    "Content-Type": "application/json",
  };

  // Request Params
  const params = {};

  // Search Criteia
  params.junction = criteria?.junctionId || null;

  params.min_time = criteria?.minTimestamp || null;
  params.max_time = criteria?.maxTimestamp || null;

  params.min_time_day = criteria?.minTimeOfDay || null;
  params.max_time_day = criteria?.maxTimeOfDay || null;

  params.min_delay = criteria?.minDelaySecs || null;
  params.max_delay = criteria?.maxDelaySecs || null;

  params.min_speed = criteria?.minSpeedKmph || null;
  params.max_speed = criteria?.maxSpeedKmph || null;

  return downloadFile(downloadCongestionDataURL, true, headers, params);
}