import { Marker, Polyline, Tooltip } from "react-leaflet";
import { Popup } from "react-leaflet";

//Constants
import { GEO_JSON_BOUNDRIES_DATA } from "../../../constants/GeoJSONBoundries";
import { STATUS_COLOR } from "../../../constants/GeneralConstants";

//Utils
import getColorCode from "../../../utils/TrafficUtils";

// Components
import LLMap from "../../../components/map/LLMap";
import MapIcon from "../../../components/map/map-icon/MapIcon";

// map-point-marker
const junctionIcon = new MapIcon({ type: "un-selected-poi" });
const feedJunctionIcon = new MapIcon({ type: "selected-poi" });

//Page Components
function TrafficStatusInfoMarkers({ trafficStatus, road }) {
  //Traffic status in each road
  const { delayInSecs } = trafficStatus;

  //Each road details
  const { traceLatLngs, name } = road;

  //To get color code
  const { colorCode } = getColorCode({ delayInSecs });

  //Path options
  const pathOptions = {
    color: STATUS_COLOR[colorCode],
    weight: 10,
  };

  return (
    <Polyline pathOptions={pathOptions} positions={traceLatLngs}>
      <Popup>{name}</Popup>
    </Polyline>
  );
}

/**
 * Signal Live Monitor Map Container
 */
export default function SignalLiveMonitorMapContainer({ junction = {}, trafficStates = [], feedJunctionsMap = {} }) {
  // Junction
  const { latitude: jLat, longitude: jLng } = junction?.location || {};

  // Initial Position
  const initLat = jLat || 12.992206641308625;
  const initLng = jLng || 80.2433964488025;

  return (
    <div className="col-8">
      <div className="map-container">
        {/* Leaflet map */}
        <LLMap
          center={[initLat, initLng]}
          zoom={14}
          geoJSONBoundaryData={GEO_JSON_BOUNDRIES_DATA}
          showBoundaryByDefault={true}
        >
          {jLat && jLng && <Marker position={[jLat, jLng]} icon={junctionIcon} />}

          {/* Marking Roads */}
          {trafficStates?.map((ts, index) => {
            const { feedJunctionId } = ts;
            const feedJunction = feedJunctionsMap[feedJunctionId] || {};
            const { latitude, longitude } = feedJunction?.location || {};

            return (
              <Marker key={feedJunctionId} position={[latitude, longitude]} icon={feedJunctionIcon}>
                <Tooltip permanent>{feedJunction.signalNo}</Tooltip>
              </Marker>
            );
          })}
        </LLMap>
      </div>
    </div>
  );
}
