import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import { getSnapshotCongestionList } from "../../../actions/snapshot/SnapshotCongestionListActions";

//Utils
import getColorCode from "../../../utils/TrafficUtils";

// Sections
import CongestionSearchForm from "../../../business-components/congestion/CongestionSearchForm";
import CongestionTable from "../../../business-components/congestion/CongestionTable";
import CongestionMapView from "../../../business-components/congestion/CongestionMapView";

/**
 * Live Monitor Page
 */
export default function CongestionSnapshotPage() {
  //Dispatch
  const dispatch = useDispatch();

  // Selector State
  const snapshotCongestionList = useSelector((state) => state.snapshotCongestionList.congestionList);
  const snapshotCongestionListLoading = useSelector((state) => state.snapshotCongestionList.congestionListLoading);

  useEffect(() => {
    const input_params = {
      searchText: "",
      minDelayMins: "",
      division: "",
      subDivision: "",
      station: "",
      time: Date.now(),
    };
    dispatch(getSnapshotCongestionList(input_params));
  }, []);

  // Results Length
  const resultLength = snapshotCongestionList.length || 0;

  return (
    <div className="row gx-0">
      {/* Congestion Section*/}
      <div className="col-4 p-3 overflow-auto content">
        {/** Search Form */}
        <CongestionSearchForm isLive={false} />

        {/** Header */}
        <h5 className="d-flex justify-content-between">
          <span>Traffic Congestions ({resultLength})</span>
        </h5>

        <CongestionTable
          isLive={false}
          congestionList={snapshotCongestionList}
          congestionListLoading={snapshotCongestionListLoading}
          getColorCode={getColorCode}
        />
      </div>

      {/* Live Monitor  map view  */}
      <CongestionMapView congestionList={snapshotCongestionList} />
    </div>
  );
}
