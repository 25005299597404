//Utils
import {
  sendGetRequest,
  sendCreateOrUpdateRequest,
} from "../../utils/api/ApiUtils";

//Urls
import {
  getUserInfoByIdUrl,
  addUserUrl,
  editUserByIdUrl,
} from "../../urls/api-urls/UserURL";

export async function getUserInfoByIdApi(staffId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getUserInfoByIdUrl.replace(":staffId", staffId);

  return sendGetRequest(url, {}, true, headerParams);
}

export async function addUserApi(userData) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    addUserUrl,
    userData,
    true,
    "POST",
    headerParams
  );
}

export async function editUserByIdApi(userData, staffId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = editUserByIdUrl.replace(":staffId", staffId);

  return sendCreateOrUpdateRequest(url, userData, true, "PUT", headerParams);
}
