import { all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Action Constants
import { Reports } from "../../constants/action-constants/reports/ReportsActionConstants";

// Utils
import { getErrorMessage } from "../../utils/ErrorUtils";

// APIs
import { congestionReportApi } from "../../apis/reports/ReportsAPI";

// Download Data File
function* congestionReportInfo(action) {
  const { minTime, maxTime } = action.payload;

  try {
    const congestionReport = yield congestionReportApi(minTime, maxTime);

    // Dispatching Action
    yield put({ type: Reports.GET_CONGESTION_REPORT_SUCCESS, payload: { congestionReport } });
  } catch (error) {
    // Show error toaster or redirect to error page
    const errorMessage = getErrorMessage(error);

    yield put({
      type: Reports.GET_CONGESTION_REPORT_FAILURE,
      payload: { error },
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(Reports.GET_CONGESTION_REPORT, congestionReportInfo)]);
}
