import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Actions
import { createSignal } from "../../actions/signals/SignalActions";

// Utils
import { constructFormik } from "./SignalSchema";
import { SignalHeader, SignalForm } from "./SignalUtils";
import { SignalMapContainer } from "./SignalMapUtils";


/**
 * Add new signal page
 */
export default function CreateSignalPage() {
  //Dispatch
  const dispatch = useDispatch();

  // Navigation
  const navigate = useNavigate();

  // Formik
  const formikOnSubmitFunc = (signalData) => {
    dispatch(createSignal(signalData, navigate));
  };
  const formik = constructFormik({ onSubmitFunc: formikOnSubmitFunc });
  const { handleSubmit } = formik;

  return (
    <div className="content-wrapper">
      <form onSubmit={handleSubmit}>

        {/* Add New Signal Header */}
        <SignalHeader value={"New Signal / Junction"} />
        <hr className="hr-color" />

        <div className="row">

          {/* Form component */}
          <SignalForm formik={formik} />

          {/* Map Container */}
          <SignalMapContainer />
        </div>
      </form>
    </div>
  );
}
