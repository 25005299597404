export const Road = {

    // Road Select and unSelect
    SELECT_ROAD: "SELECT_ROAD",
    UN_SELECT_ROAD: "UN_SELECT_ROAD",

    // Road Select Bulk
    SELECT_MULTIPLE_ROADS: "SELECT_MULTIPLE_ROADS",

    // Geo Location Around which the Roads Are fetched
    SET_GEO_LOCATION: "SET_GEO_LOCATION",
}