import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Constants
import { MapZoom } from "../../constants/GeneralConstants";

// Actions
import { getSignalInfo } from "../../actions/signals/SignalActions";

// Utils
import { SignalMapContainer as SignalMapSection } from "./SignalMapUtils";
import { DISLAY_SIGNAL_INFO, constructSignalHeader } from "../../constants/signals/signalConstants";

// Components
import Spinner from "../../components/spinner/Spinner";

//Page Constants
const DEFAULT_ZOOM = MapZoom.zoomLevel13;

//Page Components
function SignalPageHeader({ signalInfo }) {
  // TODO: Create a Empty Header Component...
  if (Object.keys(signalInfo).length == 0) {
    return null;
  }

  const headerValue = constructSignalHeader(signalInfo);
  return (
    <>
      <h4 className="pt-5">{headerValue}</h4>
      <hr className="hr-color" />
    </>
  );
}

function SignalInfoSection({ signalInfo, feedRoadsMap = {}, feedJunctionsMap = {} }) {
  // TODO: Create a Empty Info Component...
  if (Object.keys(signalInfo).length == 0) {
    return null;
  }

  return (
    <div className="col-6 mb-3">
      <div>
        {DISLAY_SIGNAL_INFO.map((date, i) => {
          const { label, key } = date;
          return (
            <div key={i}>
              <h6>{label}</h6>
              <p className="fw-light">{signalInfo[key]}</p>
            </div>
          );
        })}
      </div>

      {/** Feed Junctions */}
      <div className="mt-5">
        <h5 className="mb-3">Junctions Feeding Traffic</h5>
        {Object.values(feedJunctionsMap).map((feedJunction, idx) => {
          return (
            <div>
              <p className="my-2">
                <strong className="">{feedJunction.signalNo}</strong> | {feedJunction.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

/**
 * Signal station page
 */
export default function ViewSignalPage() {
  //Dispatch
  const dispatch = useDispatch();

  //use Params
  const params = useParams();

  // Selector States
  const signalInfoLoading = useSelector((state) => state.signal.signalInfoLoading);
  const signalInfo = useSelector((state) => state.signal.signalInfo);
  const feedRoadsMap = useSelector((state) => state.signal.signalRoadMap);
  const feedJunctionsMap = useSelector((state) => state.signal.signalFeedJunctionsMap);

  // Get SignalId from Url
  const { signalId } = params;

  useEffect(() => {
    if (Object.keys(signalInfo).length == 0 || signalInfo.id !== signalId) {
      dispatch(getSignalInfo(signalId));
    }
  }, [dispatch, signalId]);

  // Page-loading
  if (signalInfoLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="content-wrapper">
      {/* Signal Station Page Header */}
      <SignalPageHeader signalInfo={signalInfo} />

      <div className="row">
        {/* Signal Station Information  */}
        <SignalInfoSection signalInfo={signalInfo} feedRoadsMap={feedRoadsMap} feedJunctionsMap={feedJunctionsMap} />

        {/* Map container */}
        <SignalMapSection enableMapActions={false} />
      </div>
    </div>
  );
}
