import { RoadSearch } from "../../constants/action-constants/roads/RoadSearchActionConstants";

//initial state
const initialState = {
    roadList: [],
    roadListError: "",
    roadListLoading: false,

};


export default (state = initialState, action) => {
    switch (action.type) {
        case RoadSearch.GET_GEO_NEAR_ROADS:
            return {
                ...state,
                roadListLoading: true,
            };

        case RoadSearch.GET_GEO_NEAR_ROADS_SUCCESS: {
            const { roadList } = action.payload;
            return {
                ...state,
                roadListLoading: false,
                roadList,
            };
        }

        case RoadSearch.GET_GEO_NEAR_ROADS_FAILURE:
            return {
                ...state,
                roadListLoading: false,
                roadListError: action.payload,
            };

        case RoadSearch.RESET:
            return initialState;


        default:
            return state;
    }
};
