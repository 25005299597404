import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateTimePicker({ dateTime, setDateTime, minDate, maxDate, minTime, maxTime, timeInterval }) {
  return (
    <DatePicker
      className={"form-control"}
      showIcon={true}
      selected={dateTime}
      onChange={(dateTime) => setDateTime(dateTime)}
      dateFormat="Pp"
      showTimeSelect
      timeFormat="p"
      minDate={minDate}
      maxDate={maxDate}
      placeholderText="Select a date and time"
      timeIntervals={timeInterval}
      minTime={minTime}
      maxTime={maxTime}
    />
  );
}

export default DateTimePicker;
