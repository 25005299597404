import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

//Actions
import { login } from "../../actions/auth/AuthActions";

//Components
import Input from "../../components/input/Input";
import { Button } from "../../components/button/Button";

//Logo
import gctpLogo from "../../assets/images/logo.jpg";

//Validation Schema
const formValidationSchema = yup.object({
  email: yup
    .string()
    .email("please provide valid email")
    .required("please fill the User Name"),
  password: yup.string().required("please fill the Password"),
});

//Page Components
function LoginError({ loginError }) {
  //If No error
  if (!loginError) {
    return null;
  }

  return (
    <div className="card bg-secondary-subtle">
      <div className="card-body text-center text-danger">
        <i className="fa-solid fa-circle-xmark me-2"></i>
        Username/Password is incorrect
      </div>
    </div>
  );
}

function UserNameInputSection({ formik }) {
  const { values, handleChange, handleBlur, errors, touched } = formik;

  return (
    <div className="mb-3">
      <label htmlFor="">Username</label>
      <Input
        name="email"
        className="form-control mt-2 shadow-none"
        placeholder="Enter username"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && errors.email}
      />
    </div>
  );
}

function PasswordInputSection({ formik }) {
  const { values, handleChange, handleBlur, errors, touched } = formik;

  return (
    <div className="mb-3">
      <label>Password</label>
      <div className=" mt-2">
        {/* Input */}
        <Input
          name="password"
          type="password"
          className="shadow-none"
          placeholder="Enter password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && errors.password}
        />
      </div>
    </div>
  );
}

function LoginCardBody({ formik, loginLoading, loginError }) {
  const { handleSubmit } = formik;

  return (
    <>
      <div className="p-2 pt-3">
        <form className="form-horizontal" onSubmit={handleSubmit}>
          {/* User Name Input Section */}
          <UserNameInputSection formik={formik} />

          {/* Password Input Section */}
          <PasswordInputSection formik={formik} />

          {/* Login Error */}
          <LoginError loginError={loginError} />

          <div className="mt-5 d-grid">
            {/* Button Component */}
            <Button
              className="btn btn-primary btn-block"
              type="submit"
              label="Login"
              loading={loginLoading}
              disabled={loginLoading}
              color="primary"
            />
          </div>
        </form>
      </div>
    </>
  );
}

function LoginPageHeader() {
  return (
    <>
      <div className="text-center">
        <img src={gctpLogo} alt="GCTP" width="160" height="160" />
      </div>
      <h4 className="text-center mt-1 text-muted">Login</h4>
    </>
  );
}

/**
 * Login Page
 */
export default function LoginPage() {
  //Dispatch
  const dispatch = useDispatch();

  //Navigate
  const navigate = useNavigate();

  //Selector State
  const loginLoading = useSelector((state) => state.auth.loginLoading);
  const loginError = useSelector((state) => state.auth.loginError);

  //Form Validation
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: (user) => {
      const { email, password } = user;

      //Dispatch
      dispatch(login({ email, password, navigate }));
    },
  });

  return (
    <div className="container align-middle">
      <div className="card rounded shadow card-login">
        {/* Login Page Header */}
        <LoginPageHeader />

        {/* Login Card Body */}
        <LoginCardBody
          loginLoading={loginLoading}
          loginError={loginError}
          formik={formik}
        />
      </div>
    </div>
  );
}
